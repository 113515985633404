import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import { EyeFill } from "react-bootstrap-icons";

import "./style.css";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import UserProfile from "../../../images/user-profile.png";
import flagIcon from "../../../images/flag.png";
import football from "../../../images/football.png";
import Cricket from "../../../images/Cricket.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import view from "../../../images/menu10.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import Subscribe from "./Subscribe";
import Modal from "react-bootstrap/Modal";
import BigUserProfile from "../../../images/big-user-profile.png";
import CsvDownload from "../../../Components/Comman/CsvDownload";
import ManagerHeader from "../../../Components/Header/ManagerHeader";
import { useHeaderContext } from "../ManagerContext/HeaderContext";
import timeData from "../../timezone";

function TeamSchdule(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    updateSelectedId,
    updateManagerRosterId,
    updateTeamId,
    updateGameId,
    updateTeamName,
    teamName,
    teamId,
    managerRosterId
  } = useHeaderContext();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [profilePic, setProfilePic] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [teamDropdown, setTeamDropDown] = useState("");

  const [valueDropDown, setValueDropDown] = useState("");
  const [eventType, setEventType] = useState();
  const [modeValue, setModeValue] = useState(false);
  const [uid, setUId] = useState("");
  const [id, setId] = useState("");
  const [nameUpdate, setNameUpdate] = useState("");
  const [dateUpdate, setDateUpdate] = useState("");
  const [timeUpdate, setTimeUpdate] = useState("");
  const [locationDetails, setLocationDetails] = useState("");
  const [assignmentUpdate, setAssignmentupdate] = useState("");
  const [volenteerUpdate, setVolenteerUpdate] = useState("");
  const [flag, setFlagList] = useState([]);
  const [flagId, setFlagId] = useState("");
  const [availModal, setAvailModal] = useState();
  const [availSportModal, setAvailSportModal] = useState("");
  const [sportId, setSportId] = useState("");
  const [availDataModal, setAvailDataModal] = useState([]);
  const [locationlist, setLocationList] = useState([]);
  const [opponentlist, setOpponentList] = useState([]);
  const [selectedOpponent, setSelectedOpponent] = useState("");
  const [opponentname, setOponentName] = useState("");
  const [locationname, setLocationName] = useState("");
  const [timezonename, setTimeZoneName] = useState("");
  const [timezonelist, setTimeZoneList] = useState([]);
  const [item, setItem] = useState({});
  const [locationid, setLocationId] = useState("");
  const [duration, setDuration] = useState("");
  const [gamenotes, setGameNotes] = useState("");
  const [uniform, setUniform] = useState("");
  const [timezoneid, setTimeZoneId] = useState("");
  const [opponentid, setopponentId] = useState("");
  const [durationlist, setDurationList] = useState([]);
  const [arrivel, setArrivel] = useState("");
  const [trackAvailability, setTrackAvailability] = useState(false);
  const [isEventCancel, setIsEventCancel] = useState(false);
  const [notifyTeam, setNotifyTeam] = useState(false);
  const[arrivaltime,setArrivaltime]= useState("")
  const[starttime,setStarttime]=useState("")
  const[endtime,setEndtime]=useState("")
  const[durationbefore,setDurationbefore]=useState("")
  const[clock,setClock]=useState(false)
  console.log(arrivel, "09090909000");
  const handleDropDown = (e) => {
    setArrivel(e);
  };
  //  const [track_availability, setTrackAvailability] = useState('false');
  //  const[isEventCancel,setIsEventCancel]=useHistory('false')
  //  const[notifyteam,setNotifyTeam]=useState('false')

  const pic = "https://www.sports-cloud.io/sportsapi/";

  useEffect(() => {
    // let user = userdata && userdata._id ? true : false;
    // //console.log("userMe===>", user);
    // dropdownMenu();

    // setUser(user);
    // //console.log("USerData", userdata);
    const newid=teamId
    const rosterid =   managerRosterId
    const userLocal = JSON.parse(localStorage.getItem("user"));
    //console.log("userData after login--->", userLocal)
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    flagList();
    updateProfile();
    const team_id = localStorage.getItem("selectedTeamId");
    teamSchedule(newid,rosterid);
    setTeamDropDown(team_id);
    location();
    oponent();
    timezonel();
    setDurationList(durations);
    // setTimeZoneList(timeData);
  }, [teamId,managerRosterId]);

  // const handleLogout = () => {
  //   //console.log("pruyuuuuuu", props);
  //   dispatch(logoutUser(null));
  //   localStorage.removeItem("user");
  //   setUserData(null);
  //   props.history.push("/");
  // };
  const updateProfile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      console.log("user", user);

      Network("api/get-user-details?user_id=" + user._id, "GET", header).then(
        async (res) => {
          console.log("new Profile Pic----", res);
          setProfilePic(res.response_data);
        }
      );
    }
  };

  // const dropdownMenu = () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   if (user) {
  //     let header = {
  //       token: user.authtoken,
  //     };
  //     //console.log('user',user)

  //     Network(
  //       "api/getAllTeamName?teamManagerId=" + user._id,
  //       "GET",
  //       header
  //     ).then(async (res) => {
  //       console.log("dropdown----", res);
  //       if (res.response_code == 400) {
  //         dispatch(logoutUser(null));
  //         localStorage.removeItem("user");
  //         history.push("/");
  //         toast.error(res.response_message);
  //       }
  //       setDropdown(res.response_data);

  //       teamSchedule(res.response_data[0].team_id);
  //     });
  //   }
  // };
  const change = (value) => {
    console.log("values===>", value);
    setTeamDropDown(value);
    updateTeamId(value);
    teamSchedule(value);
  };

  const teamSchedule = (id,ManagerRosterId) => {
    console.log("id", id);
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("authToken", user.authtoken);
    // const ManagerRosterId = localStorage.getItem("selectedManagerRosterId");
     console.log("ManagerRosterId", ManagerRosterId);
    if (user) {
      let header = {
        token: user.authtoken,
      };

      let url = "";
      if (id != undefined) {
        url =
          "api/get-game-event-list?manager_id=" +
          user._id +
          "&team_id=" +
          id +
          "&page=1&limit=10";
       } else {
        url =
          "api/get-game-event-list?manager_id=" +
          user._id +
          "&team_id=" +
          teamDropdown +
          "&page=1&limit=10";
       }
       //console.log('user',user)
       // var teamid = id ? id :   "6480285555cf8a5024960668";
      Network(
        "api/getAllEventAndGamesData?team_id=" +
          id +
          "&roster_id=" +
          ManagerRosterId,
        "GET",
        header
      ).then(async (res) => {
        console.log("schedule----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        console.log("doc data----->", res.response_data);
        setSchedule(res.response_data);
      });
    }
  };
  console.log(schedule, "====dddddd=====");

  const flagList = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      //console.log('user',user)

      Network("api/all-flag-list", "GET", header).then(async (res) => {
        // console.log("flagList----", res?.response_data);
        setFlagList(res?.response_data);
        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
      });
    }
  };

  const deleteScheduleData = (id, flag) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const team_id = localStorage.getItem("selectedTeamId");
    console.log("id-------------->", id);
    const a = window.confirm("Are you sure you wish to delete this Data?");
    console.log("delete click");
    if (a == true) {
      if (flag == "Game") {
        var url =
          "https://www.sports-cloud.io/sportsapi/api/deleteGameDetailsById";
      } else {
        var url =
          "https://www.sports-cloud.io/sportsapi/api/deleteEventDetailsById";
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: user.authtoken,
        },
        body: JSON.stringify({
          id: id,
        }),
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          console.log("delete Schedule  data", res);
          if (res.success == true) {
            console.log("deleted data", res);
            toast.success(res.response_message);
          }
          if (res.response_code == 400) {
            // dispatch(logoutUser(null));
            // localStorage.removeItem("user");
            // history.push("/");
            toast.error(res.response_message);
          }

          teamSchedule(team_id);
        });
    }
  };

  const setAvailability = (flag, sport_id, availData) => {
    setAvailModal(true);
    setAvailSportModal(flag);
    setSportId(sport_id);
    setAvailDataModal(availData);
  };

  const updateAvailability = (status) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (availSportModal == "Game") {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/editGameAvailabilityByGameAvailableId";
      var data = {
        _id: sportId,
        at_an_event: status,
      };
    } else {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/editEventAvailabilityByEventAvailableId";

      var data = {
        _id: sportId,
        at_an_event: status,
      };
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("Schedule  data", res);
        if (res.response_code == 200) {
          console.log(" data", res);
          setAvailModal(false);
          toast.success(res.response_message);
          const team_id = localStorage.getItem("selectedTeamId");
          teamSchedule(team_id,managerRosterId);
        }
        if (res.response_code == 400) {
          setAvailModal(false);
          toast.error(res.response_message);
        }
      });
  };
  const addAvailability = (status) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const ManagerRosterId = localStorage.getItem("selectedManagerRosterId");
    console.log("ManagerRoster");

    if (availSportModal == "Game") {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/addGameAvailabilityByGameId";
      var data = {
        game_id: sportId,
        assignment_volunteer_roster_id: ManagerRosterId,
        at_an_event: status,
      };
    } else {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/addEventAvailabilityByEventId";

      var data = {
        event_id: sportId,
        assignment_volunteer_roster_id: ManagerRosterId,
        at_an_event: status,
      };
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("Schedule  data", res);
        if (res.response_code == 200) {
          console.log(" data", res);
          setAvailModal(false);
          toast.success(res.response_message);
          const team_id = localStorage.getItem("selectedTeamId");
          teamSchedule(team_id);
        }
        if (res.response_code == 400) {
          setAvailModal(false);
          toast.error(res.response_message);
        }
      });
  };

  const teamEventData = (id, flag, teamId) => {
    updateGameId(id, flag, teamId);
    history.push("/ScheduleAvailabilityDetails");
  };
  const updateModalValue = (id1, uId, Item) => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      let header = {
        token: user.authtoken,
      };
      //game respose set for update
      if (Item?.isFlag === "Game") {
        Network(
          "api/getGameDetailsByGameId?game_id=" + uId,
          "GET",
          header
        ).then(async (res) => {
          console.log("schedule----", res);
          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          setModeValue(true);
          setItem(res.response_data);
          console.log(res.response_data, "gamedata==>>>");
          setNameUpdate(res.response_data?.game_name);
          setOponentName(res.response_data?.opponent?.opponentsName);
          setLocationName(res.response_data?.location?.locationName);
          setLocationId(res.response_data?.location?._id);
          setDateUpdate(res.response_data?.date?.substring(0, 10));
          setTimeUpdate(convertTo24HourFormat(res.response_data?.time));
          setLocationDetails(
            `${res.response_data?.location?.locationName},${res.response_data?.location?.address}`
          );
          setDuration(res.response_data?.duration);
         
          console.log(duration,"duration==>>>>")
          setGameNotes(res.response_data?.notes);
          setUniform(res.response_data?.uniform);
          setTimeZoneName(res.response_data?.timezone?.timezone);
          setTimeZoneId(res.response_data?.timezone?._id);
          setopponentId(res.response_data?.opponent?._id);
          // setArrivel(
          //   convertMinutesToHoursMinutes(res.response_data?.arrive_early)
          // );
          setArrivel(res.response_data?.arrive_early)
          console.log("Track arrival from response data:", res.response_data?.arrive_early);
          setArrivaltime(res.response_data?.arrival_time)
          setStarttime(res.response_data?.start_time)
          setEndtime(res.response_data?.end_time)
          setTrackAvailability(res.response_data?.track_availability);
          console.log("Track availability from response data:", res.response_data?.track_availability);

          setIsEventCancel(res.response_data?.isEventCancel);
          setNotifyTeam(res.response_data?.notify_team);
        });
      }
      //event  response set for update
       else {
        Network("api/getDetailsByEventId?events_id=" + uId, "GET", header).then(
          async (res) => {
            console.log("schedule----", res);
            if (res.response_code == 400) {
              dispatch(logoutUser(null));
              localStorage.removeItem("user");
              history.push("/");
              toast.error(res.response_message);
            }
            setItem(res.response_data);
            setModeValue(true);
            setItem(res.response_data);
            console.log(res.response_data, "gamedata==>>>");
            setNameUpdate(res.response_data?.event_name);
            // setOponentName(res.response_data?.opponent?.opponentsName);
            setLocationName(res.response_data?.location?.locationName);
            setLocationId(res.response_data?.location?._id);
            setDateUpdate(res.response_data?.date?.substring(0, 10));
            setGameNotes(res.response_data?.notes);
            setUniform(res.response_data?.uniform);
            setTimeZoneName(res.response_data?.timezone?.timezone);
            setTimeZoneId(res.response_data?.timezone?._id);
            setTimeUpdate(convertTo24HourFormat(res.response_data?.time));
            setLocationDetails(
              `${res.response_data?.location?.locationName},${res.response_data?.location?.address}`
            );
            setDuration(res.response_data?.duration);
            setArrivel(res.response_data?.arrive_early)
          // console.log("Track arrival from response data:", res.response_data?.arrive_early);
          setArrivaltime(res.response_data?.arrival_time)
          setStarttime(res.response_data?.start_time)
          setEndtime(res.response_data?.end_time)
            setTrackAvailability(res.response_data?.track_availability);
          setIsEventCancel(res.response_data?.isEventCancel);
          setNotifyTeam(res.response_data?.notify_team);

            console.log(res.response_data, "eventdata==>>>");
          }
        );
      }
    }

    console.log(id1, "id1+++++++>>>");
    console.log(uId, "uId+++++++>>>");
    console.log(item, "item+++++++>>>");
    // teamSchedule(teamDropdown == null ? dropdown[0]._id : teamDropdown);
    // setModeValue(true);
    // setUId(uId);
    // setId(id1);
    // console.log(item, "pooraarray");
    // // setSelectedOpponent(item.)
    // setNameUpdate(Item?.isFlag === "Game" ? item?.game_name : item?.event_name);
    // setOponentName(item?.opponent?. opponentsName)
    // setLocationName(item?.location?.locationName)
    // setLocationId(item?.location?._id)
    // setDateUpdate(item?.date?.substring(0, 10));
    // // setTimeUpdate(convertTo24HourFormat(item?.time));
    // setLocationDetails(
    //   `${item?.location?.locationName},${item?.location?.address}`
    // );
    //  setTimeZone(item?.timezone?.timezone)
    // setAssignmentupdate(item?.assignment);
    // setVolenteerUpdate(item?.contactInformationZipCode);
    // console.log("idddddd-------->", id1);
    // console.log("idddddd-------->", uId);
  };

  // const convertMinutesToHoursMinutes = (minutes) => {
  //   if (minutes > 60) {
  //     const hours = Math.floor(minutes / 60);
  //     return `${hours} hours`;
  //   } else {
  //     return `${minutes} minutes`;
  //   }
  //   // const remainingMinutes = minutes % 60;
  //   // return `${hours} hours ${remainingMinutes} minutes`;
  // };

  const handleCancelEditGameEvent = () => {
    setNameUpdate("");
    setDateUpdate("");
    setTimeUpdate("");
    setLocationDetails("");
    setAssignmentupdate("");
    setModeValue(false);
  };
  const convertToMinutes = (durationString) => {
    console.log (durationString,"durationString==>>")
    // const [hoursStr, minutesStr] = durationString.split(' ');
    const parts = durationString.split(' ');

    // Parse hours and minutes into numbers
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[2]);

    // Convert hours to minutes and add minutes
    const totalMinutes = hours * 60 + minutes;

    return totalMinutes;
   
};

  const convertToHourMinuteFormat = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const formattedHours = `${hours} hr`;
    const formattedMinutes = `${minutes} min`;
    
    return `${formattedHours} ${formattedMinutes}`;
};

  const timeToMinutes = (time) => {
    const [timeStr, period] = time.split(' ');
    const [hours, minutes] = timeStr.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes;

    // Adjust total minutes based on AM or PM
    if (period === "PM" && hours !== 12) {
        totalMinutes += 12 * 60; // Add 12 hours for PM
    } else if (period === "AM" && hours === 12) {
        totalMinutes -= 12 * 60; // Subtract 12 hours for midnight
    }

    return totalMinutes;
};

const convertToAmPmFormat = (arrivalTime) => {
  const [hours, minutes] = arrivalTime.split(':').map(Number);
  let period = "AM";
  let formattedHours = hours;

  if (hours >= 12) {
      period = "PM";
      formattedHours = hours === 12 ? 12 : hours - 12;
  }

  // Ensure that formattedHours is always padded with 0 if less than 10
  formattedHours = String(formattedHours).padStart(2, '0');

  return `${formattedHours}:${String(minutes).padStart(2, '0')} ${period}`;
};

  // Function to convert minutes to time in HH:MM format
const minutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  // return `${hours.toString().padStart(2, "0")}:${mins
  //   .toString()
  //   .padStart(2, "0")}`;
  return`${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  
};
const convertToHourMinuteFormattwo = (totalMinutes) => {
  // Calculate hours and remaining minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Return the formatted duration as "X hr Y min"
  return `${hours} hr ${minutes} min`;
};

// Function to calculate new arrival time based on arrival_time and arrive_early
const calculateArrivalTime = (arrivalTime, arrivel) => {
  console.log(arrivalTime, arrivel,"arrivalTime, arrivel==>>>")
  const arrivalMinutes = timeToMinutes(arrivalTime);
  const earlyMinutes = arrivel;
  console.log(arrivalMinutes,earlyMinutes,"==>>earlyMinutes==>>")

  let newArrivalMinutes = arrivalMinutes - earlyMinutes;
  console.log(newArrivalMinutes,"newArrivalMinutes==>>//")
  if (newArrivalMinutes < 0) {
      // Handle case where arrive_early exceeds arrival_time
      newArrivalMinutes = 0;
  }
  console.log( minutesToTime(newArrivalMinutes),"console.log( minutesToTime(newArrivalMinutes))==?>>>>")

  return minutesToTime(newArrivalMinutes);
  
};

const calculateEndTime =(starttime,duration)=>{
  console.log(starttime,duration,"stimeand duration==>>>")
   const endminute =timeToMinutes(starttime)
   const durationtime =convertToMinutes(duration)
   let newendtime = endminute + durationtime
   console.log(endminute,durationtime,"endtime,durationtime ==>>")
   if (newendtime < 0) {
    // Handle case where arrive_early exceeds arrival_time
    newendtime = 0;
}
console.log( minutesToTime(newendtime),"console.log( minutesToTime(newendtime))==?>>>>")

return minutesToTime(newendtime);
}

//for update game

  const updateGameEvent = () => {
    console.log(locationid, "locationid=====>>>");
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(schedule[id], "=====>>>???");
    // if (duration < "60") {
    // }
  console.log(durationbefore,"duretion in request==>>>")
  
    
    const data = {
      // short_label: schedule[id].short_label,
      game_id: item._id,
      location: locationid,
      timezone: timezoneid,
      opponent: opponentid,
      time: timeUpdate,
      duration:durationbefore ? durationbefore : duration,
      arrive_early:arrivel,
      arrival_time:convertToAmPmFormat(calculateArrivalTime(starttime, arrivel)),
      end_time:durationbefore ? convertToAmPmFormat(calculateEndTime(starttime,durationbefore)): convertToAmPmFormat(calculateEndTime(starttime,duration)),
      uniform: uniform,
      notes: gamenotes,
      // opponent: schedule[id].opponent,
      // event_type: schedule[id].event_type,
      date: dateUpdate,
      // time: timeUpdate,
      // location: locationDetails,
      // location_details: locationDetails,
      // home_or_away: schedule[id].home_or_away,
      // uniform: schedule[id].uniform,
      track_availability: trackAvailability,
      isEventCancel: isEventCancel,
      notify_team: notifyTeam,
    };
    console.log(data, "requestOptions");
  
    

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
      // body: JSON.stringify({
      //   name: nameUpdate,
      //   short_label: schedule[id].short_label,
      //   _id: schedule[id]._id,
      //   opponent: schedule[id].opponent,
      //   event_type: schedule[id].event_type,
      //   date: dateUpdate,
      //   time: timeUpdate,
      //   location: locationDetails,
      //   location_details: locationDetails,
      //   home_or_away: schedule[id].home_or_away,
      //   uniform: schedule[id].uniform,
      // }),
    };
    console.log(requestOptions.body, "requestOptions");
    fetch(
      "https://www.sports-cloud.io/sportsapi/api/editGameDetailsById",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("update game/event data", res);
        if (res.success == true) {
          toast.success(res.response_message);
          setModeValue(false);
          const team_id = localStorage.getItem("selectedTeamId");
          teamSchedule(team_id);
        }

        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
      });
  };
  //for update event

  const updateGameEvents = () => {
    console.log(locationid, "locationid=====>>>");
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(schedule[id], "=====>>>???");
    if (duration < "60") {
    }
    const data = {
      // short_label: schedule[id].short_label,
      events_id: item._id,
      location: locationid,
      timezone: timezoneid,
      // opponent:opponentid,
      time: timeUpdate,

      duration:durationbefore ? durationbefore : duration,
      arrive_early:arrivel,
      arrival_time:convertToAmPmFormat(calculateArrivalTime(starttime, arrivel)),
      end_time:durationbefore ? convertToAmPmFormat(calculateEndTime(starttime,durationbefore)): convertToAmPmFormat(calculateEndTime(starttime,duration)),
      uniform: uniform,
      notes: gamenotes,
      // opponent: schedule[id].opponent,
      // event_type: schedule[id].event_type,
      date: dateUpdate,
      // time: timeUpdate,
      // location: locationDetails,
      // location_details: locationDetails,
      // home_or_away: schedule[id].home_or_away,
      // uniform: schedule[id].uniform,
      track_availability: trackAvailability,
      isEventCancel:isEventCancel ,
      notify_team:notifyTeam ,
    };
    console.log(data, "requestOptions");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
      // body: JSON.stringify({
      //   name: nameUpdate,
      //   short_label: schedule[id].short_label,
      //   _id: schedule[id]._id,
      //   opponent: schedule[id].opponent,
      //   event_type: schedule[id].event_type,
      //   date: dateUpdate,
      //   time: timeUpdate,
      //   location: locationDetails,
      //   location_details: locationDetails,
      //   home_or_away: schedule[id].home_or_away,
      //   uniform: schedule[id].uniform,
      // }),
    };
    console.log(requestOptions.body, "requestOptions");
    fetch(
      "https://www.sports-cloud.io/sportsapi/api/editEventDetailsById",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("update game/event data", res);
        if (res.success == true) {
          toast.success(res.response_message);
          setModeValue(false);
          const team_id = localStorage.getItem("selectedTeamId");
          teamSchedule(team_id);
        }

        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
      });
  };

  const selectFlag = (event) => {
    setFlagId(event.target.value);
  };

  let headers = [
    // { label: "Event/Game", key: "firstName" },
    { label: "Event Name", key: "event_name" },
    // { label: "email", key: "contactInformationEmail" },
    // { label: "phone", key: "contactInformationPhoneNumber" },
    // { label: "Gender", key: "playerGender" }
  ];

  // const allPlayers = newplayerdata.concat(newNonPlayerData);
  // let data = (allPlayers && allPlayers.length >0) ? allPlayers  : [];
  let data = schedule && schedule.length > 0 ? schedule : [];

  const convertTo24HourFormat = (inputTime) => {
    console.log(inputTime,"inputtime==....///////.")
    const timeArray = inputTime?.split(" ");
    const timeParts = timeArray[0].split(":");
    let hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    if (timeArray[1] === "PM" && hours !== 12) {
      hours += 12;
    } else if (timeArray[1] === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  const location = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      let header = {
        token: user.authtoken,
      };

      Network("api/getAllLocationData", "GET", header).then(async (res) => {
        console.log("schedule----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setLocationList(res.response_data);
        console.log(res.response_data, "locationlist==>>>");
      });
    }
  };

  const oponent = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      let header = {
        token: user.authtoken,
      };

      Network("api/getAllOpponentsData", "GET", header).then(async (res) => {
        console.log("schedule----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setOpponentList(res.response_data);
        console.log(res.response_data, "opponentlist==>>>");
      });
    }
  };
  const timezonel = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      let header = {
        token: user.authtoken,
      };

      Network("api/getAllTimeZoneList", "GET", header).then(async (res) => {
        console.log("schedule----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setTimeZoneList(res.response_data);
        console.log(res.response_data, "opponentlist==>>>");
      });
    }
  };
  // const handleOpponentChange = (e) => {
  //   // Update the selected opponent when the user makes a selection
  //   setSelectedOpponent(e.target.value);
  // };


  const handleCheckboxChange = (property) => {
    // switch (property) {
    //   case 'trackAvailability':
    //     setTrackAvailability(!trackAvailability);
    //     break;
    //   case 'isEventCancel':
    //     setIsEventCancel(!isEventCancel);
    //     break;
    //   case 'notifyTeam':
    //     setNotifyTeam(!notifyTeam);
    //     break;
    //   default:
    //     break;
    // }
  };

  const durations = [
    { label: "15 minutes", value: 15 },
    { label: "20 minutes", value: 20 },
    { label: "30 minutes", value: 30 },
    { label: "40 minutes", value: 40 },
    { label: "50 minutes", value: 50 },
    { label: "1 hour", value: 60 },
    { label: "2 hours", value: 120 },
    { label: "3 hours", value: 180 },
    { label: "5 hours", value: 300 },
    { label: "6 hours", value: 360 },
    { label: "8 hours", value: 480 },
    { label: "12 hours", value: 720 },
    { label: "24 hours", value: 1440 },
    { label: "4 days", value: 5200 },
    { label: "5 days", value: 7200 },
  ];

 

  return (
    <div>
      <div className="dashboard-container">
        <div className="dashboard-main">
          <SideMenuComponents manger="manger" />
          <div className="dashboard-main-content">
            <ManagerHeader change={change} />

            <div className="prefarance-page">
              <div className="page-header">
                <h2 className="page-title">Schedule</h2>
                <div className="streming-head-right">
                  <div className="stream-tab">
                    <ul>
                      <li>
                        <a className="active" href="#">
                          List View
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            history.push("./ManagerViewCalender");
                          }}
                        >
                          Calendar View
                        </a>
                      </li>
                    </ul>
                  </div>

                  <button
                    className="start-stream-btn"
                    onClick={() => {
                      history.push("./ManagerTeamAvailability");
                    }}
                  >
                    Select Availability
                  </button>

                  {/* <CsvDownload
                    data={data}
                    headers={headers}
                    filename={`Schedule list `}
                  /> */}
                </div>
              </div>

              <div className="managerDropdownLink">
                <h3 style={{ color: "white" }}>Manager:</h3>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ backgroundColor: "#2C2C2C", border: "none" }}
                  >
                    New
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                    style={{
                      backgroundColor: "#484848",
                      listStyle: "none",
                      margin: "14px",
                    }}
                  >
                    <Link to={{ pathname: "/NewGame", state: "GAME" }}>
                      <li>
                        <a className="dropdown-item">New Game</a>
                      </li>
                    </Link>
                    <Link to={{ pathname: "/NewEvent", state: "EVENT" }}>
                      <li>
                        <a className="dropdown-item">New Event</a>
                      </li>
                    </Link>
                  </ul>
                </div>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ backgroundColor: "#2C2C2C", border: "none" }}
                  >
                    Create
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton2"
                    style={{
                      backgroundColor: "#484848",
                      listStyle: "none",
                      margin: "14px",
                    }}
                  >
                    <Link to={{ pathname: "/EditLocation", state: "Location" }}>
                      <li>
                        <a className="dropdown-item" href="#">
                          Edit Location
                        </a>
                      </li>
                    </Link>
                    <Link to={{ pathname: "/EditOponent", state: "Opponent" }}>
                      <li>
                        <a className="dropdown-item" href="#">
                          Edit Opponent
                        </a>
                      </li>
                    </Link>
                  </ul>
                </div>
                {/* <div className='dropdown'>
                  <button
                    className='btn btn-secondary dropdown-toggle'
                    type='button'
                    id='dropdownMenuButton1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                    style={{ backgroundColor: "#2C2C2C", border: "none" }}
                  >
                    Import
                  </button>
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='dropdownMenuButton1'
                    style={{
                      backgroundColor: "#484848",
                      listStyle: "none",
                      margin: "14px",
                    }}
                  >
                    <li>
                      <a className='dropdown-item' href='#'>
                        Import from Schedule
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="prefarance-box">
                <div className="team-payment team-assesment custom-team-schedule-tbl">
                  <table>
                    <tr>
                      <th></th>
                      <th>Game/ Event</th>
                      <th>Date Time</th>
                      {/* <th>Time</th> */}
                      <th>Location</th>
                      <th>Availability</th>
                      <th>Actions</th>
                    </tr>

                    {schedule?.map((schedule, id) => {
                      return (
                        <tr>
                          <td>
                            {/* {console.log(id,"divider")} */}
                            {schedule.isFlag == "Game" ? (
                              <img
                                src={Cricket}
                                alt="cricket-icon"
                                className="img-fluid"
                                style={{ "max-width": 50 }}
                              />
                            ) : (
                              <img
                                src={flagIcon}
                                alt="flag-icon"
                                className=""
                              />
                            )}
                          </td>
                          <td>
                            <div className="flag-prac">
                              <button className="practice">
                                {schedule.isFlag == "Game"
                                  ? schedule.game_name
                                  : schedule.event_name}
                              </button>
                            </div>
                          </td>
                          <td>
                            <span>{schedule.date.split("T")[0]}</span>
                          </td>
                          {/* <td><span>{`${new Date(schedule.date).getDate()}/${new Date(schedule.date).getMonth()}/${new Date(schedule.date).getFullYear()}`}</span></td> */}
                          {/* <td> */}
                          {/* <span>{schedule.time.startTime}-{schedule.time.endTime}</span> */}
                          {/* </td> */}
                          <td>
                            <span>{schedule.location.locationName},</span>
                            {/* <span>{schedule.location.address}</span> */}
                          </td>
                          <td>
                            {schedule.roster_availability_data?.length > 0 ? (
                              <div className="last-row">
                                <button
                                  style={{ color: "#ec3525" }}
                                  data-toggle="modal"
                                  data-target="#setAvailability"
                                  onClick={() =>
                                    setAvailability(
                                      schedule.isFlag,
                                      schedule.roster_availability_data[0]?._id,
                                      schedule.roster_availability_data
                                    )
                                  }
                                >
                                  {
                                    //make first letter Uppercase
                                    schedule?.roster_availability_data[0]?.at_an_event.replace(
                                      /^\w/,
                                      (match) => match.toUpperCase()
                                    )
                                  }
                                </button>
                              </div>
                            ) : (
                              <div className="last-row">
                                <button
                                  style={{ color: "#ec3525" }}
                                  data-toggle="modal"
                                  data-target="#setAvailability"
                                  onClick={() =>
                                    setAvailability(
                                      schedule.isFlag,
                                      schedule._id,
                                      schedule.roster_availability_data
                                    )
                                  }
                                >
                                  Set Availability
                                </button>
                              </div>
                            )}
                          </td>
                          {/* <td>{schedule.assignment}</td> */}
                          <td>
                            <div className="last-row">
                              {/* <p>Avaneesh Shett</p>{" "} */}
                              <button
                                data-toggle="modal"
                                data-target="#assignmentdelect"
                                onClick={() =>
                                  deleteScheduleData(
                                    schedule._id,
                                    schedule.isFlag
                                  )
                                }
                              >
                                <img src={Delect} />
                              </button>{" "}
                              <button
                                onClick={() =>
                                  updateModalValue(id, schedule._id, schedule)
                                }
                              >
                                <img src={pencil} />
                              </button>
                              <button
                                onClick={() =>
                                  teamEventData(
                                    schedule._id,
                                    schedule.isFlag,
                                    schedule.team_id
                                  )
                                }
                              >
                                <EyeFill
                                  style={{ color: "white", fontSize: "26px" }}
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </table>

                  {availModal ? (
                    <Modal show={availModal} size="md">
                      <Modal.Body>
                        <div className="prefarance-form playerinfo-form">
                          <h2 className="m-title">Set Availability</h2>
                          <div className="row">
                            <div className="col-md-12">
                              <button
                                className="btn acceptbtn focus1"
                                style={{ backgroundColor: "#1FD83C" }}
                                onClick={() => {
                                  availDataModal.length > 0
                                    ? updateAvailability("going")
                                    : addAvailability("going");
                                }}
                              >
                                GOING
                              </button>
                              <button
                                className="btn acceptbtn focus2"
                                style={{ backgroundColor: "#963687" }}
                                onClick={() => {
                                  availDataModal.length > 0
                                    ? updateAvailability("maybe")
                                    : addAvailability("maybe");
                                }}
                              >
                                MAY BE
                              </button>
                              <button
                                className="btn acceptbtn focus3"
                                style={{ backgroundColor: "#EC3525" }}
                                onClick={() => {
                                  availDataModal.length > 0
                                    ? updateAvailability("no")
                                    : addAvailability("no");
                                }}
                              >
                                NO
                              </button>
                            </div>
                          </div>
                          <div className="text-center mt-3">
                            <button
                              className="add-links"
                              onClick={() => setAvailModal(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  ) : (
                    ""
                  )}

                  {modeValue && schedule?.length != 0 ? (
                    <Modal show={modeValue} size="md">
                      <Modal.Body>
                        <div className="prefarance-form playerinfo-form">
                          <h1 className="m-title">Edit Game/Event</h1>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="prefarance-form-list">
                                <label className="input-label">
                                  Name of Event/Game
                                </label>
                                <input
                                  type="text"
                                  className="input-select"
                                  placeholder="Enter Name of Game/Event "
                                  // value={
                                  //   schedule[id]?.isFlag === "Game"
                                  //     ? schedule[id]?.game_name
                                  //     : schedule[id]?.event_name
                                  // }
                                  value={nameUpdate}
                                  onChange={(e) =>
                                    setNameUpdate(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="prefarance-form-list">
                                <label className="input-label">Date</label>
                                <input
                                  type="date"
                                  className="input-select"
                                  placeholder="Select Date "
                                  // defaultValue={`${new Date(
                                  //   schedule[id].date
                                  // ).getDate()}/${new Date(
                                  //   schedule[id].date
                                  // ).getMonth()}/${new Date(
                                  //   schedule[id].date
                                  // ).getFullYear()}`}
                                  // value={schedule[id]?.date.substring(0, 10)}
                                  value={dateUpdate}
                                  onChange={(e) =>
                                    setDateUpdate(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="prefarance-form-list">
                                <label className="input-label">Time</label>
                                <input
                                  type="time"
                                  className="input-select"
                                  placeholder="Select Time "
                                  // defaultValue={`${schedule[id].time.startTime}-${schedule[id].time.endTime}`}
                                  // defaultValue={convertTo24HourFormat(
                                  //   schedule[id]?.time
                                  // )}
                                  // defaultValue={}
                                  value={timeUpdate}
                                  onChange={(e) =>
                                    setTimeUpdate(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="prefarance-form-list">
                                <label className="input-label">TimeZone</label>
                                {/* <input
                                  // type="time"
                                  className="input-select"
                                  placeholder="Select Time "
                                  // defaultValue={`${schedule[id].time.startTime}-${schedule[id].time.endTime}`}
                                  // defaultValue={convertTo24HourFormat(
                                  //   schedule[id]?.time
                                  // )}
                                  value={timezone}
                                  // onChange={(e) =>
                                  //   setTimeUpdate(e.target.value)
                                  // }
                                /> */}
                                <select
                                  className="input-select"
                                  // defaultValue={timezonename}
                                  value={timezoneid}
                                  onChange={(e) =>
                                    setTimeZoneId(e.target.value)
                                  }
                                >
                                  <span>
                                    {/* {console.log(
                                      timezonename,
                                      "timezonename==>"
                                    )} */}
                                    {timezonename
                                      ? timezonename
                                      : "Select an option"}
                                  </span>

                                  {timezonelist?.map((option, index) => (
                                    <option
                                      key={index}
                                      // value={`${timezone.label} ${timezone.timezone}`}
                                      // value={}
                                      value={option._id}
                                    >
                                      {/* {timezone.label} */}
                                      {option.timezoneName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="prefarance-form-list">
                                <label className="input-label">Location</label>
                                {/* <input
                                  type='text'
                                  className='input-select'
                                  placeholder='Enter Location'
                                  defaultValue={`${schedule[id].location_details},${schedule[id].location}`}
                                  defaultValue={`${schedule[id]?.location?.locationName},${schedule[id]?.location?.address}`}
                                  value={locationDetails}
                                  onChange={(e) =>
                                    setLocationDetails(e.target.value)
                                  }
                                /> */}
                                <select
                                  // id="dropdown"
                                  value={locationid}
                                  // onChange={handleSelect}
                                  className="input-select"
                                  onChange={(e) =>
                                    setLocationId(e.target.value)
                                  }
                                >
                                  <span>
                                    {locationname
                                      ? locationname
                                      : "Select an option"}
                                  </span>
                                  {locationlist?.map((option, index) => (
                                    <option key={index} value={option._id}>
                                      {option.locationName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {item.game_name ? (
                              <div>
                                <div className="col-md-12">
                                  <div className="prefarance-form-list">
                                    <label className="input-label">
                                      Opponent
                                    </label>
                                    {/* <input
                                  type='text'
                                  className='input-select'
                                  placeholder='Enter Location'
                                  defaultValue={`${schedule[id].location_details},${schedule[id].location}`}
                                  defaultValue={`${schedule[id]?.location?.locationName},${schedule[id]?.location?.address}`}
                                  value={locationDetails}
                                  onChange={(e) =>
                                    setLocationDetails(e.target.value)
                                  }
                                /> */}
                                    <select
                                      // id="dropdown"
                                      // value={selectedOption}
                                      // onChange={handleSelect}
                                      // value={selectedOpponent}
                                      value={opponentid}
                                      onChange={(e) =>
                                        setopponentId(e.target.value)
                                      }
                                      className="input-select"
                                    >
                                      <span
                                        // defaultValue={opponentname}
                                        value=""
                                      >
                                        {opponentname
                                          ? opponentname
                                          : "Select an option"}
                                        {console.log(
                                          opponentname,
                                          "opponentname==>>>"
                                        )}
                                      </span>
                                      {opponentlist?.map((option, index) => (
                                        <option key={index} value={option._id}>
                                          {option.opponentsName}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-md-12">
                              <div className="prefarance-form-list">
                                <label className="input-label">Duration</label>
                                <input
                                  type={clock?"time":"text"}
                                  className="input-select"
                                
                                 
                                  value={duration}
                                  onChange={(e) =>{ 
                                    console.log(e.target.value,"duration update")
                                    //  setDurationbefore(e.target.value)
                                    const newDuration = e.target.value;
                                    setClock(true)
                                    console.log(newDuration, "duration update");
                                 
                                   setDuration(newDuration)
                                      let durationset = convertToHourMinuteFormat(newDuration)
                                   setDurationbefore(durationset)
                                   console.log(durationbefore,"durationbefore==>>")
                                  }}
                                />
                                 
                                {/* <span>{duration}</span> */}
                               
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="prefarance-form-list">
                                <label className="input-label">Arrive Early</label>
                              
                                <select
                                
                                  value={arrivel}
                                  onChange={(e) =>{
                                    console.log(e.target.value,"e.target.value==>>")
                                    handleDropDown(e.target.value)
                                  }}
                                  className="input-select"
                                >
                                  <span
                         
                                   
                                  >
                                    {arrivel
                                      ?arrivel
                                      : "Select an option"}
                                    {console.log(
                                      arrivel,
                                      "arrivel==>>>"
                                    )}
                                  </span>
                                  {durationlist?.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option.label}
                                      {console.log(option,"+++++++++++++++_--------------+_")}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="prefarance-form-list">
                                <label className="input-label">
                                  Game Notes
                                </label>
                                <input
                                  type="text"
                                  className="input-select"
                                  placeholder="Enter Name Of Volenteer "
                                  // defaultValue="Avaneesh Shett"
                                  value={gamenotes}
                                  onChange={(e) => setGameNotes(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="prefarance-form-list">
                                <label className="input-label">Uniforms</label>
                                <input
                                  type="text"
                                  className="input-select"
                                  placeholder="Enter uniform description"
                                  // defaultValue="Avaneesh Shett"
                                  value={uniform}
                                  onChange={(e) => setUniform(e.target.value)}
                                />
                              </div>
                            </div>
                            <div >
                              <label>
                                <input
                                  type="checkbox"
                                  checked={trackAvailability}
                                  // onChange={() => handleCheckboxChange('trackAvailability')}
                                  onChange={(e)=>{
                                    console.log("Checkbox checked:", e.target.checked);
                                    setTrackAvailability(e.target.checked)}}
                                />
                                Track Availability
                              </label>
                             </div>
                             <div>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={isEventCancel}
                                  // onChange={() => handleCheckboxChange('isEventCancel')}
                                  onChange={(e)=>{
                                    console.log("Checkbox checked:", e.target.checked);
                                    setIsEventCancel(e.target.checked)}}
                                />
                                 Is Event Cancel
                               </label>
                              </div>
                              <div>

                              <label>
                                <input
                                  type="checkbox"
                                  checked={notifyTeam}
                                  // onChange={() => handleCheckboxChange('notifyTeam')}
                                  onChange={(e)=>{
                                    console.log("Checkbox checked:", e.target.checked);
                                   setNotifyTeam(e.target.checked)}}
                                />
                                Notify Team
                              </label>

                              {/* <button onClick={updateBackendData}>Update Backend</button> */}
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-12">
                          <div className="prefarance-form-list">
                            <div style={{ display: "flex" }}>
                              {flag?.map((flag) => {
                                return (
                                  <div style={{ margin: "10px" }}>
                                    <img
                                      src={`${pic}${flag?.image}`}
                                      alt=""
                                      style={{ height: "30px", width: "30px" }}
                                    />
                                    <br></br>
                                    <input
                                      type="radio"
                                      name="radio"
                                      style={{ height: "30px", margin: "5px" }}
                                      onClick={selectFlag}
                                      value={flag._id}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div> */}
                        <div className="text-center mt-3">
                          <button
                            className="add-links"
                            // onClick={() => setModeValue(false)}
                            onClick={handleCancelEditGameEvent}
                          >
                            Cancel
                          </button>
                          <button
                            className="add-links ml-2"
                            style={{
                              backgroundColor: "#1d1b1b",
                            }}
                            onClick={
                              item.game_name
                                ? updateGameEvent
                                : updateGameEvents
                            }
                          >
                            Update
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamSchdule;
