import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
  useLocation,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import Logo from "../../../images/logo.png";
import UserProfile from "../../../images/user-profile.png";
import TeamList from "../../../images/team-list.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";

import flag from "../../../images/flag.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import { useHeaderContext } from "../ManagerContext/HeaderContext";
import ManagerHeader from "../../../Components/Header/ManagerHeader";

const TeamScorekeeper = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  // const {
  //   updateSelectedId,
  //   updateManagerRosterId,
  //   updateTeamId,
  //   updateTeamName,
  //   teamName,
  //   teamId,
  // } = useHeaderContext();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [dropdown, setDropdown] = useState([]);
  const [locationValue, setLocationValue] = useState([]);
  const [resData, setResData] = useState([]);
  const { gameID,gameName,teamid } = location.state;
  const[score,setScore]=useState([])
  console.log(gameID,"gameid---->>>>", resData)

  useEffect(() => {
    setUser(user);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    dropdownMenu();
    LocationData();
    // const team_id = localStorage.getItem("selectedTeamId");
    // teamRoster(team_id);
    scoreKeeper(gameID)
  }, []);
  const handleLogout = () => {
    // dispatch(logoutUser(null));
    localStorage.removeItem("user");
    setUserData(null);
    history.push("/");
  };

  const dropdownMenu = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      //console.log('user',user)

      Network(
        "api/my-team-list?team_manager_id=" + user._id,
        "GET",
        header
      ).then(async (res) => {
        console.log("dropdown----", res);
        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setDropdown(res.response_data);
      });
    }
  };
  const LocationData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      //console.log('user',user)

      Network("api/get-location-list", "GET", header).then(async (res) => {
        console.log("location----", res);
        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setLocationValue(res.response_data);
      });
    }
  };

  // console.log(dropdown,"dropdown");
  const teamRoster = () => {
    
    // console.log("team roster id", id);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      // console.log("user", user);

      Network(
        "api/getRoasterUserDetailsByTeamId?teamId=" + teamid,
        "GET",
        header
      ).then(async (res) => {
        // console.log("teamRoster----", res);

        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        //setResData(res.response_data)
        let mydata = res.response_data.filter((c) => c.user_id !== user._id);
        // // setResData(mydata)
        let Data = mydata?.map((item) => ({
          ...item,
          twopoints: 0,
          threepoints: 0,
          freethrows: 0,
        }));
        setResData(Data);
        // console.log(res.response_data.player,"response4545454")
        // setResData(res.response_data.player);
      });
    }
  };
  const scoreKeeper = (id) => {
    // console.log("team roster id", id);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      // console.log("user", user);

      Network("api/get-all-score-keeper-list?gameId="+id, "GET", header).then(
        async (res) => {
          // console.log("teamRoster----", res);
          console.log(res,"res--989898")

          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          if (res.success == true) {
            setScore(res.response_data)
            if(res.response_data.length===0)
            {
              // const id = teamId;
            teamRoster(teamid);
            }
           else{
            console.log(res.response_data, "response4545454");
            setResData(res.response_data);
           }
            }
        }
      );
    }
  };

  const twopointupdate = (value,index)=>{
    console.log(index,"value--->>>",value)

    let tempArr=[...resData]
    tempArr[index].twopoints=value
    console.log(tempArr, 'tempArr-=-=====-')
    setResData(tempArr)
    const updatetwopoint= resData.map((item,i)=>({
      ...item,
      twopoints:i==index?value:item.twopoints
    }))
    console.log(updatetwopoint, 'updatetwopoint......')
    setResData(updatetwopoint)
   
// console.log("==========>",tempArr[index])
  }
  const threepointupdate = (value,index)=>{
    console.log(index,"value--->>>",value)

    let tempArr=[...resData]
    tempArr[index].threepoints=value
    setResData([...tempArr])
    // const updatetwopoint= resData.map((item)=>({
    //   ...item,
    //   secondpoints:v.target.value
    // }))
    // setResData(updatetwopoint)
   

  }
  const freethroughupdate = (value,index)=>{
    console.log(index,"value--->>>",value)

    let tempArr=[...resData]
    tempArr[index].freethrows=value
    setResData([...tempArr])
    console.log(tempArr,"tempArr==>>>>>")
    // const updatetwopoint= resData.map((item)=>({
    //   ...item,
    //   secondpoints:v.target.value
    // }))
    // setResData(updatetwopoint)
   

  }

  // const change = (event) => {
  //   console.log("event", event);
  //   updateTeamId(event)
  // };

  const addTeamScore = (index) =>{
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(resData,"resData==>>>>")
    let Data = resData?.map((item) => ({
     
      rosterId:item.rosterId,
      gameId:gameID,
      twoPointsThrows:item.twopoints,
      threePointsThrows:item.threepoints,
      freeThrows:item.freethrows,
    }));
    console.log(Data,"data===>>+++")
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify({
        score: Data
      }),
    };

    fetch(
      "https://www.sports-cloud.io/sportsapi/api/add-score-keeper",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("player data", res);

        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message)
        }if (res.success == true) {
          toast.success(res.response_message)
          scoreKeeper(gameID)
           }
      });
    
    // if (user) {
    //   let header = {
    //     token: user.authtoken,
    //   };
    //   // console.log("user", user);
    //   console.log(Data,"Data==>>>+++++++")
    //  let Body= JSON.stringify({
    //     score:Data
    //   })

    //   Network("api/add-score-keeper", "POST", header,Body).then(
    //     async (res) => {
    //       // console.log("teamRoster----", res);
    //       console.log(res,"res--989898")

    //       if (res.response_code == 400) {
    //         dispatch(logoutUser(null));
    //         localStorage.removeItem("user");
    //         history.push("/");
    //         toast.error(res.response_message);
    //       }
    //       if (res.success == true) {
    //        toast.success(res.response_message)
    //        scoreKeeper(gameID)
    //         }
    //     }
    //   );
    // }

  
  }
  
  // const editTeamScore = (id,index) =>{
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   console.log(resData,"resData==>>>>")
  //   // let Data = resData?.map((item) => ({
     
      
  //   //   twoPointsThrows:item.twopoints,
  //   //   threePointsThrows:item.threepoints,
  //   //   freeThrows:item.freethrows,
  //   // }));
  //   const updatedPlayer = resData[index];
  //   console.log(updatedPlayer,"data===>>+++")
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       token: user.authtoken,
  //     },
  //     body: JSON.stringify({
  //       twoPointsThrows:updatedPlayer.twopoints,
  //     threePointsThrows:updatedPlayer.threepoints,
  //     freeThrows:updatedPlayer.freethrows,
  //     }),
  //   };

  //   fetch(
  //     "https://www.sports-cloud.io/sportsapi/api/edit-score-keeper?scoreId="+id,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((res) => {
  //       console.log("player data", res);

  //       if (res.response_code == 400) {
  //         dispatch(logoutUser(null));
  //         localStorage.removeItem("user");
  //         history.push("/");
  //         toast.error(res.response_message)
  //       }if (res.success == true) {
  //         toast.success(res.response_message)
  //         scoreKeeper(gameID)
  //          }
  //     });
    
    
  
  // }
   const updatescore = (index)=>{
    console.log(resData,"resdata--->>>>0000", index)
    
    const updatedPlayer = resData[index];
    console.log(updatedPlayer,"updatedPlayer====>>>>")
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(resData,"resData==>>>>")
    let Data = resData?.map((item) => ({
     
      rosterId:item.rosterId,
      gameId:gameID,
      twoPointsThrows:item.twopoints?item.twopoints:item.twoPointsThrows,
      threePointsThrows:item.threepoints?item.threepoints:item.threePointsThrows,
      freeThrows:item.freethrows?item.freethrows:item.freeThrows,
    }));
    console.log(Data,"data===>>+++")
    // return
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify({
        score: Data
      }),
    };

    fetch(
      "https://www.sports-cloud.io/sportsapi/api/edit-score-keeper",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("player data", res);

        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message)
        }if (res.success == true) {
          toast.success(res.response_message)
          scoreKeeper(gameID)
           }
      });
    
    
  }



  return (
    <div>
      <div className="dashboard-container">
        <div className="dashboard-main">
          <SideMenuComponents manger="manger" />
          <div className="dashboard-main-content">
            {/* <ManagerHeader change={change} /> */}
            {/* <div className="dashboard-head">
              <div className="teams-select">
                <select onChange={change} value={teamId}>
                  <option>Select A Team</option>
                  {dropdown?.map((dropdown) => {
                    return (
                      <option value={dropdown._id}>{dropdown.team_name}</option>
                    );
                  })}
                </select>
              </div>

              <div className="profile-head">
                <div className="profile-head-name">
                  {user ? user.fname : null}
                </div>
                <div className="profile-head-img">
                  {user ? (
                    <img src={user.profile_image} alt="" />
                  ) : (
                    <img src={UserProfile} alt="" />
                  )}
                </div>
              </div>
              <div className="login-account">
                <ul>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#myModallogin"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="manager-player-section">
              <h3>Match :</h3>
              <div className="teams-select3" style={{ marginRight: "37%" }}>
                {/* <select>
                  <option>Location</option>
                  {locationValue?.map((data) => {
                    return (
                      <option>
                        {data.locationName},{data.address}
                      </option>
                    );
                  })}
                </select> */}
                <h3>{gameName}</h3>
              </div>

              {/* <div className="teams-select3 teamSlct">
                <select>
                  <option>All </option>
                  <option> Team Availability</option>
                </select>
              </div> */}
            </div>

            <div className="prefarance-box">
              <div className="team-payment team-assesment">
                <table style={{ textAlign: "center" }}>
                  <tr>
                    <th style={{ fontSize: "25px" }}>Players</th>
                    <th style={{ fontSize: "25px" }}>2 Pointers</th>
                    <th style={{ fontSize: "25px" }}>3 Pointers</th>
                    <th style={{ fontSize: "25px" }}>Free Throws</th>
                    <th></th>
                  </tr>

                  {/* {resData.map((item,index)=>{
                      return(
                        <tr key={index}>
                       
                      <img src={UserProfile} alt="" />
                      <td style={{ fontSize: "30px" }}>
                        {item.fullName}
                     </td>
                       <td ><input type="text" 
                      value={item.twoPointsThrows==undefined?'0':item.twoPointsThrows}
                        
                    /></td>
                     <td >{item.threePointsThrows==undefined?'0':item.threePointsThrows}</td>
                     <td >{item.freeThrows==undefined?'0':item.freeThrows}</td>
                      </tr>

                      )
                    })}             */}

                  {resData
                  // .filter((c) => c.user_id !== user._id)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        {/* <img src={UserProfile} alt="" /> */}
                        {/* <td style={{ fontSize: "30px" }}>{item.rosterName ?item.rosterName:item.fullName}</td> */}
                       <td><span>{item.rosterName ?item.rosterName:item.fullName}</span></td> 
                        <td>
                          <input
                            type="text"
                            value={
                              item.twopoints}
                            onChange={(e)=>twopointupdate( e.target.value,index)}
                            defaultValue={item.twoPointsThrows}
                            style={{
                              border: 'none',
                              background: 'none',
                              outline: 'none', // Remove default focus border
                              color: 'black', // Set text color
                              textAlign:"center"
                              // fontSize:"120px"
                              // You can adjust padding, font size, etc., as needed
                            }}
                          />
                        </td>
                        <td>
                        <input
                        type="text"
                            value={item.threepoints }
                            onChange={(e)=>threepointupdate( e.target.value,index)}
                            defaultValue={item.threePointsThrows}
                            style={{
                              border: 'none',
                              background: 'none',
                              outline: 'none', // Remove default focus border
                              color: 'black', // Set text color
                              textAlign:"center"
                              // You can adjust padding, font size, etc., as needed
                            }}
                          />
                          
                        </td>
                        <td>
                        <input
                        type="text"
                            value={item.freethrows }
                            onChange={(e)=>freethroughupdate( e.target.value,index)}
                            defaultValue={item.freeThrows}
                            style={{
                              border: 'none',
                              background: 'none',
                              outline: 'none', // Remove default focus border
                              color: 'black', // Set text color
                              textAlign:"center"
                              // You can adjust padding, font size, etc., as needed
                            }}
                          />
                          
                        </td>
                        {/* <td>
                        <button className="add-links my-3" onClick={updatescore(item._id)}>Update</button>
                        </td> */}
                      </tr>
                    );
                  })}

                  {/* <td>
                  <button className="add-links my-3">ADD</button>
                  </td> */}
                </table>
              </div>
            
            </div>
            <div className="manager-player-section">
              <button className="add-links my-3" onClick={score.length == 0 ? addTeamScore : updatescore}>
                {score.length == 0 ? "ADD":"UPDATE"}
              {/* {console.log(score,"score000000===>>")} */}
              </button>
            </div>

            {/* <div className="prefarance-box">
              <div className="team-payment team-assesment">
                <table style={{ textAlign: "center" }}>
                  <tr>
                    <th style={{ fontSize: "30px" }}>Non-Players</th>
                    <th style={{ fontSize: "30px" }}>2 Pointers</th>
                    <th style={{ fontSize: "30px" }}>3 Pointers</th>
                    <th style={{ fontSize: "30px" }}>Free Throws</th>
                  </tr>

                  <tr>
                    <td>
                      <img src={UserProfile} alt="" />
                      <span>Jayanta Karmakor</span>
                    </td>
                    <td>2</td>
                    <td>
                      <span>1</span>
                    </td>
                    <td>2</td>
                    <td>
                      <div className="last-row">
                        <button
                          data-toggle="modal"
                          data-target="#assignmentdelect"
                        >
                          <img src={Delect} />
                        </button>{" "}
                        <button>
                          <img src={pencil} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src={UserProfile} alt="" />
                      <span>Jayanta Karmakor</span>
                    </td>
                    <td>2</td>
                    <td>
                      <span>1</span>
                    </td>
                    <td>2</td>
                    <td>
                      <div className="last-row">
                        <button
                          data-toggle="modal"
                          data-target="#assignmentdelect"
                        >
                          <img src={Delect} />
                        </button>{" "}
                        <button>
                          <img src={pencil} />
                        </button>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div> */}

            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamScorekeeper;
