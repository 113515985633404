import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import TeamList from "../../../images/team-list.png";
import SideMenuComponents from "../../../Components/SideMenu";
import flag from "../../../images/flag.png";
import SideNav from "./sideNav";

import { useDispatch } from "react-redux";
import axios from "axios";
import { Network } from "../../../Services/Api";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import PlayerHeader from "../../../Components/Header/PlayerHeader";
import { usePlayerHeaderContext } from "../../Players/PlayerContext/PlayerHeaderContext";

const PlayerAlert = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { playerSelectedId, playerrRosterId, playerTeamId, playerTeamName } =
    usePlayerHeaderContext();

  const [listValue, setList] = useState({
    email: false,
    alert: false,
    post: false,
  });
  const [rosterList, setRosterList] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [allNonPlayers, setAllNonPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [selectedNonPlayers, setSelectedNonPlayers] = useState([]);

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const newId = playerTeamId;
    getRosterList(newId);
  }, [playerTeamId]);
  const getRosterList = async (id) => {
    // const teamId = localStorage.getItem("ManagerTeamId");
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      let header = {
        token: user.authtoken,
      };
      Network(
        "api/getRoasterUserDetailsByTeamId?teamId=" + id,
        "GET",
        header
      ).then(async (res) => {
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setRosterList(res.response_data);
        const playerArray = res.response_data?.filter(
          (item) => item.userType === "player"
        );
        const nonPlayerArray = res.response_data?.filter(
          (item) => item.userType === "non_player"
        );
        setAllNonPlayers(nonPlayerArray);
        setAllPlayers(playerArray);
      });
    }
  };

  const handlePlayerCheckboxChange = (event, playerId) => {
    if (event.target.checked) {
      setSelectedPlayers([...selectedPlayers, playerId]);
    } else {
      setSelectedPlayers(selectedPlayers?.filter((id) => id !== playerId));
    }
  };

  const handleNonPlayerCheckboxChange = (event, nonPlayerId) => {
    if (event.target.checked) {
      setSelectedNonPlayers([...selectedNonPlayers, nonPlayerId]);
    } else {
      setSelectedNonPlayers(
        selectedNonPlayers?.filter((id) => id !== nonPlayerId)
      );
    }
  };

  const handleSelectAllPlayers = () => {
    const allPlayerIds = rosterList
      ?.filter((item) => item.userType === "player")
      ?.map((player) => player.user_id);
    setSelectedPlayers(allPlayerIds);
  };

  const handleSelectAllNonPlayers = () => {
    const allNonPlayerIds = rosterList
      ?.filter((item) => item.userType === "non_player")
      ?.map((nonPlayer) => nonPlayer.user_id);
    setSelectedNonPlayers(allNonPlayerIds);
  };

  const handleSelectNonePlayer = () => {
    setSelectedPlayers([]);
  };
  const handleSelectNoneNonPlayer = () => {
    setSelectedNonPlayers([]);
  };
  const handleSubjects = (event) => {
    setSubject(event.target.value);
  };
  const handleAlert = (event) => {
    setMessage(event.target.value);
  };

  const sendAlerts = async () => {
    if (selectedPlayers.length === 0 && selectedNonPlayers.length === 0) {
      toast.error("Please select recipients.");
      return;
    } else if (subject === "") {
      toast.error("Please add a subject.");
      return;
    } else if (message === "") {
      toast.error("Please write some message for Alert.");
      return;
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user._id;
    const teamId = localStorage.getItem("PlayerTeamId");
    const token = user.authtoken;
    let header = {
      token: token,
    };
    const mergeRecipients = [...selectedPlayers, ...selectedNonPlayers];
    let data = {
      user_id: userId,
      team_id: teamId,
      alert_to: mergeRecipients,
      subject: subject,
      message: message,
    };
    var config = {
      method: "post",
      url: "https://www.sports-cloud.io/sportsapi/api/createComposeAlert",
      headers: header,
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log("send Alert----", res.data);
        if (res.data.success === true) {
          setSelectedPlayers([]);
          setSelectedNonPlayers([]);
          setSubject("");
          setMessage("");
          toast.success("Successfully Alert Sent", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          toast.error("Something went wrong. Please try after some time", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        console.log("error", error.response.status);
        toast.error("Internel Server Error. Please try after some time", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };
  return (
    <div
      className='prefarance-box player-info'
      style={{ height: "100%", marginTop: "0px", borderRadius: "0px" }}
    >
      <SideMenuComponents manger='players' />
      <div className='dashboard-main-content'>
        <PlayerHeader />
        <div className='prefarance-box player-info mail_body mt-0'>
          <SideNav name='Alert' />
          <div className='tab-content'>
            <div className='tab-pane active' id='tabs-1' role='tabpanel'>
              <div className='prefarance-tab-content'>
                <div className='prefarance-form playerinfo-form'>
                  <div className='row'>
                    <div className='col-md-12'>
                      {/* <div className="prefarance-form-list">
                    <textarea
                      type="text"
                      className="input-select"
                      style={{
                        height: "210px",
                        padding: "32px",
                        fontSize: "larger",
                        color: "red",
                        border: "1px solid red",
                        borderColor: "red",
                      }}
                    >
                      About Alerting: Alerts are sent via push notifications or
                      text message. If neither of these are enabled, we'll send
                      alerts via email. Reply to alerts by creating a new alert
                      within the TeamSnap app. The more you say the word "alert"
                      the funnier it sounds. Alert alert alert. See?
                    </textarea>
                  </div> */}

                      <div className='prefarance-form-list'>
                        <label>Subject</label>
                        <input
                          type='text'
                          className='input-select'
                          onChange={handleSubjects}
                          value={subject}
                        />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='prefarance-form-list'>
                        <label> Your Alert</label>
                        <textarea
                          type='text'
                          className='input-select'
                          style={{ height: "210px" }}
                          onChange={handleAlert}
                          value={message}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className='row'
                    style={{
                      marginBottom: "15px",
                      paddingBottom: "28px",
                      borderBottom: "0.5px solid gray",
                    }}
                  >
                    <div className='col-md-7'>
                      <div className='prefarance-form-list'>
                        <p style={{ fontSize: "larger", color: "white" }}>
                          Player Recipients
                        </p>
                      </div>
                    </div>

                    <div className='col-md-2'>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        className='prefarance-form-list'
                        onClick={handleSelectAllPlayers}
                      >
                        <span style={{ color: "red" }}>Select All</span>
                      </button>
                    </div>
                    <div className='col-md-3'>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        className='prefarance-form-list'
                        onClick={handleSelectNonePlayer}
                      >
                        <span style={{ color: "red" }}>Select None</span>
                      </button>
                    </div>

                    {allPlayers?.length == 0 ? (
                      <div
                        style={{
                          color: "grey",
                          paddingTop: "30px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        No Players Available for this team
                      </div>
                    ) : (
                      allPlayers?.map((player) => (
                        <div className='col-md-4'>
                          <div className='prefarance-form-list'>
                            <input
                              type='checkbox'
                              checked={selectedPlayers?.includes(player?.user_id)}
                              onChange={(event) =>
                                handlePlayerCheckboxChange(
                                  event,
                                  player?.user_id
                                )
                              }
                              style={{ height: "15px", width: "17px" }}
                            />
                            <span>{player?.fullName}</span>
                          </div>
                        </div>
                      ))
                    )}

                    <div className='col-md-4'>
                      <div className='prefarance-form-list'></div>
                    </div>
                  </div>
                  <div
                    className='row'
                    style={{
                      marginBottom: "15px",
                      paddingBottom: "28px",
                      borderBottom: "0.5px solid gray",
                    }}
                  >
                    <div className='col-md-7'>
                      <div className='prefarance-form-list'>
                        <p style={{ fontSize: "larger", color: "white" }}>
                          Non-Player Recipients
                        </p>
                      </div>
                    </div>

                    <div className='col-md-2'>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        className='prefarance-form-list'
                        onClick={handleSelectAllNonPlayers}
                      >
                        <span style={{ color: "red" }}>Select All</span>
                      </button>
                    </div>
                    <div className='col-md-3'>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        className='prefarance-form-list'
                        onClick={handleSelectNoneNonPlayer}
                      >
                        <span style={{ color: "red" }}>Select None</span>
                      </button>
                    </div>
                    {allNonPlayers?.length == 0 ? (
                      <div
                        style={{
                          color: "grey",
                          paddingTop: "30px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        No Non-Players Available for this team
                      </div>
                    ) : (
                      allNonPlayers?.map((nonPlayer) => (
                        <div className='col-md-4'>
                          <div className='prefarance-form-list'>
                            <input
                              type='checkbox'
                              checked={selectedNonPlayers?.includes(
                                nonPlayer?.user_id
                              )}
                              onChange={(event) =>
                                handleNonPlayerCheckboxChange(
                                  event,
                                  nonPlayer?.user_id
                                )
                              }
                              style={{ height: "15px", width: "17px" }}
                            />
                            <span>{nonPlayer?.fullName}</span>
                          </div>
                        </div>
                      ))
                    )}
                  </div>

                  <div className='row'>
                    {/* <div className="col-md-12">
                      <div className="prefarance-form-list">
                        <label> Options</label>
                        <input
                          type="checkbox"
                          style={{ height: "15px", width: "17px" }}
                        />
                        <span>Send me a copy</span>
                      </div>
                    </div> */}
                    <div
                      className='col-md-12'
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className='prefarance-form-list'
                        // style={{ marginLeft: "63%" }}
                      >
                        <button
                          className='add-links'
                          style={{ margin: "10px" }}
                        >
                          Cancel
                        </button>
                        <button
                          className='add-links-secondary'
                          //   style={{
                          //     margin: "10px",
                          //     backgroundColor: "#1d1b1b",
                          //   }}
                          onClick={sendAlerts}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerAlert;
