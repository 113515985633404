import React, { useState, useEffect } from "react";
import { EyeFill, ZoomIn } from "react-bootstrap-icons";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
  useLocation
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import UserProfile from "../../../images/user-profile.png";
import flag from "../../../images/flag.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import Mappin from "../../../images/mappin.jpg";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";
import { Network } from "../../../Services/Api";
// import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth"
// ../Redux/Actions/auth";
import BigUserProfile from "../../../images/big-user-profile.png";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import { useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import PlayerHeader from "../../../Components/Header/PlayerHeader";
import { usePlayerHeaderContext } from '../../Players/PlayerContext/PlayerHeaderContext';
import { Form } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import Map from "../../../Components/Map/Map";

const TeamDetails = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  const data = location.state;
  console.log(data, "data===>>>>>>>>><<<<<<<<<")

  const [user, setUserData] = useState({})
  const [userMe, setUser] = useState(null);
  const [schedule, setSchedule] = useState([]);
  const [team, setTeam] = useState([]);
  const [eventditailsmodel, seteventDitailsmodel] = useState(false);
  const [gameditailsmodel, setgameDitailsmodel] = useState(false);
  const [eventdetails, setEventdetails] = useState(null);
  const [gamedetails, setGamedetails] = useState({});
  const [flagId, setFlagId] = useState("");
  const [id1, setId1] = useState("");
  const [key, setKey] = useState("details");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [eventlatitude, seteventLatitude] = useState(0);
  const [eventlongitude, seteventLongitude] = useState(0);
  const [gamevolunteer,setgameVolunteer]= useState([])
  const[eventvolunteer,seteventVolunteer]= useState([])
  const [lineup,setLineup]= useState([])

  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
  });

  const [isToggled, setIsToggled] = useState(null);

  const { playerSelectedId, playerrRosterId, playerTeamId, playerTeamName, eventId } = usePlayerHeaderContext();
  useEffect(() => {
    const newId = playerTeamId;


    // let user = userdata && userdata._id ? true : false;
    // console.log("userMe===>", user);
    setUser(user);
    // setUserData(userLocal);
    // console.log("USerData", userdata);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal)
    teamSchedule(newId);
    // teamRoster();
    eventDitails()
    gameDitails()

  }, [playerTeamId]);

  const teamSchedule = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const playerarosteraid = localStorage.getItem("PlayerRosterId")
    console.log(playerarosteraid, "===>>>rosterid")

    console.log("id<<<<<", `api/getAllEventAndGamesData?team_id=${id}&roster_id=${playerarosteraid}`,);
    if (user) {
      let header = {
        token: user.authtoken,
      };
      console.log("user", user);

      Network(
        // 'api/get-game-event-list-for-player?user_id='+user._id+'&page=1&limit=10',
        // "api/getAllEventAndGamesData?team_id="+"645cc97e6612dc1e4cd97597",
        "api/getAllEventAndGamesData?team_id=" + id + "&roster_id=" + playerarosteraid,
        // +'&page=1&limit=10',
        "GET",
        header
      ).then(async (res) => {
        console.log("schedule----", res);

        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/")
          toast.error(res.response_message);
        }
        setSchedule(res.response_data);
        console.log("res", res.response_data)
        // schedule?.map((item) => {
        //   setData(item.roster_availability_data[0]?._id)
        //   console.log(item.roster_availability_data[0]?._id,"09898989")
        // })


      });
    }
  };

  // const ditailsmodelvalue = (id, flag) => {
  //   setId1(id);
  //   flag == "Event" ? eventDitails(id) : gameDitails(id);
  //   if (flag == "Event") {
  //     seteventDitailsmodel(true);

  //   } else {
  //     setgameDitailsmodel(true);
  //   }
  //   console.log(flag, "======>>>>>>>>flag value");
  // };
  // const [getId, setGetId] = useState(null);
  // console.log(getId,"><><>>>>>>>>>>><<><---------getId")
  // useEffect(()=>{
  //   sessionStorage.getItem('flagId')&& setGetId( sessionStorage.getItem('flagId'))
  // },[])

  const eventDitails = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log(eventId.eventId, "redgfdgfgfdgfcddsdfs");
    axios({
      method: "get",

      url:
        //  getId!=null&& 
        " https://www.sports-cloud.io/sportsapi/api/getDetailsByEventId?events_id=" + data,
      //'https://www.sports-cloud.io/sportsapi/api/getRoasterDetailsById?rosterId=' + id,
      headers: {
        token: user.authtoken,
      },
    })
      .then(function (res) {
        console.log(res, "978767564554343456767475784789567856756");
        setEventdetails(res.data.response_data);
        seteventVolunteer(res.data.response_data.Volunteer)
        const coordinate =
        res.data.response_data?.location?.locationLatLong.split(" ");
      const parsedLatitude = parseFloat(coordinate[0]);
      const parsedLongitude = parseFloat(coordinate[1]);
      console.log(parsedLatitude,"===>>parsedLatitude")

      // seteventLatitude(parsedLatitude);
      // seteventLongitude(parsedLongitude);
      setLatitude(parsedLatitude);
          setLongitude(parsedLongitude);

        console.log(res.data.response_data, "147===>>>")
        if (res.response_code == 200) {
          seteventDitailsmodel(false);
          //   teamRoster(teamDropdown)
        }
      })
      .catch(function (res) {
        //  console.log(res)
      });
  };

  const gameDitails = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log(id, "redgfdgfgfdgfcddsdfs");
    axios({
      method: "get",

      url:
        "https://www.sports-cloud.io/sportsapi/api/getGameDetailsByGameId?game_id=" + data,
      //'https://www.sports-cloud.io/sportsapi/api/getRoasterDetailsById?rosterId=' + id,
      headers: {
        token: user.authtoken,
      },
    })
      .then(function (res) {
        console.log(res, "game ditails");
        setGamedetails(res.data.response_data);
        setgameVolunteer(res.data.response_data.Volunteer)
        setLineup(res.data.response_data.lineup)

        if (res.data.success == true) {
          setgameDitailsmodel(false);
          //   teamRoster(teamDropdown)
          const coordinate =
            res.data.response_data?.location?.locationLatLong.split(" ");
            console.log(coordinate,"coordinate==>>")
          const parsedLatitude = parseFloat(coordinate[0]);
          const parsedLongitude = parseFloat(coordinate[1]);
          setLatitude(parsedLatitude);
          setLongitude(parsedLongitude);
          console.log(parsedLatitude,"===>>parsedLatitude")

          const { away_team_score, home_team_score } =
            res?.response_data?.result;
          setFormData({
            field1: home_team_score,
            field2: away_team_score,
          });
          setIsToggled(res?.response_data?.result?.overtime);
        }
      })
      .catch(function (res) {
        //  console.log(res)
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const selectFlag = (event) => {
    setFlagId(event.target.value);
  };
  const AnyReactComponent = ({ text }) => (
    <div>
      <img
        src={Mappin}
        alt='Pin'
        style={{
          width: "30px",
          height: "auto",
        }}
      />
    </div>
  );


  return (
    <div>
      <div className='dashboard-container'>
        <div className='dashboard-main'>
          <SideMenuComponents manger='players' />
          <div className='dashboard-main-content'>
            <PlayerHeader
            // change={change} 
            />

            <div className='player-info-head availability'>
              <h2 className='page-title'>Game/Event Details</h2>
            </div>
            <div className='main-wrapper-prefarance-box'>
              <Tabs
                id='controlled-tab-example'
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className='mb-3c'
              >

                <Tab eventKey='details' title='Details'>
                  <div className='team-payment team-assesment'>
                    {/* <table>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Availability</th>
                      </tr>

                      {detailsData?.map((details) => {
                        return (
                          <tr>
                            <td>
                              <img
                                src={
                                  details.assignment_volunteer_roster_profile_pic
                                }
                                alt='cricket-icon'
                                className='img-fluid'
                                style={{ "max-width": 90 }}
                              />
                            </td>

                            <td>
                              <span>
                                {details.assignment_volunteer_roster_name}
                              </span>
                            </td>

                            <td>
                              <div className='last-row'>
                                <button
                                  style={{ color: "#ec3525" }}
                                  data-toggle='modal'
                                  data-target='#setAvailability'
                                >
                                  {details.at_an_event}
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </table> */}
                    {console.log(eventdetails, "0909event ditails")}

                    {eventdetails ? (
                      <>
                        <div className='plr_dtls_wrp' style={{ padding: "2% 5%" }}>

                          <div className="plr_dtls_wrp">
                            <div className="plr_dtls_itm">
                              <label> Arrival Time:</label>
                              <span> {eventdetails.arrival_time}</span>
                            </div>
                            <div className="plr_dtls_itm">
                              <label> Team Name :</label>
                              <span>{eventdetails?.team_id?.team_name}</span>
                            </div>
                            <div className="plr_dtls_itm">
                              <label> Location Name :</label>
                              <span>
                                {eventdetails?.location?.locationName}
                              </span>
                            </div>
                            <div className='plr_dtls_itm'>
                            <label> Location :</label>
                            <span>
                              {/* <GoogleMapReact
                                bootstrapURLKeys={{
                                  key: "AIzaSyCOKA-Ho3wav5X4fLl-bidLEuTx4SSf4qU",
                                  language: "en",
                                }}
                                style={{ height: `300px` }}
                                defaultZoom={10}
                                resetBoundsOnResize={true}
                                defaultCenter={{ lat: eventlatitude, lng: eventlongitude }}
                              >
                                <AnyReactComponent
                                  lat={eventlatitude}
                                  lng={eventlongitude}
                                  text='Your Marker Text'
                                />
                              </GoogleMapReact> */}
                              <Map latitude={latitude} longitude={longitude} />
                            </span>
                          </div>
                            <div className="plr_dtls_itm">
                              <label> Location Addrass:</label>
                              <span>
                                {eventdetails?.location?.address}
                              </span>
                            </div>
                            <div className="plr_dtls_itm">
                              <label> Start time :</label>
                              <span>{eventdetails?.start_time}</span>
                            </div>
                            <div className="plr_dtls_itm">
                              <label>End time :</label>
                              <span>{eventdetails?.end_time}</span>
                            </div>
                            <div className="plr_dtls_itm">
                              <label> Arrival time :</label>
                              <span>{eventdetails?.arrival_time}</span>
                            </div>
                            <div className="plr_dtls_itm">
                              <label>Event duration :</label>
                              <span> {eventdetails?.duration}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}




                    {/* //////game model */}




                    {gamedetails ? (
                      <>
                      <div className='plr_dtls_wrp' style={{ padding: "2% 5%" }}>
                        <div className="plr_dtls_wrp">
                          <div className="plr_dtls_itm">
                            <label>Game name :</label>
                            <span>{gamedetails.game_name}</span>
                          </div>
                          {/* <div className="plr_dtls_itm">
                                  <label>Opponent :</label>
                                  <span>
                                    {gamedetails?.opponent?.opponentsName}
                                  </span>
                                </div> */}
                          <div className="plr_dtls_itm">
                            <label>Team name :</label>
                            <span>{gamedetails?.team_id?.team_name}</span>
                          </div>
                          <div className='plr_dtls_itm'>
                            <label>Opponent :</label>
                            <span>
                              {gamedetails?.opponent?.contactName}
                            </span>
                          </div>

                          <div className='plr_dtls_itm'>
                            <label>Opponent Contact Name :</label>
                            <span>
                              {gamedetails?.opponent?.opponentsName}
                            </span>
                          </div>

                          <div className='plr_dtls_itm'>
                            <label>Opponent Email :</label>
                            <span>
                              {gamedetails?.opponent?.contactEmail}
                            </span>
                          </div>
                          <div className='plr_dtls_itm'>
                            <label>Opponent Contact Number :</label>
                            <span>
                              {gamedetails?.opponent?.contactPhoneNumber}
                            </span>
                          </div>
                          <div className="plr_dtls_itm">
                            <label>Location name :</label>
                            <span>
                              {gamedetails?.location?.locationName}
                            </span>
                          </div>
                          <div className='plr_dtls_itm'>

                            {/* map */}
                            <label> Location:</label>
                            <span>
                              {/* <GoogleMapReact
                                bootstrapURLKeys={{
                                  key: "AIzaSyCOKA-Ho3wav5X4fLl-bidLEuTx4SSf4qU",
                                  language: "en",
                                }}
                                style={{ height: `300px` }}
                                defaultZoom={10}
                                resetBoundsOnResize={true}
                                defaultCenter={{ lat: latitude, lng: longitude }}
                              >
                                <AnyReactComponent
                                  lat={latitude}
                                  lng={longitude}
                                  text='Your Marker Text'
                                />
                              </GoogleMapReact> */}
                               <Map latitude={latitude!==NaN ? latitude:"22.5629256"  } longitude={longitude !== NaN ? longitude:"88.3422595"} />
                            </span>
                          </div>
                          <div className="plr_dtls_itm">
                            <label>Location Address :</label>
                            <span>
                              {gamedetails?.location?.address}
                            </span>
                          </div>
                          <div className="plr_dtls_itm">
                            <label>Start time :</label>
                            <span>{gamedetails.start_time}</span>
                          </div>
                          <div className="plr_dtls_itm">
                            <label>End time :</label>
                            <span>{gamedetails.end_time}</span>
                          </div>
                          <div className="plr_dtls_itm">
                            <label>Arrival time :</label>
                            <span>{gamedetails.arrival_time}</span>
                          </div>
                          <div className="plr_dtls_itm">
                            <label>Game Duration :</label>
                            <span>{gamedetails.duration}</span>
                          </div>
                          <div className='plr_dtls_itm'>
                            <label>Home Team Score :</label>
                            <span>
                              <input
                                style={{
                                  backgroundColor: "#484848",
                                  color: "white",
                                  textAlign: "center",
                                }}
                                type='text'
                                name='field1'
                                value={formData.field1}
                                onChange={handleInputChange}
                              />
                            </span>
                          </div>
                          <div className='plr_dtls_itm'>
                            <label>Away Team Score :</label>
                            <span>
                              <input
                                style={{
                                  backgroundColor: "#484848",
                                  color: "white",
                                }}
                                type='text'
                                name='field2'
                                value={formData.field2}
                                onChange={handleInputChange}
                              />
                            </span>
                          </div>
                          <div className='plr_dtls_itm'>
                            <label>Overtime :</label>
                            <span>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type='switch'
                                  id='custom-switch'
                                  size='large'
                                  checked={isToggled}
                                  onChange={() => setIsToggled(!isToggled)}
                                />
                              </Form>
                            </span>
                          </div>
                        </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}




                  </div>
                </Tab>
                {gamedetails ? (
                <Tab eventKey='lineUp' title='Lineup'>
                
                  <div className='prefarance-box'>
                    <div className='team-payment team-assesment'>
                    
                      <table>
                        <tr>
                          {/* <th>Profile images</th> */}
                          <th>Roster Name</th>
                          <th>Position</th>
                          {/* <th>Availability</th> */}
                        </tr>

                        {lineup?.map((details) => {
                          return (
                            <tr>
                              <td className="table_td">
                                <img
                                  // src={
                                  //   details.assignment_volunteer_roster_profile_pic
                                  // }
                                  src={BigUserProfile}
                                  alt='cricket-icon'
                                  className='img-fluid'
                                  style={{ "max-width": 50 }}
                                />
                                 <span>
                                  {details?.assignment_lineup_roster_id?.firstName} {details?.assignment_lineup_roster_id?.lastName}
                                </span>
                              {/* </td>

                              <td> */}
                               
                              </td>
                              <td>
                               {details?.game_team_position_id === null ?<span> Not Assigned</span> :
                               <span> {details?.game_team_position_id?.position_title}</span>
                               }
                              </td>

                              {/* <td>
                                <div className='last-row'>
                                  <button
                                    style={{ color: "#ec3525" }}
                                    data-toggle='modal'
                                    data-target='#setAvailability'
                                  >
                                    {details.at_an_event}
                                  </button>
                                </div>
                              </td> */}
                            </tr>
                          );
                        })}
                      </table>
                      
                    </div>
                  </div>
                </Tab>
                ):("")}
                <Tab eventKey='assignment' title='Assignment'>
                  <div className='team-payment team-assesment'>
                    <table>
                      <tr>
                        {/* <th>Profile Image</th> */}
                        <th>Assignment</th>
                        <th>Roster Name</th>
                      </tr>
                      {/* {gamedetails?} */}

                      {gamevolunteer?.map((details) => {
                        return (
                          <tr>
                            <td  className="table_td">
                              <div>
                              <img
                                src={
                                  details.assignment_id.volunteer_assignment_image
                                }
                                alt='cricket-icon'
                                className='img-fluid'
                                style={{ "max-width": 50 }}
                              />
                              </div>
                               <span>
                                {details.assignment_id.volunteer_assignment_type}
                              </span>
                            </td>

                            {/* <td>
                             
                            </td> */}

                            <td>
                              {/* <div className='last-row'>
                                <button
                                  style={{ color: "#ec3525" }}
                                  data-toggle='modal'
                                  data-target='#setAvailability'
                                >
                                  {details.at_an_event}
                                </button>
                              </div> */}
                               {details?.assignment_volunteer_roster_id === null ? <span>Not Assigned</span>:
                              <span>
                                {details?.assignment_volunteer_roster_id?.firstName} {details?.assignment_volunteer_roster_id?.lastName}
                                </span>
                      }
                            </td>
                          </tr>
                        );
                      })}
                      {eventvolunteer?.map((details) => {
                        return (
                          <tr>
                            <td className="table_td">
                              <img
                                src={
                                  details.assignment_id.volunteer_assignment_image
                                }
                                alt='cricket-icon'
                                className='img-fluid'
                                style={{ "max-width": 50 }}
                              />
                               <span>
                                {details.assignment_id.volunteer_assignment_type}
                              </span>
                            </td>

                            {/* <td>
                             
                            </td> */}

                            <td>
                              {/* <div className='last-row'>
                                <button
                                  style={{ color: "#ec3525" }}
                                  data-toggle='modal'
                                  data-target='#setAvailability'
                                >
                                  {details.at_an_event}
                                </button>
                              </div> */}
                               {details?.assignment_volunteer_roster_id === null ? <span>Not Assigned</span>:
                              <span>
                                {details?.assignment_volunteer_roster_id?.firstName}[" "] {details?.assignment_volunteer_roster_id?.lastName}
                                </span>
                      }
                            </td>
                          </tr>
                        );
                      })}


                    </table>
                  </div>
                </Tab>
              </Tabs>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamDetails;