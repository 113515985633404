import React, { useState, useEffect } from "react";
import CsvDownload from "../../../Components/Comman/CsvDownload";
import { EyeFill, ZoomIn } from "react-bootstrap-icons";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import Logo from "../../../images/logo.png";
import UserProfile from "../../../images/user-profile.png";
import TeamList from "../../../images/team-list.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";

import flag from "../../../images/flag.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";

import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import BigUserProfile from "../../../images/big-user-profile.png";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import PlayerHeader from "../../../Components/Header/PlayerHeader";
import { usePlayerHeaderContext } from '../../Players/PlayerContext/PlayerHeaderContext';

function TeamRoster(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [player, setPlayer] = useState([]);
  const [resData, setResData] = useState({});
  const [nonPlayer, setNonPlayer] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [teamDropdown, setTeamDropDown] = useState("");
  const [team, setTeam] = useState([]);
  const [newplayerdata, setNewPlayerData] = useState([]);

  // console.log( newplayerdata,"++++++++++++++++++++===")
  const [newNonPlayerData, setNewNonPlayerData] = useState([]);
  const [profilePic, setProfilePic] = useState([]);
  const [gender, setGender] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [jursey, setJursey] = useState("");
  const [position, setPosition] = useState("");
  const [contact, setContact] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [stateData, setSateData] = useState("");
  const [zip, setZip] = useState("");
  const[jerseyNumber,setJerseyNumber] = useState("")
  const [timid, setTimid] = useState("");
  const [birthday, setBirthday] = useState("");
  const [memberType, setMemberType] = useState("");
  const [familyEmail, setFamilyEmail] = useState("");
  const [familyFname, setFamilyFname] = useState("");
  const [familyLname, setFamilyLname] = useState("");
  const [familyRelation, setFamilyRelation] = useState("");
  const[rosterid,setRosterid] = useState("")
  const [modeValue, setModeValue] = useState(false);
  const [uid, setUId] = useState("");
  const [id, setId] = useState("");
  const [modeValue1, setModeValue1] = useState(false);
  const [id1, setId1] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [imageId, setImageId] = useState("");
  const [image, Profile] = useState("");
  const [ditailsmodel, setDitailsmodel] = useState(false);
  const [ditails, setDitails] = useState({});
  console.log(ditails, "++++++++++++++++++++=== ditails");
  const { playerSelectedId, playerrRosterId, playerTeamId, playerTeamName } = usePlayerHeaderContext();

  const pic = "https://www.sports-cloud.io/sportsapi/roster/";
  const pic1 = "https://www.sports-cloud.io/sportsapi/profilepic/";

  // const [Nonplayer,setNonPlayer]= useState([]);

  useEffect(() => {
    const newId = playerTeamId;


    // let user = userdata && userdata._id ? true : false;
    // console.log("userMe===>", user);
    setUser(user);
    // console.log("USerData", userdata);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    teamSelect();
    updateProfile();
    teamRoster(newId);
    // teamRoster();
    playerDitails();
  }, [playerTeamId]);
  // [playerteamId]

  const updateProfile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        "token": user.authtoken,
      };
      // console.log("user", user);

      Network("api/getUserDetailsById?user_id=" + user?._id, "GET", header).then(
        async (res) => {
          // console.log("new Profile Pic----", res);
          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          setProfilePic(res.response_data.userDetailsObj);
        }
      );
    }
  };

  const handleLogout = () => {
    console.log("pruyuuuuuu", props);
    dispatch(logoutUser(null));
    localStorage.removeItem("user");
    setUserData(null);
    props.history.push("/");
  };

  const teamRoster = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("id------>", id);
    if (user) {
      let header = {
        token: user.authtoken,
      };
      console.log("user", user);

      // Network('api/getRosterListByTeamId?teamid=' +"6480285555cf8a5024960668", 'GET', header)
      Network("api/player-list-by-team-id?team_id=" + id, "GET", header).then(
        async (res) => {
          console.log("teamRoster----", res);

          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          setResData(res.response_data);
          // console.log(res)
          console.log("team player", res.response_data?.player);
          console.log("non player", res.response_data?.non_player);
          setPlayer(res.response_data.player);
          setNewPlayerData(
            res.response_data?.player?.filter((data) => {
              return data._id != null;
            })
          );
          // setNewPlayerData(res.response_data.player)

          // console.log( newplayerdata,"6767676769898988")
          setNonPlayer(res.response_data.non_Player);
          setNewNonPlayerData(
            res.response_data.non_player.filter((data) => {
              return data._id != null;
            })
          );
        }
      );
    }
  };

  const teamSelect = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      console.log("user", user);

      Network(
        // 'api/getAllAcceptedTeamListByPlayerId?playerId=' + "644a463b556e970345ff5be5",
        "api/getAllAcceptedTeamListByPlayerId?playerId=" + user._id,
        // "api/getAllAcceptedTeamListByPlayerId?playerId=" +"644a463b556e970345ff5be5",
        "GET",
        header
      ).then(async (res) => {
        console.log("res----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }

        setTeam(res.response_data);
        // if(res.response_data.length!=0){
        teamRoster(res?.response_data[0]._id);
        // }
      });
    }
  };

  const change = (event) => {
    console.log("event+++++++++++++++++++++", event.target.value);
    setTeamDropDown(event.target.value);
    teamRoster(event.target.value);
    setPlayer([]);
  };

  const deletePlayerData = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));

    console.log("id------>---->---->---->--->", id);
    const a = window.confirm("Are you sure you wish to delete this Data?");
    console.log("delete click");
    if (a == true) {
      const requestOptions = {
        method: "post",
        headers: {
          // 'Content-Type': 'application/json',
          // 'x-access-token': user.authtoken
          token: user.authtoken,
        },
        body: JSON.stringify({
          roster_id: id,
        }),
      };
      fetch(
        "https://www.sports-cloud.io/sportsapi/api/deleteRoasterDetailsById",
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          console.log("delete Player  data", res);
          if (res.response_code == 200) {
            console.log("deleted data", res);
            teamRoster(teamDropdown);
          }
          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }

          // setPlayer(player.filter(data => {
          //     return data._id != id
          // }))
          // setNonPlayer(nonPlayer.filter(data => {
          //     return data._id != id
          // }))
        });
    }
  };

  const updateModalValue = () => {
    setModeValue(true);
    setDitailsmodel(false);
    setRosterid (ditails?._id)
    setGender(ditails?.playerGender);
    setFName(ditails?.firstName);
    setLName(ditails?.lastName);
    setJursey(ditails?.jerseyNumber);
    setEmail(ditails?.contactInformationEmail);
    setPosition(ditails?.position);
    setCity(ditails?.contactInformationCity);
    setZip(ditails?.contactInformationZipCode);
    setSateData(ditails?.contactInformationState);
    setBirthday(ditails?.playerBirthday);

    setPhone(ditails?.contactInformationPhoneNumber);
    setMemberType(ditails?.whoIsThis);
    setTimid(ditails?.teamId)
    // setFamilyFname(ditails.familyMemberFirstName)
    // setFamilyLname(ditails.familyMemberLastName)
    // setFamilyEmail(ditails.familyMemberEmail)
    // setFamilyRelation(ditails.familyMemberRealationshipStatus)

  
    // setUId(uId);
    // setId(id1);
    // setGender(ditails[id]?.playerGender);
    // setFName(ditails[id]?.firstName);
    // setLName(ditails[id]?.lastName);
    // setJursey(ditails[id]?.jerseyNumber);
    // setEmail(ditails[id]?.contactInformationEmail);
    // setPosition(ditails[id]?.position);
    // setCity(ditails[id]?.contactInformationCity);
    // setZip(ditails[id]?.contactInformationZipCode);
    // setSateData(ditails[id]?.contactInformationState);
    // setBirthday(ditails[id]?.playerBirthday);

    // setPhone(ditails[id]?.contactInformationPhoneNumber);
    // setMemberType(ditails[id]?.whoIsThis);
    // setTimid(ditails[id]?.teamId)
    // setJerseyNumber(ditails[id]?.jerseyNumber)
  };
  const updateModalValue1 = (id1, uId) => {
    setModeValue1(true);
    setUId(uId);
    setId1(id1);
  };

  const handleChange = (event) => {
    console.log(
      "URL.createObjectURL(event.target.files[0])---->",
      URL.createObjectURL(event.target.files[0])
    );
    Profile(event.target.files[0]);
    // addShopData(event.target.files[0])
  };
  const updateData=()=>{
    const user = JSON.parse(localStorage.getItem("user"));
    const teamid =  localStorage.getItem("playerselectedTeamId");
    console.log (user,"-->>>")
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "token": user.authtoken,
      },
      body:JSON.stringify({
        // rosterId :"649ad222dd1e2a112f4d280e",
        rosterId :rosterid,
        email: email,
        firstName: fname,
        lastName: lname,
        playerGender: gender,
        contactInformationCity: city,
        contactInformationZipCode: zip,
        playerBirthday: birthday,
        contactInformationState: stateData,
        contactInformationAddress: address1,
      
        phone: phone,
        // member_type: memberType,
        jerseyNumber: jursey,
        position: position,
        // family_member: [
        //   {
        //     name: "jay",
        //     email: "jayantakarmakar.brainium@gmail.com",
        //     phone: 123453,
        //   },
        // ],
        addFamilyMemberFirstName : familyFname,
        addFamilyMemberLastName: familyLname,
        addFamilyMemberRelationshipStatus: familyRelation,
        addFamilyMemberEmail:familyEmail,
      }),
    };
    fetch(
      "https://www.sports-cloud.io/sportsapi/api/editRoasterdetailsById",
      requestOptions
    )
     .then((response) => response.json())
      .then((res) => {
        console.log("update Player data", res);
        if (res.success == true) {
          toast.success(res.response_message
            );
          setModeValue(false);
          // setModeValue1(false);
          // teamRoster(teamid);
          teamRoster(teamid);
        }
        if (res.success == false) {
          toast.error(res.response_message
            );
          // setModeValue(false);
          // setModeValue1(false);
          
        }

        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
      });

  }
  // const updatePlayerData = () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   console.log (user,"-->>>")
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       // "Content-Type": "application/json",
  //       "token": user.authtoken,
  //     },
  //     body:JSON.stringify({
  //       rosterId : rosterid,
  //       email: email,
  //       firstName: fname,
  //       lastName: lname,
  //       playerGender: gender,
  //       contactInformationCity: city,
  //       contactInformationZipCode: zip,
  //       playerBirthday: birthday,
  //       contactInformationState: stateData,
  //       contactInformationAddress: address1,
      
  //       phone: phone,
  //       // member_type: memberType,
  //       jerseyNumber: jursey,
  //       position: position,
  //       // family_member: [
  //       //   {
  //       //     name: "jay",
  //       //     email: "jayantakarmakar.brainium@gmail.com",
  //       //     phone: 123453,
  //       //   },
  //       // ],
  //       addFamilyMemberFirstName : familyFname,
  //       addFamilyMemberLastName: familyLname,
  //       addFamilyMemberRelationshipStatus: familyRelation,
  //       addFamilyMemberEmail:familyEmail,
  //     }),
  //   };
  //   fetch(
  //     "https://www.sports-cloud.io/sportsapi/api/editRoasterdetailsById",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((res) => {
  //       console.log("update Player data", res);
  //       if (res.success == true) {
  //         toast.success(res.response_message
  //           );
  //         setModeValue(false);
  //         // setModeValue1(false);
  //         teamRoster(playerTeamId);
  //       }

  //       if (res.response_code == 400) {
  //         dispatch(logoutUser(null));
  //         localStorage.removeItem("user");
  //         history.push("/");
  //         toast.error(res.response_message);
  //       }
  //     });
  // };
  const imageModalOpen = (id1, uId) => {
    setImageModal(true);
    setUId(uId);
    setImageId(id1);
  };
  const updateImage = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const formData = new FormData();
    formData.append("profile_image", image);
    formData.append("player_id", uid);
    axios(
      "https://www.sports-cloud.io/sportsapi/api/add-update-player-profile-image",
      {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": user.authtoken,
        },
        data: formData,
      }
    ).then((res) => {
      console.log("edit player Image", res);
      if (res.status == 200) {
        toast.success("Edit Succecfull");
        console.log("edit player Image", res);
        setImageModal(false);
        teamRoster(teamDropdown);
      }

      if (res.response_code == 400) {
        dispatch(logoutUser(null));
        localStorage.removeItem("user");
        history.push("/");
        toast.error(res.response_message);
      }
    });
  };
  const ditailsmodelvalue = (uId, id) => {
    setDitailsmodel(true);
    setUId(uId);
    setId1(id);
    //set the  values for the  form.
    // setGender(newplayerdata[uId].playerGender);
    // setFName(newplayerdata[uId]?.firstName);
    // setLName(newplayerdata[uId]?.lastName);
    // setJursey(newplayerdata[uId]?.jerseyNumber);
    // setEmail(newplayerdata[uId]?.contactInformationEmail);
    // setPosition(newplayerdata[uId]?.position);
    // setCity(newplayerdata[uId]?.contactInformationCity);
    // setZip(newplayerdata[uId]?.contactInformationZipCode);
    // // setSateData(newplayerdata[uId]?.contactInformationState);
    // // setBirthday(newplayerdata[uId]?.playerBirthday);

    // setPhone(newplayerdata[uId]?.contactInformationPhoneNumber);
    // setMemberType(newplayerdata[uId]?.whoIsThis);
    // setTimid(newplayerdata[uId]?.teamId)
    
    playerDitails(id);
  };

  const playerDitails = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log(user._id,"user idghkmh")
    axios({
      method: "get",

      url:
        "https://www.sports-cloud.io/sportsapi/api/getRoasterDetailsById?rosterId=" +
        id,
      headers: {
        token: user.authtoken,
      },
    })
      .then(function (res) {
        console.log(res, "978767564554343456767475784789567856756");
        setDitails(res.data.response_data);
   
        if (res.response_code == 200) {
          setDitailsmodel(false);
          //   teamRoster(teamDropdown)
        }
      
      })
      .catch(function (res) {
        //  console.log(res)
      });
  };
  let headers = [
    { label: "Firstname", key: "firstName" },
    { label: "lastname", key: "lastName" },
    { label: "email", key: "contactInforrmationEmail" },
    { label: "phone", key: "contactInformationPhoneNumber" },
    { label: "Gender", key: "playerGender" },
  ];

  const allPlayers = newplayerdata.concat(newNonPlayerData);
  let data = allPlayers && allPlayers.length > 0 ? allPlayers : [];

  console.log(team);
  return (
    <div>
      <div className="dashboard-container">
        <div className="dashboard-main">
          <SideMenuComponents />
          <div className="dashboard-main-content">
            {/* <div className="dashboard-head">
              <div className="teams-select">
                <select value={teamDropdown} onChange={change}>
                  <option>Select Team</option>
                  {team?.map((team) => {
                    console.log("676767555", team);

                    return (
                      <option value={team?.accept_invite_team_id}>
                        {team?.accept_invite_team_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="profile-head">
              {profilePic?.fname ? (
                  <div className="profile-head-name">
                   
                    {profilePic?.fname + " " + profilePic?.lname}
                  </div>
                ) : (
                  <div className="profile-head-name">{profilePic?.fname} {profilePic?.lname}</div>
                )}
                <div className="profile-head-img">
                  {profilePic?.profile_image == null ? (
                    <img src={BigUserProfile} alt="" />
                  ) : (
                    <img src={`${pic1}${profilePic?.profile_image}`} alt="" />
                    <img src={user?.profile_image} alt="" />
                  )}
                </div>
              </div>
              <div className="login-account">
                <ul>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#myModallogin"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                  <li><a href="#" data-toggle="modal" data-target="#myModalregister" onClick={handleLogout}>Logout</a></li>
                </ul>
              </div>
            </div> */}
            <PlayerHeader />

            <div className="prefarance-page">

              {/* csv export */}
              <div className="player-info-head">
                <h2 className="page-title">Roster</h2>
                <div className="player-info-head-right">
                  {/* <button className="edit-btn" style={{ width: "265px" }} onClick={() => history.push('./TeamPlayerInfo')}>Manage My Player Info</button> */}
                  {/* <button
                    className="add-new-family"
                    onClick={() => history.push("./TeamPlayerInfo")}
                  >
                    + Add or Edit My Family Member
                  </button> */}
                  {/* <button className="edit-btn" style={{ marginLeft: "5px" }} onClick={() => history.push('./Export')}>Export</button> */}
                  <CsvDownload
                    data={data}
                    headers={headers}
                    filename={`Roster list `}
                  />
                </div>
              </div>

{/* extra section for manager */}
              {user.user_type == "manager" ? (
                <div className="manager-player-section">
                  <h3>Manager</h3>
                  <ul>
                    <li onClick={() => history.push("./AddPlayer")}>
                      <a href="#" style={{ color: "red" }}>
                        + Add Player
                      </a>
                    </li>
                    <li onClick={() => history.push("./ImportPlayer")}>
                      <a href="#" style={{ color: "red" }}>
                        Import Players
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        style={{ color: "red" }}
                        onClick={() => history.push("./AnotherPlayer")}
                      >
                        Import From Another Teams
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}

{/* player count */}
              <div className="manager-player-section">
                <h3>Players</h3>

                <span>Total Player {resData?.total_player}(Men:3,Women:2)</span>
              </div>


              <div className="prefarance-box">
                <div className="team-payment mt-0 p-4">
                  <table>
                    <tr>
                      <th>Photo</th>
                      <th>Name</th>
                      <th>Male/Female</th>
                      <th>Jursey No</th>
                      <th>Contact Info</th>
                      <th>Position</th>
                      <th>Actions</th>
                    </tr>
                    {newplayerdata && newplayerdata.length > 0 ? (
                      <>
                        {newplayerdata?.map((player, i) => {
                          return (
                            <>
                              {player._id != null ? (
                                <>
                                  <tr>
                                    <td
                                      onClick={() =>
                                        imageModalOpen(i, player.member_id._id)
                                      }
                                    >
                                      {player.member_id?.profile_image ==
                                        null ? (
                                        <img src={UserProfile} alt="" />
                                      ) : (
                                        <img
                                          src={`${pic1}${player.member_id?.profile_image}`}
                                          alt=""
                                          style={{
                                            height: "50px",
                                            width: "50px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      <span>
                                        {player.firstName} {player.lastName}
                                        
                                      </span>
                                    </td>
                                    <td>
                                      <div className="game-name">
                                        {player.playerGender
                                          ? player.playerGender
                                          : "-"}
                                        {/* {(player.member_id.gender)==Male ? player.member_id.gender : null} */}
                                      </div>
                                    </td>
                                    <td>
                                      <span>{player.jerseyNumber ? player.jerseyNumber : "-"}</span>
                                    </td>
                                    <td>
                                      <span>
                                        {player.contactInformationAddress ? player.contactInformationAddress : "-"}
                                      </span>
                                    </td>
                                    {/* <td>{player._id?.fname}<br></br>
                                                                                        {player._id.email}

                                                                                    </td> */}
                                    <td>
                                      <span>{player.position ? player.position : "-"}</span>
                                      {/* <div className="last-row">
                                                                                            <p>{player.position}</p> 
                                                                                            <button data-toggle="modal" data-target="#assignmentdelect" onClick={() => deletePlayerData(player.member_id._id)} ><img src={Delect} /></button>
                                                                                            <button onClick={() => updateModalValue(i, player.member_id._id)}><img src={pencil} /></button>
                                                                                        </div> */}
                                                                                       
                                    </td>
                                    <td>
                                      <div className="text-center">
                                        {/* <button data-toggle="modal" data-target="#assignmentdelect" onClick={() => deletePlayerData(player._id)} ><img src={Delect} /></button> */}
                                        <button
                                          className="btn p-0"
                                          onClick={() =>
                                            ditailsmodelvalue(i, player._id)
                                          }
                                        >
                                          <EyeFill style={{ color: "white" }} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={7} className="text-center text-red">
                            Please select team
                          </td>
                        </tr>
                      </>
                    )}
                  </table>
                </div>
                {modeValue ? (
                  <Modal show={modeValue} size="md">
                    <Modal.Body>
                      <div className="prefarance-form playerinfo-form">
                        <h1 className="m-title">Edit Player Details</h1>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Gender</h2>
                              <select
                                className="input-select"
                                onChange={(e) => setGender(e.target.value)}
                                defaultValue={
                                 ditails?.playerGender
                                }
                              >
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> First Name of Player</h2>
                              {console.log(fname,"fname===>>>>>>>>")}
                              <input
                                type="text"
                                className="input-select"
                                // placeholder={fname}
                               
                                onChange={(e) => setFName(e.target.value)}
                                defaultValue={
                              // fname&&fname!=undefined||"" ? fname:""
                              ditails?.firstName
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Last Name of Player</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Virtual Practice "
                                onChange={(e) => setLName(e.target.value)}
                                defaultValue={
                                  ditails?.lastName
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Jursey Number </h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter jursey Number "
                                onChange={(e) => setJursey(e.target.value)}
                                defaultValue={ditails?.jerseyNumber}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2>Email</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="enter youe Email"
                                onChange={(e) => setEmail(e.target.value)}
                                defaultValue={ditails?.contactInformationEmail}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Player Position</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="enter your position"
                                onChange={(e) => setPosition(e.target.value)}
                                defaultValue={ditails?.position}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> City</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="enter uyour city"
                                onChange={(e) => setCity(e.target.value)}
                                defaultValue={ditails?.contactInformationCity}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Zip</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter your Zip code "
                                onChange={(e) => setZip(e.target.value)}
                                defaultValue={ditails?.contactInformationZipCode}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> State</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="enter your state "
                                onChange={(e) => setSateData(e.target.value)}
                                defaultValue={ditails?.contactInformationState}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Birthday</h2>
                              <input
                                type="date"
                                className="input-select"
                                placeholder="enter your birth date"
                                onChange={(e) => setBirthday(e.target.value)}
                                defaultValue={ditails?.playerBirthday}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Address Line1</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="enter your address "
                                onChange={(e) => setAddress1(e.target.value)}
                                defaultValue={ditails?.contactInformationAddress}
                              />
                            </div>
                          </div>
                      
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Phone Number</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="enter your phone number"
                                onChange={(e) => setPhone(e.target.value)}
                                defaultValue={ditails?.contactInformationPhoneNumber}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Family Member First Name</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="enter your family menber first name "
                                onChange={(e) => setFamilyFname(e.target.value)}
                                defaultValue={ditails?.familyMemberFirstName}
                              
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2>Family Member Last Name</h2>
                              <input
                                type="text"
                                className="input-select"
                                // placeholder={ditails?.familyMemberLastName}
                                placeholder="enter your family member last name"
                                onChange={(e) => setFamilyLname(e.target.value)}
                                defaultValue={ditails?.familyMemberLastName}
                               
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2>  Family Member Email</h2>
                              <input
                                type="text"
                                className="input-select"
                                // placeholder={ditails?.familyMemberEmail}
                                placeholder="enter your family member email"
                                onChange={(e) => setFamilyEmail(e.target.value)}
                                defaultValue={ditails?.familyMemberEmail}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2>Relation with Family Member</h2>

                              <select
                                className="input-select"
                                placeholder="enter your relationship status with family member"
                                onChange={(e) => setFamilyRelation(e.target.value)}
                                defaultValue={ditails?.addFamilyMemberRelationshipStatus}
                              >
                                
                                <option>father</option>
                                <option>mother</option>
                                <option>guardian</option>
                                
                                
                              </select>
                            </div>
                          </div>
                          {/* <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Member Type</h2>

                              <select
                                className="input-select"
                                onChange={(e) => setMemberType(e.target.value)}
                              >
                                <option>Select</option>
                                <option>PLAYER</option>
                                <option>MANAGER</option>
                              </select>
                            </div>
                          </div> */}
                        </div>

                        <button
                          className="add-links"
                          style={{ margin: "10px" }}
                          onClick={() => setModeValue(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="add-links"
                          style={{ margin: "10px", backgroundColor: "#1d1b1b" }}
                          onClick={(e) => updateData(e)}

                        >
                          
                          Update
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : (
                  ""
                )}

                {imageModal ? (
                  <Modal show={imageModal} size="md">
                    <Modal.Body>
                      <div className="prefarance-form playerinfo-form">
                        <h1 className="m-title">Edit Player Details</h1>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="update-team-photo"
                              style={{ width: "100%" }}
                            >
                              Choose Image
                              <input
                                type="file"
                                name="img"
                                onChange={(event) => handleChange(event)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            className="add-links"
                            style={{ margin: "10px" }}
                            onClick={() => setImageModal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="add-links"
                            style={{
                              margin: "10px",
                              backgroundColor: "#1d1b1b",
                            }}
                            onClick={updateImage}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : (
                  ""
                )}

                {/* {modeValue1 ? (
                  <Modal
                    show={modeValue1}
                    style={{ position: "absolute", top: "206px" }}
                  >
                    <Modal.Body>
                      <div className="prefarance-form playerinfo-form">
                        <h1
                          style={{
                            color: "red",
                            paddingBottom: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Edit Game/Event
                        </h1>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Gender</h2>
                              <select
                                className="input-select"
                                onChange={(e) => setGender(e.target.value)}
                                defaultValue={
                                  newNonPlayerData[id1].member_id.gender
                                }
                              >
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> First Name of Player</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="enter Player First Name... "
                                onChange={(e) => setFName(e.target.value)}
                                defaultValue={
                                  newNonPlayerData[id1].member_id?.fname
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Last Name of Player</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter Player Last Name... "
                                onChange={(e) => setLName(e.target.value)}
                                defaultValue={
                                  newNonPlayerData[id1].member_id?.lname
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Jursey Number </h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter Jursey Number... "
                                onChange={(e) => setJursey(e.target.value)}
                                defaultValue={
                                  newNonPlayerData[id1].jersey_number
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2>Email</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter Email Address.. "
                                onChange={(e) => setEmail(e.target.value)}
                                defaultValue={
                                  newNonPlayerData[id1].member_id.email
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Player Position</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter Player Position..."
                                onChange={(e) => setPosition(e.target.value)}
                                defaultValue={newNonPlayerData[id1].position}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> City</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter City..."
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Zip</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter Zip Code... "
                                onChange={(e) => setZip(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> State</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter State... "
                                onChange={(e) => setSateData(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Birthday</h2>
                              <input
                                type="date"
                                className="input-select"
                                placeholder="Select Birdthady... "
                                onChange={(e) => setBirthday(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Address Line1</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter Address Line1... "
                                onChange={(e) => setAddress1(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Address Line 2</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter Address Line 2... "
                                onChange={(e) => setAddress2(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Phone Number</h2>
                              <input
                                type="text"
                                className="input-select"
                                placeholder="Enter Phone Number... "
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="prefarance-form-list">
                              <h2> Member Type</h2>

                              <select
                                className="input-select"
                                onChange={(e) => setMemberType(e.target.value)}
                              >
                                <option>Select</option>
                                <option>PLAYER</option>
                                <option>MANAGER</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <button
                          className="add-links"
                          style={{ margin: "10px" }}
                          onClick={() => setModeValue1(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="add-links"
                          style={{ margin: "10px", backgroundColor: "#1d1b1b" }}
                          onClick={updatePlayerData}
                        >
                          Update
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : (
                  ""
                )} */}

                {/* detials view of player */}
                {ditailsmodel ? (
                  <Modal show={ditailsmodel} size="lg" centered>
                    <Modal.Body>
                      <div className="prefarance-form playerinfo-form">
                        <h1 className="m-title">Player Details</h1>

                        {ditails ? (
                          <>
                            <div className="plr_dtls_wrp">
                              {/* <div className='plr_dtls_itm'> <label>Player ID: </label><span> {ditails._id}</span></div> */}
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Player Name: </label>
                                <span>
                                  {" "}
                                  {` ${ditails.firstName} ${ditails.lastName} `}
                                </span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Player gender: </label>
                                <span> {ditails.playerGender ? ditails.playerGender : "-"}</span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Team Name: </label>
                                <span>
                                  {" "}
                                  {team && team.length > 0
                                    ? team.map((teamone) => {
                                      return teamone.accept_invite_team_id ==
                                        ditails.teamId
                                        ? teamone.accept_invite_team_name
                                        : "";
                                    })
                                    : ""}
                                </span>
                              </div>
                              {/* <div className='plr_dtls_itm'> <label>Manager ID: </label><span>{ditails.managerId}</span></div> */}
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Email: </label>
                                <span>{ditails.contactInformationEmail ? ditails.contactInformationEmail : "-"}</span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Address: </label>
                                <span>{ditails.contactInformationAddress ?ditails.contactInformationAddress  : "-"}</span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>City: </label>
                                <span>{ditails.contactInformationCity ? ditails.contactInformationCity  : "-"}</span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>State: </label>
                                <span>{ditails.contactInformationState ? ditails.contactInformationState : "-"}</span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Zip: </label>
                                <span>{ditails.contactInformationZipCode ? ditails.contactInformationZipCode: "-"}</span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Member Type: </label>
                                <span>
                                  {ditails.nonPlayer == false
                                    ? "Player"
                                    : "nonplayer"}
                                </span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Birth day: </label>
                                <span>{ditails.playerBirthday ? ditails.playerBirthday: "-"}</span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Position: </label>
                                <span>{ditails.position ? ditails.position : "-"}</span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Jersey no: </label>
                                <span>{ditails.jerseyNumber ? ditails.jerseyNumber : "-"}</span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Phone: </label>
                                <span>
                                  {ditails.contactInformationPhoneNumber ? ditails.contactInformationPhoneNumber : "-"}
                                </span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Family Member FirstName: </label>
                                <span>
                                  {ditails.familyMemberFirstName ? ditails.familyMemberFirstName : "-"}
                                </span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Family Member LastName: </label>
                                <span>
                                  {ditails.familyMemberLastName ? ditails.familyMemberLastName : "-"}
                                </span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Family Member Email: </label>
                                <span>
                                  {ditails.familyMemberEmail ? ditails.familyMemberEmail : "-"}
                                </span>
                              </div>
                              <div className="plr_dtls_itm">
                                {" "}
                                <label>Family Member Realationship Status: </label>
                                <span>
                                  {ditails.familyMemberRealationshipStatus ? ditails.familyMemberRealationshipStatus : "-"}
                                </span>
                              </div>
                            </div>
                            <div>
                              {/* <p>Team Name: {ditails.team_name}</p> */}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div class="d-flex justify-content-center gap-3">
                        <div className="text-center">
                        {ditails?.member_id === user._id ? <>
                        {ditails?.familyMemberEmail ==null ?
                          <button  className="add-links" onClick={() => updateModalValue() } style={{ width: "220px" }} >add family member</button>
                          : ""
                        }
                          {/* <button  className="add-links" style={{ backgroundColor: "#181717", marginRight: "5px" }}>edit</button> */}
                          <button onClick={() => updateModalValue() } className="add-links" style={{ backgroundColor: "#181717", marginRight: "5px" }}>edit</button>
                          </> : ""
                          }
                          
                        </div>
                        <div className="text-center">
                          <button
                            className="add-links"
                            onClick={() => setDitailsmodel(false)}
                          >
                            Close
                          </button>
                         
                        </div>
                        </div>
                        {/* <div className="text-center">
                          <button
                            className="add-links"
                            onClick={() => setDitailsmodel(false)}
                          >
                            Close
                          </button>
                          <button>add family member</button>
                          <button>edit</button>
                        </div>
                        <div className="text-center">
                          <button
                            className="add-links"
                            onClick={() => setDitailsmodel(false)}
                          >
                            Close
                          </button>
                          <button>add family member</button>
                          <button>edit</button>
                        </div> */}
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : (
                  ""
                )}
              </div>

              <div className="manager-player-section">
                <h3> Non-Players</h3>
                {/* <ul>
                                    <li><a href="#">New</a></li>
                                    <li><a href="#">Edit</a></li>
                                    <li><a href="#">Import</a></li>
                                </ul> */}
                <span>
                  Total Player {resData?.total_non_players}(Men:3,Women:2)
                </span>
              </div>
              <div className="prefarance-box">
                <div className="team-payment team-assesment">
                  <table>
                    <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Male/Female</th>
                    <th>Jursey No</th>
                    <th>contact Info</th>
                    <th>Position</th>
                    <th>Actions</th>
                    </tr>

                    {newNonPlayerData && newNonPlayerData.length > 0 ? (
                      <>
                        {newNonPlayerData?.map((non_Player, i) => {
                          return (
                            <>
                              {non_Player._id != null ? (
                                <>
                                  <tr>
                                    
                                    <td
                                      onClick={() =>
                                        imageModalOpen(
                                          i,
                                          non_Player.member_id._id
                                        )
                                      }
                                    >
                                      {non_Player.member_id?.profile_image ==
                                        null ? (
                                        <img src={UserProfile} alt="" />
                                      ) : (
                                        <img
                                          src={`${pic1}${non_Player.member_id?.profile_image}`}
                                          alt=""
                                          style={{
                                            height: "50px",
                                            width: "50px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                    </td>
                                    
                                    <td>
                                      <span>
                                        {non_Player.firstName} {non_Player.lastName}
                                       
                                      </span>
                                    </td>
                                    <td>
                                      <div className="game-name">
                                        {non_Player.playerGender
                                          ? non_Player.playerGender
                                          : "-"}
                                      </div>
                                    </td>
                                    <td>
                                      <span>{non_Player.jerseyNumber ? non_Player.jerseyNumber : "-"}</span>
                                    </td>
                                    <td>
                                      <span>
                                        {non_Player.contactInformationAddress ? non_Player.contactInformationAddress : "-"}
                                      </span>
                                      {/* {nonPlayer.member_id?.fname}<br></br>
                                                                                    {nonPlayer.member_id.email} */}
                                    </td>
                                    <td>
                                      <span>{non_Player.position ? non_Player.position : "-"}</span>
                                      {/* <div className="last-row">
                                                                                        <p>{non_Player.position}</p> 
                                                                                        <button data-toggle="modal" data-target="#assignmentdelect" onClick={() => deletePlayerData(non_Player.member_id._id)} ><img src={Delect} /></button>
                                                                                        <button onClick={() => updateModalValue1(i, non_Player.member_id._id)}><img src={pencil} /></button>
                                                                                    </div> */}
                                    </td>
                                    <td>
                                      <div className="last-row">
                                        {/* <button data-toggle="modal" data-target="#assignmentdelect" onClick={() => deletePlayerData(non_Player._id)} ><img src={Delect} /></button> */}
                                        <button
                                          onClick={() =>
                                            ditailsmodelvalue(i, non_Player._id)
                                          }
                                        >
                                          <EyeFill style={{ color: "white" }} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamRoster;
