import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import TimePicker from "react-time-picker";
import Modal from "react-bootstrap/Modal";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../../Utils/css/style.css";
// import "../../../Utils/css/responsive.css";
// import "../../../Utils/css/bootstrap.min.css";
// import "../../../Utils/css/bootstrap-datepicker.css";
// import UserProfile from "../../../images/user-profile.png";
// import flag from "../../../images/flag.png";
// import add from "../../../images/add.png";
// import Delect from "../../../images/delect.png";
// import pencil from "../../../images/pencil.png";
// import SideMenuComponents from "../../../Components/SideMenu";
// import Footer from "../../../Components/Footer";
import { Network } from "../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../Redux/Actions/auth"
import BigUserProfile from "../../images/big-user-profile.png";
// import { EyeFill, ZoomIn } from "react-bootstrap-icons";
// import axios from "axios";
import { usePlayerHeaderContext } from '../../Screens/Players/PlayerContext/PlayerHeaderContext';

const PlayerHeader=(props)=>{

  const { updateplayerSelectedId, updateplayerRosterId, updateplayerTeamId, updateplayerTeamName, playerTeamName, playerTeamId,playerRosterId } = usePlayerHeaderContext ();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [team, setTeam] = useState([]);
  const [teamDropdown, setTeamDropDown] = useState("");
  const [profilePic, setProfilePic] = useState([]);
  console.log(playerTeamName,"playerteamName==>>")
  console.log(playerTeamId,"playerTeamId==>>")
  console.log(playerRosterId,"playerRosterId==>>")

  useEffect(() => {
    const storedTeamId = playerTeamId;
    if (storedTeamId != null) {
      setTeamDropDown(storedTeamId);
    }
    // let user = userdata && userdata._id ? true : false;
    // console.log("userMe===>", user);
    setUser(user);
    // console.log("USerData", userdata);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    const selectedTeamName = localStorage.getItem("playerTeamName");

    // setTeamDropDown

    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);

    teamSelect();
    // setTeamDropDown()
    updateProfile();
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser(null));
    localStorage.removeItem("user");
    localStorage.removeItem("playerselectedTeamName");
    localStorage.removeItem("playerselectedTeamId");
    localStorage.removeItem("selectedplayerRosterId");
    

    setUserData(null);
    history.push("/");
  };



  const updateProfile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        "token": user.authtoken,
      };
      // console.log("user", user);

      Network("api/getUserDetailsById?user_id=" + user?._id, "GET", header).then(
        async (res) => {
          // console.log("new Profile Pic----", res);
          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          setProfilePic(res.response_data.userDetailsObj);
        }
      );
    }
  };

  const teamSelect = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      console.log("user", user);

      Network(
        // 'api/getAllAcceptedTeamListByPlayerId?playerId=' + "644a463b556e970345ff5be5",
        "api/getAllAcceptedTeamListByPlayerId?playerId=" + user._id,
        // "api/getAllAcceptedTeamListByPlayerId?playerId=" +"644a463b556e970345ff5be5",
        "GET",
        header
      ).then(async (res) => {
        console.log("res----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }

        setTeam(res.response_data);
        // teamSchedule(res?.response_data[0].accept_invite_team_id)
        // if(res.response_data.length!=0){
        // teamRoster(res?.response_data[0]._id);
        // }
      });
    }
  };
  const change = (event) => {
    console.log("event", event.target.value);
    setTeamDropDown(event.target.value);
    console.log(team,"team==>>")

    const selectedTeamObject = team.find(
      (item) => item.accept_invite_team_id === event.target.value
    );
    console.log(selectedTeamObject?.accept_invite_id,"=====>>140")
    updateplayerTeamName(selectedTeamObject?.accept_invite_team_name)
    
    updateplayerTeamId(selectedTeamObject?.accept_invite_team_id)
    updateplayerRosterId(selectedTeamObject?.accept_invite_id)
    // localStorage.setItem("playerrTeamName", selectedTeamObject?.accept_invite_team_name);
    // localStorage.setItem("playerTeamId", selectedTeamObject?.accept_invite_team_id);
    // localStorage.setItem(
    //   "playerRosterId",
    //   selectedTeamObject?.accept_invite_id
    // );
    // setTeamDropDown(event.target.value);


    return teamDropdown
  };
  const TeamName = localStorage.getItem("playerTeamName") || "Select a team";



    return(
        
          <div className="dashboard-head">
              <div className="teams-select">
                <select onChange={change} value={teamDropdown}>
                  {/* <option>Select Team</option> */}
                  {playerTeamName  == null || playerTeamName == undefined || playerTeamName === "" ?  <option>Select Team</option> : null }
                  {team?.map((team) => {
                    return (
                      <>
                      {/* // <option value={team.team_id._id}>
                      //   {team.team_id.team_name}
                      // </option> */}
                     {/* {playerTeamName  == null || playerTeamName == undefined || playerTeamName === "" ?  <option>Select Team</option> : null } */}
                      <option 
                      key={team?.accept_invite_team_id}
                      value={team?.accept_invite_team_id}
                      id={team?.accept_invite_team_id}
                      name={team?.accept_invite_team_name}>
                        {team?.accept_invite_team_name}
                      </option>
                      </>
                    );
                  })}
                </select>
              </div>

              <div className="profile-head">
                {console.log(profilePic.lname, "3740000000000000000>>>>")}
                {profilePic?.fname ? (
                  <div className="profile-head-name">

                    {profilePic?.fname + " " + profilePic?.lname}
                  </div>
                ) : (
                  <div className="profile-head-name">{profilePic?.fname} {profilePic?.lname}</div>
                )}
                <div className="profile-head-img">
                  {console.log(profilePic, "profilepic===>>>")}
                  {profilePic?.profile_image == null ? (

                    <img src={BigUserProfile} alt="" />
                  ) : (
                    <img src={profilePic?.profile_image} alt="" />

                  )}
                </div>
              </div>
              <div className="login-account">
                <ul>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#myModallogin"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
        

    )

}
export default PlayerHeader

 

