import React, { useState, useEffect } from "react";
import { Network } from "../../../Services/Api";

function Assignment({ getAssignments }) {
  const [assignments, setAssignment] = useState([]);
  const [inputFields, setInputFields] = useState([
    { assignment_id: "", assignment_volunteer_roster_id: "" },
  ]);

  useEffect(() => {
    AssignmentData();
  }, []);

  // Function to track selected assignment IDs
  const getSelectedAssignmentIds = () => {
    return inputFields.map((input) => input.assignment_id).filter((id) => id);
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);

    // Pass the updated fields to the parent component
    getAssignments(data);
  };

  const addFields = (e) => {
    e.preventDefault();
    let newField = { assignment_id: "", assignment_volunteer_roster_id: "" };
    setInputFields([...inputFields, newField]);
  };

  const AssignmentData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      Network("api/getAllAssignmentData", "GET", header).then(async (res) => {
        setAssignment(res.response_data);
      });
    }
  };

  return (
    <div className="assignment_wrp">
      <form>
        {inputFields.map((input, index) => {
          // Get IDs of selected assignments
          const selectedIds = getSelectedAssignmentIds();

          return (
            <div key={index} className="d-flex my-2">
              <select
                name="assignment_id"
                className="form-control"
                onChange={(event) => handleFormChange(index, event)}
                value={input.assignment_id} // Set controlled value
              >
                <option value="" disabled>
                  Select Assignment
                </option>
                {assignments
                  .filter(
                    (assignment) =>
                      assignment._id === input.assignment_id ||
                      !selectedIds.includes(assignment._id)
                  ) // Filter out assignments already selected
                  .map((assignment) => {
                    return (
                      <option
                        key={assignment._id}
                        value={assignment._id}
                      >
                        {assignment.assignment_name}
                      </option>
                    );
                  })}
              </select>

              {/* Show 'Add More' button only for the last row */}
              {index === inputFields.length - 1 && (
                <button
                  className="btn ml-2"
                  style={{ color: "#fff", background: "#EC3525" }}
                  onClick={addFields}
                >
                  Add More..
                </button>
              )}
            </div>
          );
        })}
      </form>
    </div>
  );
}

export default Assignment;
