import React, { createContext, useContext, useState, useEffect } from "react";

const HeaderContext = createContext();

export const useHeaderContext = () => useContext(HeaderContext);

export const HeaderProvider = ({ children }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [managerRosterId, setManagerRosterId] = useState(
    localStorage.getItem("selectedManagerRosterId") || null
  );
  const [teamId, setTeamId] = useState(
    localStorage.getItem("selectedTeamId") || null
  );
  const [gameId, setGameId] = useState({ gameId: "", flag: "" });
  const [teamName, setTeamName] = useState(
    localStorage.getItem("selectedTeamName") || null
  );

  const updateSelectedId = (id) => {
    setSelectedId(id);
  };

  const updateManagerRosterId = (id) => {
    setManagerRosterId(id);
    localStorage.setItem("selectedManagerRosterId", id);
  };

  const updateTeamId = (id) => {
    setTeamId(id);
    localStorage.setItem("selectedTeamId", id);
  };

  const updateGameId = (id, flag ,teamId) => {
    setGameId({ gameId: id, isFlag: flag ,teamId: teamId });
    localStorage.setItem(
      "selectedUpdatedGameId",
      JSON.stringify({ gameId: id, isFlag: flag,teamId: teamId })
    );
  };

  const updateTeamName = (id) => {
    setTeamName(id);
    localStorage.setItem("selectedTeamName", id);
  };

  useEffect(() => {
    const savedSelectedTeamId = localStorage.getItem("selectedTeamId");
    if (savedSelectedTeamId) {
      setTeamId(savedSelectedTeamId);
    }

    const savedRoaterId = localStorage.getItem("selectedManagerRosterId");
    if (savedRoaterId) {
      setManagerRosterId(savedRoaterId);
    }

    const savedTeamName = localStorage.getItem("selectedTeamName");
    if (savedTeamName) {
      setTeamName(savedTeamName);
    }

    const savedGameId = localStorage.getItem("selectedUpdatedGameId");
    const savedParsedGameId = JSON.parse(savedGameId);
    if (savedParsedGameId) {
      setGameId(savedParsedGameId);
    }
  }, []);
  return (
    <HeaderContext.Provider
      value={{
        selectedId,
        managerRosterId,
        teamId,
        teamName,
        gameId,
        updateSelectedId,
        updateManagerRosterId,
        updateTeamId,
        updateTeamName,
        updateGameId,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
