import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouterComponent from './App/Router';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { HeaderProvider } from '../src/App/Screens/Manager/ManagerContext/HeaderContext';
import { PlayerHeaderProvider } from './App/Screens/Players/PlayerContext/PlayerHeaderContext';

function App() {
  return (
    <BrowserRouter>
    <HeaderProvider>
    <PlayerHeaderProvider>
      <RouterComponent />
     </PlayerHeaderProvider>
    </HeaderProvider>
    </BrowserRouter>
  );
}

export default App;
