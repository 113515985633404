import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoogleMapReact from "google-map-react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import UserProfile from "../../../images/user-profile.png";
import Mappin from "../../../images/mappin.jpg";
import flag from "../../../images/flag.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import { useHeaderContext } from "../ManagerContext/HeaderContext";
import ManagerHeader from "../../../Components/Header/ManagerHeader";
import { Form, Modal } from "react-bootstrap";
import Map from "../../../Components/Map/Map";

const ScheduleAvailabilityDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateTeamId, gameId } = useHeaderContext();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [dropdown, setDropdown] = useState([]);
  const [teamDropdown, setTeamDropDown] = useState("");
  const [schedule, setSchedule] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [gameDetailsData, setGameDetailsData] = useState([]);
  const [lineupData, setLineupData] = useState([]);
  const [teamPositionData, setTeamPositionData] = useState([]);
  const [key, setKey] = useState("availability");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [modeValue, setModeValue] = useState(false);
  const [selectedPositionId, setSelectePositiondId] = useState("");
  // const [selectedPositionTitle, setSelectedPositionTitle] = useState("");
  const [positionTitleUpdate, setPositionTitleUpdate] = useState("");
  const [customRosterData, setCustomRosterData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [positionTitleForEdit, setPositionTitleForEdit] = useState("");
  const [positionIdForEdit, setPositionIdForEdit] = useState("");
  const [addOrUpdateBtn, setAddOrUpdateBtn] = useState(false);
  const [isUpdateBtnDisable, setIsUpdateBtnDisable] = useState(false);
  const [volunteerData, setVolunteerData] = useState([]);
  const [addVolunteerModal, setAddVolunteerModal] = useState(false);
  const [rosterListData, setRosterListData] = useState([]);
  const [rosterListCopyData, setRosterListCopyData] = useState([]);
  const [selectedVolunteerIndex, setSelectedVolunteerIndex] = useState(null);
  const [addAssignmentModal, setAddAssignmentModal] = useState(false);
  const [allAssignmentData, setAllAssignmentData] = useState([]);
  const [addNewAssignmentData, setAddNewAssignmentData] = useState([]);
  const [assignmentUpdateBtn, setAssignmentUpdateBtn] = useState(false);

  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
  });

  const [isToggled, setIsToggled] = useState(null);
  useEffect(() => {
    setUser(user);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    // dropdownMenu();
    const team_id = localStorage.getItem("selectedTeamId");
    teamSchedule(team_id);
    // GameEventList();
    // setColor();
    rosterAvailDetailsData();
    gameDetailsbyGameEventId(gameId.gameId);
    getPositionByTeamId(gameId.teamId);
    getAllAssignmentData();
    getRosterListByTeamId();
  }, []);
  const handleLogout = () => {
    dispatch(logoutUser(null));
    localStorage.removeItem("user");
    setUserData(null);
    history.push("/");
  };

  const teamSchedule = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      Network("api/getAllEventAndGamesData?team_id=" + id, "GET", header).then(
        async (res) => {
          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          setSchedule(res.response_data);
        }
      );
    }
  };

  const rosterAvailDetailsData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (gameId.isFlag == "Game") {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/getRosterAvailableDetailsByGameId?game_id=" +
        gameId.gameId;
    } else {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/getRosterAvailableDetailsByEventId?events_id=" +
        gameId.gameId;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.response_code == 200) {
          setDetailsData(res.response_data);
        }
      });
  };

  const gameDetailsbyGameEventId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (gameId.isFlag == "Game") {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/getGameDetailsByGameId?game_id=" +
        gameId.gameId;
    } else {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/getDetailsByEventId?events_id=" +
        gameId.gameId;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log(res,"response data==>>>")
        if (res.response_code == 200) {
          setGameDetailsData(res?.response_data);
          setLineupData(res?.response_data?.lineup);
          setCustomRosterData(
            res.response_data?.lineup?.map((item) => {
              return {
                fullName:
                  item?.assignment_lineup_roster_id?.firstName +
                  " " +
                  item?.assignment_lineup_roster_id?.lastName,
                email: item?.email,
                rosterId: item?.assignment_lineup_roster_id?._id,
                positionId: item?.game_team_position_id?._id || null,
                positionTitle:
                  item?.game_team_position_id?.position_title || null,
              };
            })
          );
          const coordinate =
            res.response_data?.location?.locationLatLong.split(" ");
          const parsedLatitude = parseFloat(coordinate[0]);
          const parsedLongitude = parseFloat(coordinate[1]);
          setLatitude(parsedLatitude);
          setLongitude(parsedLongitude);
          const { away_team_score, home_team_score } =
            res?.response_data?.result;
          setFormData({
            field1: home_team_score,
            field2: away_team_score,
          });
          setIsToggled(res?.response_data?.result?.overtime);
          // console.log(res?.response_data?.Volunteer, "volunteerData");
          setVolunteerData(
            res?.response_data?.Volunteer.map((item) => {
              return {
                assignmentType: item?.assignment_id?.volunteer_assignment_type,
                assignmentId: item?.assignment_id?._id,
                assignmentImage:
                  item?.assignment_id?.volunteer_assignment_image,
                assignmentRosterId:
                  null || item?.assignment_volunteer_roster_id?._id,
                assignmentRosterName:
                  null ||
                  item?.assignment_volunteer_roster_id?.firstName +
                    " " +
                    item?.assignment_volunteer_roster_id?.lastName,
              };
            })
          );
        }
      });
  };

  const getPositionByTeamId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      Network(
        "api/getPositionsByTeamId?team_id=" + gameId.teamId,
        "GET",
        header
      ).then(async (res) => {
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setTeamPositionData(res.response_data);
      });
    }
  };

  const addPositionModal = (id, title, index) => {
    setModeValue(true);
    setSelectedIndex(index);
  };

  const handleCheckboxChange = (id, title) => {
    setCustomRosterData(
      customRosterData.map((item, index) => {
        if (selectedIndex === index) {
          return {
            email: item.email,
            fullName: item.fullName,
            positionId: id,
            positionTitle: title,
            rosterId: item.rosterId,
          };
        } else {
          return item;
        }
      })
    );
    setModeValue(false);
    setIsUpdateBtnDisable(true);
  };

  const addPositionTitleByTeam = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    var url =
      "https://www.sports-cloud.io/sportsapi/api/addPositionTitleByTeam";
    var data = {
      team_id: gameId.teamId,
      position_title_name: positionTitleForEdit,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.response_code == 200) {
          setModeValue(false);
          getPositionByTeamId(gameId.teamId);
          setPositionTitleForEdit("");
          toast.success(res.response_message);
        }
        if (res.response_code == 400) {
          setModeValue(false);
          toast.error(res.response_message);
        }
      });
  };

  const handleEditPosition = (id, title) => {
    setPositionTitleForEdit(title);
    setPositionIdForEdit(id);
    setAddOrUpdateBtn(true);
  };

  const updatePositionTitleById = (id, title) => {
    const user = JSON.parse(localStorage.getItem("user"));
    var url = "https://www.sports-cloud.io/sportsapi/api/updatePositionById";
    var data = {
      position_id: id,
      position_title: title,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.response_code == 200) {
          setModeValue(false);
          getPositionByTeamId(gameId.teamId);
          setPositionTitleForEdit("");
          toast.success(res.response_message);
        }
        if (res.response_code == 400) {
          setModeValue(false);
          toast.error(res.response_message);
        }
      });
  };

  const deleteRosterFromLineup = (rosterId) => {
    const filteredRosterData = customRosterData.filter(
      (item) => item.rosterId !== rosterId
    );
    setCustomRosterData(filteredRosterData);
    setIsUpdateBtnDisable(true);
  };

  const handleCloseModal = () => {
    setPositionTitleForEdit("");
    setPositionIdForEdit("");
    setModeValue(false);
    setAddOrUpdateBtn(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDeletePosition = (id, title) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const a = window.confirm("Are you sure you wish to delete this Data?");
    if (a == true) {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/deletePositionDetailsById";

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: user.authtoken,
        },
        body: JSON.stringify({
          position_id: id,
        }),
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.response_code == 200) {
            setModeValue(false);
            toast.success(res.response_message);
            getPositionByTeamId(gameId.teamId);
          }
          if (res.response_code == 400) {
            toast.error(res.response_message);
            setModeValue(false);
          }
        });
    }
  };

  const addLineupsByGameId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var url = "https://www.sports-cloud.io/sportsapi/api/addLineupsByGameid";
    var data = {
      game_id: gameId.gameId,
      lineup: customRosterData.map((item) => {
        return {
          game_team_position_id: item.positionId,
          assignment_lineup_roster_id: item.rosterId,
        };
      }),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.response_code == 200) {
          toast.success("Lineup Updated Successfully");
          gameDetailsbyGameEventId(gameId.gameId);
          history.push("/ScheduleAvailabilityDetails");
          setIsUpdateBtnDisable(false);
        }
        if (res.response_code == 400) {
          toast.error(res.response_message);
        }
      });
  };

  const getAllAssignmentData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      Network("api/getAllAssignmentData", "GET", header).then(async (res) => {
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        // setTeamPositionData(res.response_data);
        // console.log(res.response_data, "getAllAssignmentData");
        setAllAssignmentData(res.response_data);
      });
    }
  };

  const getRosterListByTeamId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };

      Network(
        "api/getRoasterUserDetailsByTeamId?teamId=" + gameId.teamId,
        "GET",
        header
      ).then(async (res) => {
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        console.log(res.response_data, "rosterData");

        setRosterListData(res.response_data);
        setRosterListCopyData(res.response_data);
      });
    }
  };

  const handleAddVolunteer = (index) => {
    setAddVolunteerModal(true);
    setSelectedVolunteerIndex(index);
    const filteredVolunteerArray = rosterListCopyData.filter((rostItem) => {
      const matchingItem = volunteerData.find(
        (volItem) => volItem.assignmentRosterId === rostItem.rosterId
      );

      return !matchingItem;
    });
    setRosterListData(filteredVolunteerArray);
    // console.log(filteredVolunteerArray, "filteredVolunteerArray");
  };

  const handleVolunteerCheckboxChange = (id, name) => {
    setVolunteerData(
      volunteerData.map((item, index) => {
        if (selectedVolunteerIndex === index) {
          return {
            ...item,
            assignmentRosterId: id,
            assignmentRosterName: name,
          };
        } else {
          return item;
        }
      })
    );
    setAddVolunteerModal(false);
    setAssignmentUpdateBtn(true);
  };

  const handleAssigmentCheckboxChange = (e, id, name, image) => {
    if (e.target.checked === true) {
      setAddNewAssignmentData([
        ...addNewAssignmentData,
        {
          assignmentType: name,
          assignmentId: id,
          assignmentImage: image,
          assignmentRosterId: null,
          assignmentRosterName: null,
        },
      ]);
    } else {
      const newArray = addNewAssignmentData.filter((item) => {
        return item.assignmentId !== id;
      });
      setAddNewAssignmentData(newArray);
    }
  };

  const handleAddAssignment = () => {
    setAddAssignmentModal(true);
    const filteredAssignmentArray = allAssignmentData.filter((assItem) => {
      const matchingItem = volunteerData.find(
        (volItem) => volItem.assignmentId === assItem._id
      );

      return !matchingItem;
    });
    // console.log(filteredAssignmentArray, "filteredArray");
    setAllAssignmentData(filteredAssignmentArray);
  };

  const deleteAssignment = (id) => {
    // console.log(id, "allAssignmentData");
    const deletedAssignment = volunteerData.filter(
      (item) => item.assignmentId !== id
    );
    // console.log(deletedAssignment, "allAssignmentData");
    setVolunteerData(deletedAssignment);
    setAssignmentUpdateBtn(true);
  };

  const handleAddNewAssignment = () => {
    setVolunteerData([...volunteerData, ...addNewAssignmentData]);
    setAddAssignmentModal(false);
    setAssignmentUpdateBtn(true);
  };

  const addAssigmentDataByGameId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (gameId.isFlag === "Game") {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/editGameDetailsById";
      var data = {
        game_id: gameId.gameId,
        Volunteer: volunteerData.map((item) => {
          return {
            assignment_id: item.assignmentId,
            assignment_volunteer_roster_id: item.assignmentRosterId,
          };
        }),
      };
    } else {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/editEventDetailsById";
      var data = {
        events_id: gameId.gameId,
        Volunteer: volunteerData.map((item) => {
          return {
            assignment_id: item.assignmentId,
            assignment_volunteer_roster_id: item.assignmentRosterId,
          };
        }),
      };
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.response_code == 200) {
          setAssignmentUpdateBtn(false);
          toast.success(res.response_message);
          gameDetailsbyGameEventId(gameId.gameId);
          assignmentUpdateBtn(false);
        }
        if (res.response_code == 400) {
          toast.error(res.response_message);
        }
      });
  };

  const deleteAssignmentDetailsById = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(id, "allAssignmentData");
    const a = window.confirm("Are you sure you wish to delete this Data?");
    // if (a == true) {
    //   var url =
    //     "https://www.sports-cloud.io/sportsapi/api/deleteAssignmentDetailsById";

    //   const requestOptions = {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       token: user.authtoken,
    //     },
    //     body: JSON.stringify({
    //       assignmentId: id,
    //     }),
    //   };
    //   fetch(url, requestOptions)
    //     .then((response) => response.json())
    //     .then((res) => {
    //       if (res.response_code == 200) {
    //         toast.success(res.response_message);
    //         gameDetailsbyGameEventId(gameId.gameId);
    //       }
    //       if (res.response_code == 400) {
    //         toast.error(res.response_message);
    //       }
    //     });
    // }
  };

  // console.log(allAssignmentData, "allAssignmentData");

  const change = (event) => {
    setTeamDropDown(event);
    updateTeamId(event);
    teamSchedule(event);
  };

  const AnyReactComponent = ({ text }) => (
    <div>
      <img
        src={Mappin}
        alt='Pin'
        style={{
          width: "30px",
          height: "auto",
        }}
      />
    </div>
  );

  return (
    <div>
      <div className='dashboard-container'>
        <div className='dashboard-main'>
          <SideMenuComponents manger='manger' />
          <div className='dashboard-main-content'>
            <ManagerHeader change={change} />

            <div className='player-info-head availability mb-2'>
              <h2 className='page-title'>Game/Event Details</h2>
            </div>
            <div className='main-wrapper-prefarance-box'>
              <Tabs
                id='controlled-tab-example'
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className='mb-3c'
              >
                <Tab eventKey='details' title='Details'>
                  <>
                    <div
                      className='plr_dtls_wrp details-key-lists'
                      style={{ padding: "2% 5%" }}
                    >
                      <div className='plr_dtls_itm'>
                        <label>
                          {gameId.isFlag == "Game"
                            ? "Game Name :"
                            : "Event Name"}
                        </label>
                        <span>
                          {gameId.isFlag == "Game"
                            ? gameDetailsData?.game_name
                            : gameDetailsData?.event_name}
                        </span>
                      </div>
                      <div className='plr_dtls_itm'>
                        <label> Team Name :</label>
                        <span>{gameDetailsData?.team_id?.team_name}</span>
                      </div>
                      {gameId.isFlag == "Game" ? (
                        <>
                          <div className='plr_dtls_itm'>
                            <label>Opponent :</label>
                            <span>
                              {gameDetailsData?.opponent?.contactName}
                            </span>
                          </div>

                          <div className='plr_dtls_itm'>
                            <label>Opponent Contact Name :</label>
                            <span>
                              {gameDetailsData?.opponent?.opponentsName}
                            </span>
                          </div>

                          <div className='plr_dtls_itm'>
                            <label>Opponent Email :</label>
                            <span>
                              {gameDetailsData?.opponent?.contactEmail}
                            </span>
                          </div>
                          <div className='plr_dtls_itm'>
                            <label>Opponent Contact Number :</label>
                            <span>
                              {gameDetailsData?.opponent?.contactPhoneNumber}
                            </span>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className='plr_dtls_itm'>
                        <label> Location :</label>
                        <span>
                          {/* <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyCOKA-Ho3wav5X4fLl-bidLEuTx4SSf4qU",
                              language: "en",
                            }}
                            style={{ height: `300px` }}
                            defaultZoom={10}
                            resetBoundsOnResize={true}
                            defaultCenter={{ lat: latitude, lng: longitude }}
                          >
                            <AnyReactComponent
                              lat={latitude}
                              lng={longitude}
                              text='Your Marker Text'
                            />
                          </GoogleMapReact> */}
                           <Map latitude={latitude  } longitude={longitude } />
                        </span>
                      </div>
                      <div className='plr_dtls_itm'>
                        <label> Location Name :</label>
                        <span>{gameDetailsData?.location?.locationName}</span>
                      </div>
                      <div className='plr_dtls_itm'>
                        <label> Location Address :</label>
                        <span>{gameDetailsData?.location?.address}</span>
                      </div>
                      <div className='plr_dtls_itm'>
                        <label> Website :</label>
                        <span>{gameDetailsData?.location?.website}</span>
                      </div>
                      <div className='plr_dtls_itm'>
                        <label> Notes :</label>
                        <span>{gameDetailsData?.location?.notes}</span>
                      </div>
                      <div className='plr_dtls_itm'>
                        <label> Start time :</label>
                        <span>{gameDetailsData?.start_time}</span>
                      </div>
                      <div className='plr_dtls_itm'>
                        <label>End time :</label>
                        <span>{gameDetailsData?.end_time}</span>
                      </div>
                      <div className='plr_dtls_itm'>
                        <label> Arrival time :</label>
                        <span>{gameDetailsData?.arrival_time}</span>
                      </div>
                      <div className='plr_dtls_itm'>
                        <label>Event duration :</label>
                        <span> {gameDetailsData?.duration}</span>
                      </div>
                      {gameId.isFlag == "Game" ? (
                        <>
                          <div className='plr_dtls_itm'>
                            <label>Home Team Score :</label>
                            <span>
                              <input
                                style={{
                                  backgroundColor: "#484848",
                                  color: "white",
                                  textAlign: "center",
                                }}
                                type='text'
                                name='field1'
                                value={formData.field1}
                                onChange={handleInputChange}
                              />
                            </span>
                          </div>
                          <div className='plr_dtls_itm'>
                            <label>Away Team Score :</label>
                            <span>
                              <input
                                style={{
                                  backgroundColor: "#484848",
                                  color: "white",
                                }}
                                type='text'
                                name='field2'
                                value={formData.field2}
                                onChange={handleInputChange}
                              />
                            </span>
                          </div>
                          <div className='plr_dtls_itm'>
                            <label>Overtime :</label>
                            <span>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type='switch'
                                  id='custom-switch'
                                  size='large'
                                  checked={isToggled}
                                  onChange={() => setIsToggled(!isToggled)}
                                />
                              </Form>
                            </span>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                </Tab>
                {gameId.isFlag == "Game" ? (
                  <Tab eventKey='lineup' title='Lineup'>
                    <div className='team-payment team-assesment team-lineup-lists'>
                      {customRosterData?.length > 0 ? (
                        <>
                          <table>
                            <tr className='tbl-header sticky'>
                              <th>Name</th>
                              <th>Position</th>
                              {/* <button
                                className='add-links'
                                onClick={() => {
                                  history.push("/LineupPage");
                                }}
                              >
                                Add Lineup
                              </button> */}
                            </tr>

                            {customRosterData?.map((item, index) => {
                              const matchingResponse = teamPositionData.find(
                                (positionItem) =>
                                  positionItem?.position_id === item?.positionId
                              );
                              return (
                                <tr key={index}>
                                  <td>
                                    <span>
                                      {/* {item?.assignment_lineup_roster_id
                                        ?.contactInformationFirstName +
                                        " " +
                                        item?.assignment_lineup_roster_id
                                          ?.contactInformationLastName} */}
                                      {item?.fullName}
                                    </span>
                                  </td>

                                  <td>
                                    <div className='last-row'>
                                      {matchingResponse ? (
                                        <button
                                          style={{ color: "rgb(255 118 106)" }}
                                          onClick={() => {
                                            addPositionModal(
                                              item?.positionId,
                                              item?.positionTitle,
                                              index
                                            );
                                          }}
                                        >
                                          {item?.positionTitle}
                                        </button>
                                      ) : (
                                        <span>
                                          <button
                                            onClick={() => {
                                              addPositionModal(
                                                item?.rosterId,
                                                "",
                                                index
                                              );
                                            }}
                                          >
                                            <img src={add} />
                                          </button>
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className='last-row'>
                                      <span>
                                        <button
                                          onClick={() => {
                                            deleteRosterFromLineup(
                                              item.rosterId
                                            );
                                          }}
                                        >
                                          <img src={Delect} />
                                        </button>{" "}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                          <div
                            className='text-center'
                            style={{ padding: "10px" }}
                          >
                            <button
                              className={
                                !isUpdateBtnDisable
                                  ? "add-links-disable"
                                  : "add-links"
                              }
                              style={{ marginRight: "10px" }}
                              disabled={!isUpdateBtnDisable}
                              onClick={addLineupsByGameId}
                            >
                              Update Lineup
                            </button>
                            <button
                              className='add-links'
                              style={{
                                backgroundColor: "#1d1b1b",
                              }}
                              onClick={() => {
                                history.push("/LineupPage");
                              }}
                            >
                              Add Lineup
                            </button>
                          </div>
                        </>
                      ) : (
                        <div className='text-center'>
                          <h4 style={{ color: "white", textAlign: "center" }}>
                            Lineup not created yet
                          </h4>
                          <button
                            className='add-links'
                            onClick={() => {
                              history.push("/LineupPage");
                            }}
                          >
                            Add Lineup
                          </button>
                        </div>
                      )}
                    </div>
                  </Tab>
                ) : (
                  ""
                )}

                <Tab eventKey='availability' title='Availability'>
                  <div className='team-payment team-assesment team-lineup-lists'>
                    <table>
                      <tr className='tbl-header sticky'>
                        <th></th>
                        <th>Name</th>
                        {/* <th>Location</th> */}
                        <th>Availability</th>
                      </tr>

                      {detailsData?.map((details) => {
                        return (
                          <tr>
                            <td>
                              <img
                                src={
                                  details.assignment_volunteer_roster_profile_pic
                                }
                                alt='cricket-icon'
                                className='img-fluid small-avatar-icon'
                                style={{ "max-width": 50 }}
                              />
                            </td>

                            <td>
                              <span>
                                {details.assignment_volunteer_roster_name}
                              </span>
                            </td>

                            <td>
                              <div className='last-row'>
                                <button
                                  style={{ color: "rgb(255 118 106)" }}
                                  data-toggle='modal'
                                  data-target='#setAvailability'
                                >
                                  {details.at_an_event}
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </Tab>
                <Tab eventKey='assignment' title='Assignment'>
                  <div className='team-payment team-assesment team-lineup-lists'>
                    <table>
                      <tr className='tbl-header sticky'>
                        <th></th>
                        <th>Assignment Type</th>
                        <th>Volunteer</th>
                        <th></th>
                      </tr>

                      {volunteerData?.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <img
                                src={item?.assignmentImage}
                                alt='cricket-icon'
                                className='img-fluid small-avatar-icon'
                                style={{ "max-width": 50 }}
                              />
                            </td>

                            <td>
                              <span>{item?.assignmentType}</span>
                            </td>

                            <td>
                              <div className='last-row'>
                                {item?.assignmentRosterId !== null &&
                                item?.assignmentRosterId !== undefined ? (
                                  <button
                                    style={{ color: "rgb(255 118 106)" }}
                                    data-toggle='modal'
                                    data-target='#setAvailability'
                                    onClick={() => {
                                      handleAddVolunteer(index);
                                    }}
                                  >
                                    {/* {item?.assignment_volunteer_roster_id
                                      ?.firstName +
                                      " " +
                                      item?.assignment_volunteer_roster_id
                                        ?.lastName} */}
                                    {item?.assignmentRosterName}
                                  </button>
                                ) : (
                                  <button
                                    style={{ color: "rgb(255 118 106)" }}
                                    data-toggle='modal'
                                    data-target='#setAvailability'
                                    onClick={() => {
                                      handleAddVolunteer(index);
                                    }}
                                  >
                                    Add Volunteer
                                  </button>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className='last-row'>
                                <span>
                                  <button
                                    onClick={() => {
                                      deleteAssignment(item.assignmentId);
                                    }}
                                  >
                                    <img src={Delect} />
                                  </button>{" "}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                    <div className='text-center' style={{ padding: "10px" }}>
                      <button
                        className={
                          !assignmentUpdateBtn
                            ? "add-links-disable"
                            : "add-links"
                        }
                        // className='add-links'
                        style={{ marginRight: "10px", width: "180px" }}
                        disabled={!assignmentUpdateBtn}
                        onClick={addAssigmentDataByGameId}
                      >
                        Update Assignment
                      </button>
                      <button
                        className='add-links'
                        style={{
                          backgroundColor: "#1d1b1b",
                        }}
                        onClick={handleAddAssignment}
                      >
                        Add Assignment
                      </button>
                    </div>
                  </div>
                </Tab>
              </Tabs>
              {modeValue ? (
                <Modal show={modeValue} size='md'>
                  <Modal.Body>
                    <div className='prefarance-form playerinfo-form'>
                      <h1 className='m-title'>Add/Edit Position</h1>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='prefarance-form-list'>
                            <div className='last-row'>
                              <ul className='checkbox-lists-with-action'>
                                {teamPositionData.map((item) => (
                                  <>
                                    <li
                                      style={{
                                        color: "#9e9e9e",
                                        fontSize: "16px",
                                        listStyle: "none",
                                        display: "flex",
                                        gap: "5px",
                                      }}
                                      key={item.position_id}
                                    >
                                      <div className='custom-checkbox-w'>
                                        <input
                                          id={"check_id_" + item.position_id}
                                          type='checkbox'
                                          className='form-check-input'
                                          onChange={(e) => {
                                            handleCheckboxChange(
                                              item.position_id,
                                              item.position_title
                                            );
                                          }}
                                        />

                                        <label
                                          className='form-check-label'
                                          htmlFor={
                                            "check_id_" + item.position_id
                                          }
                                        >
                                          {item.position_title}
                                        </label>
                                      </div>

                                      <div className='action'>
                                        <button
                                          className='edit-btn btn'
                                          onClick={() => {
                                            handleEditPosition(
                                              item.position_id,
                                              item.position_title
                                            );
                                          }}
                                        >
                                          <img src={pencil} />
                                        </button>
                                        <button
                                          className='delete-btn btn'
                                          onClick={() => {
                                            handleDeletePosition(
                                              item.position_id,
                                              item.position_title
                                            );
                                          }}
                                        >
                                          <img src={Delect} />
                                        </button>
                                      </div>
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </div>

                            <input
                              type='text'
                              className='input-select'
                              value={positionTitleForEdit}
                              onChange={(e) =>
                                setPositionTitleForEdit(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='text-center mt-3'>
                      <button className='add-links' onClick={handleCloseModal}>
                        Cancel
                      </button>
                      <button
                        className='add-links ml-2'
                        style={{
                          backgroundColor: "#1d1b1b",
                        }}
                        onClick={() => {
                          addOrUpdateBtn
                            ? updatePositionTitleById(
                                positionIdForEdit,
                                positionTitleForEdit
                              )
                            : addPositionTitleByTeam();
                        }}
                      >
                        {addOrUpdateBtn ? "Update" : "Add"}
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              ) : (
                ""
              )}

              {addVolunteerModal ? (
                <Modal show={addVolunteerModal} size='md'>
                  <Modal.Body>
                    <div className='team-payment team-assesment'>
                      <h1 className='m-title'>Add Volunteer</h1>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='prefarance-form-list'>
                            <table>
                              <tr>
                                <th></th>
                                <th>Name</th>
                              </tr>
                              {rosterListData?.map((item, index) => {
                                return (
                                  <tr>
                                    <td
                                      onClick={(e) => {
                                        handleVolunteerCheckboxChange(
                                          item.rosterId,
                                          item.fullName
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src={item?.userImage}
                                        alt='cricket-icon'
                                        className='img-fluid'
                                        style={{ "max-width": 50 }}
                                      />
                                    </td>

                                    <td>
                                      <span>{item?.fullName}</span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='text-center mt-3'>
                      <button
                        className='add-links'
                        onClick={() => {
                          setAddVolunteerModal(false);
                        }}
                      >
                        Cancel
                      </button>
                      {/* <button
                        className='add-links ml-2'
                        style={{
                          backgroundColor: "#1d1b1b",
                        }}
                        onClick={() => {}}
                      >
                        {addOrUpdateBtn ? "Update" : "Add"}
                      </button> */}
                    </div>
                  </Modal.Body>
                </Modal>
              ) : (
                ""
              )}

              {addAssignmentModal ? (
                <Modal show={addAssignmentModal} size='md'>
                  <Modal.Body>
                    <div className='prefarance-form playerinfo-form'>
                      <h1 className='m-title'>Add Assignment</h1>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='prefarance-form-list'>
                            <div className='last-row'>
                              <ul>
                                {allAssignmentData.map((item, index) => (
                                  <>
                                    <li
                                      style={{
                                        color: "#9e9e9e",
                                        fontSize: "16px",
                                        listStyle: "none",
                                        display: "flex",
                                        gap: "15px",
                                      }}
                                      key={item._id}
                                    >
                                      <input
                                        className='custom-checkbox'
                                        type='checkbox'
                                        onChange={(e) => {
                                          handleAssigmentCheckboxChange(
                                            e,
                                            item?._id,
                                            item?.assignment_name,
                                            item?.assignment_image
                                          );
                                        }}
                                      />

                                      <span className='graphic'>
                                        <img
                                          src={item?.assignment_image}
                                          alt='cricket-icon'
                                          className='img-fluid'
                                          style={{ "max-width": 50 }}
                                        />
                                      </span>
                                      <span>{item?.assignment_name}</span>
                                      {/* <div className='last-row'>
                                          <span>
                                            <button
                                              onClick={() => {
                                                deleteAssignmentDetailsById(
                                                  item._id
                                                );
                                              }}
                                            >
                                              <img src={Delect} />
                                            </button>{" "}
                                          </span>
                                        </div> */}
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='text-center mt-3'>
                      <button
                        className='add-links'
                        onClick={() => {
                          setAddAssignmentModal(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className='add-links ml-2'
                        style={{
                          backgroundColor: "#1d1b1b",
                        }}
                        onClick={handleAddNewAssignment}
                      >
                        Add
                      </button>
                    </div>
                  </Modal.Body>
                  {/* <Modal.Body>
                    <div className='team-payment team-assesment'>
                      <h1 className='m-title'>Add Assignment</h1>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='prefarance-form-list'>
                            <table>
                              <tr>
                                <th></th>
                                <th>Name</th>
                              </tr>

                              {allAssignmentData?.map((item, index) => {
                                return (
                                  <tr>
                                    <td>
                                      <input
                                        type='checkbox'
                                        onChange={(e) => {}}
                                      />
                                      <img
                                        src={item?.assignment_image}
                                        alt='cricket-icon'
                                        className='img-fluid'
                                        style={{ "max-width": 50 }}
                                      />
                                    </td>

                                    <td>
                                      <span>{item?.assignment_name}</span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='text-center mt-3'>
                      <button
                        className='add-links'
                        onClick={() => {
                          setAddAssignmentModal(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Modal.Body> */}
                </Modal>
              ) : (
                ""
              )}
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ScheduleAvailabilityDetails;
