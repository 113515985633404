import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    HashRouter,
} from "react-router-dom";
import '../../../Utils/css/style.css';
import '../../../Utils/css/responsive.css';
import "../../../Utils/css/bootstrap.min.css"
import "../../../Utils/css/bootstrap-datepicker.css"
import UserProfile from "../../../images/user-profile.png"
import tableProfile from "../../../images/table-profile.png"
import add from "../../../images/add.png"
import Delect from "../../../images/delect.png"
import pencil from "../../../images/pencil.png"
import SideMenuComponents from "../../../Components/SideMenu"
import Footer from "../../../Components/Footer";
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Network } from '../../../Services/Api';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { logoutUser } from "../../../Redux/Actions/auth";
import InterestPhoto from './InterestPhoto';
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Insta from "../../../images/insta.png"
// import { useHeaderContext } from "../ManagerContext/HeaderContext";
import ManagerHeader from "../../../Components/Header/ManagerHeader";


function Photos(props) {
    const history = useHistory();
    const dispatch = useDispatch()
    // const {
    //     updateSelectedId,
    //     updateManagerRosterId,
    //     updateTeamId,
    //     updateTeamName,
    //     teamName,
    //     teamId,
    //   } = useHeaderContext();

    const [userMe, setUser] = useState(null);
    const [user, setUserData] = useState({});
    const [dropdown, setDropdown] = useState([])
    const [teamDropdown, setTeamDropDown] = useState("")
    // const [image, setImage] = useState([])
    const [image_list, setImages] = useState([]);
    const [teamId, setTeamId] = useState("")
    const [folder, setFolder] = useState([])
    const [imageModal, setImageModal] = useState(false);
    const[file,setFile]= useState([])
    console.log(file)
    const[galaryname,setGalaryname]=useState("")



    useEffect(() => {
        // let user = userdata && userdata._id ? true : false;
        // console.log("userMe===>", user);
        setUser(user);
        // console.log("USerData", userdata);
        const userLocal = JSON.parse(localStorage.getItem("user"));
        console.log("userData after login--->", userLocal)
        let userD = userLocal && userLocal._id ? true : false;
        const team_id = localStorage.getItem("selectedTeamId");
        setUser(userD);
        setUserData(userLocal);
        dropdownMenu()
        setTeamDropDown()
        photoUpload(team_id)

    }, []);

    const handleLogout = () => {
        console.log("pruyuuuuuu", props);
        // dispatch(logoutUser(null));
        localStorage.removeItem("user");
        setUserData(null);
        props.history.push("/")
    };


    const dropdownMenu = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        // console.log(user);
        if (user) {
            let header = {
                'token': user.authtoken,

            }
            // console.log('user',header)

            Network('api/getAllTeamName?teamManagerId=' + user._id, 'get', header)
                .then(async (res) => {
                    console.log("dropdown----", res)
                    if (res.response_code == 400) {
                        dispatch(logoutUser(null))
                        localStorage.removeItem("user");
                        history.push("/")
                        toast.error(res.response_message)
                    }
                    setDropdown(res.response_data);

                   





                })
        }

    }

    // const change = (event) => {
    //     console.log("event", event)
    //     console.log("event", event.target.name)
    //     console.log("event", event.target.id)
    //     console.log("event", event.target.key)
    //     setTeamDropDown(event.target.value)
       
    // }
    // const insertImages =()=>{
    //     const user = JSON.parse(localStorage.getItem('user'));
    //     if (user) {
    //         const requestOptions = {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'token': user.authtoken
    //             },
    //             body: JSON.stringify(
    //                 {
    //                     "folderName":"test",
    //                     "teamId": teamDropdown ? teamDropdown : "6470683a88ea6b032e255a3e",
    //                     "uploaderUserId": user._id,

    //                 }

    //             )
    //         };
    //         console.log(requestOptions);
    //         fetch('https://www.sports-cloud.io/sportsapi/api/uploadTeamGalleryImages', requestOptions)
    //             .then(response => response.json())
    //             .then((res) => {
    //                 console.log("player data", remanger="manger"s)

    //                 if (res.response_code == 400) {
    //                     // dispatch(logoutUser(null))
    //                     // localStorage.removeItem("user");
    //                     history.push("/")
    //                     toast.error(res.response_message)
    //                 } else {
    //                     toast.success(res.response_message)
    //                     // history.goBack();
    //                 }
    //             })
    //     }

    // }
    const photoUpload = (id) => {


        const user = JSON.parse(localStorage.getItem("user"));

        if (user) {
            let header = {
                token: user.authtoken,
            };
            console.log("user", user);

            Network(
                // 'api/get-game-event-list-for-player?user_id='+user._id+'&page=1&limit=10',
                // "api/getAllEventAndGamesData?team_id="+"645cc97e6612dc1e4cd97597",
                "api/getAllGalleryImagesByTeamId?teamId=" + id,
                // +'&page=1&limit=10',
                "GET",
                header
            ).then(async (res) => {


                if (res.response_code == 4000) {
                    dispatch(logoutUser(null));
                    localStorage.removeItem("user");
                    history.push("/")
                    toast.error(res.response_message);
                }
                setFolder(res.response_data);
                console.log(res.response_data, "res data=====>>>>>>>>")
                // console.log("res",res.response_data)
                // schedule?.map((item) => {
                //   setData(item.roster_availability_data[0]?._id)
                //   console.log(item.roster_availability_data[0]?._id,"09898989")
                // })


            });
        }

    }
    const imageModalOpen = (id1, uId) => {
        setImageModal(true);
        
       
      };
      const updateNewGalary =()=>{
        const user = JSON.parse(localStorage.getItem("user"));
        const teamid = localStorage.getItem("selectedTeamId")

       const formData = new FormData();
      
           formData.append("teamId",teamid)
            formData.append("folderName",galaryname)
            formData.append("uploaderUserId",user._id)
            // formData.append("gallery_image ",file[0])
        // for ( let i=0;i<=file.length;i++){
        //     formData.append(`gallery_image [${i}]`,file[0]?.name)  
    
        // }
        file.forEach((image,index)=>{
          formData.append(`gallery_image`,image) 
        });
        axios("https://www.sports-cloud.io/sportsapi/api/uploadTeamGalleryImages", {
          method: "POST",
          headers: {
            token: user.authtoken,
            // "Content-Type": "multipart/form-data",
          },
          data: formData,
         
        }).then((res) => {
          console.log("edit player Image", res);
          
          if (res.data.success == true) {
            toast.success(res.data.response_message);
            setImageModal(false);
            // console.log(data,"data==>..>.")

            photoUpload(teamid)
          
          } if (res.data.success == false) {
            toast.error(res.data.response_message);
            setImageModal(false);
          }
        
    
          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
        })
    


      }
    const handleFileChange = (e) => {
    const files = e.target.files;
    setFile((prevFiles) => [...prevFiles, ...files]);
    // setFile([...file,...files])
    // setFile((prevState) => ({
    //     prevState,
    //     ["Img"]: e.target.files}))
    // setGalaryname(e.target.value)
    // setSelectedFiles([...selectedFiles, ...files]);
    // const previews = Array.from(files).map((file) => URL.createObjectURL(file));
    // setData([...data, ...previews]);
  };


    const getImages = (images) => {
        console.log(images);
        setImages(images);
    }
    const change = (event) => {
        console.log("event", event)
        setTeamId(event)
        photoUpload(event)
        
      }



    return (
        <div>
            <div className="dashboard-container">
                <div className="dashboard-main">
                    <SideMenuComponents manger="manger" />
                    <div className="dashboard-main-content">
                    <ManagerHeader change={change} />
                        {/* <div className="dashboard-head">
                            <div className="teams-select">
                                <select>
                                    <option>My Teams</option>
                                    <option>My Teams 2</option>
                                    <option>My Teams 3</option>
                                </select>
                                 <select onChange={change} value={teamDropdown == "" ? dropdown[0]?.team_id : teamDropdown} >
                                    {dropdown?.map((dropdown) => {
                                        return (
                                            <option key={dropdown.team_id} id={dropdown.team_id} name={dropdown.team_name} value={dropdown.team_id}>{dropdown.team_name}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className="profile-head">
                                <div className="profile-head-name">{user ? user?.fname : null}</div>
                                <div className="profile-head-img">
                                    {
                                        user ?
                                            <img src={user.profile_image} alt="" /> :
                                            <img src={UserProfile} alt="" />
                                    }

                                </div>
                            </div>
                            <div className="login-account">
                                <ul>
                                    <li><a href="#" data-toggle="modal" data-target="#myModallogin" onClick={handleLogout}>Logout</a></li>
                                    <li><a href="#" data-toggle="modal" data-target="#myModalregister" onClick={handleLogout}>Logout</a></li>
                                </ul>
                            </div>
                        </div> */}

                 <div className="prefarance-page">
                   <div className="player-info-head">
                    <h2 className="page-title">Photo Albums</h2>
                    <div className="player-info-head-right">
    
                      <div className="streming-head-right">
                       <div className="stream-tab">
                           <ul>
                               <li><a href="#" onClick={() => history.push("./TeamMedia")}>Stream</a></li>
                               <li><a className="active" href="#">Photos</a></li>
                               <li><a href="#" onClick={() => history.push("./Videos")}>Videos</a></li>
                               <li><a href="#" onClick={() => history.push("./Files")}>Files</a></li>
                           </ul>
                       </div>
                       
                       {/* <button className="start-stream-btn">Start Stream</button> */}
                       </div>
                    </div>
                    </div>
                    <div className="prefarance-box streaming-section">
                      <div className="photosTp">
                          <span>Photos</span>
                          <div className="photoProgress"><span>Storate : </span><ProgressBar now={90} /><span>0 MB used (0%), 98800 MB available</span></div>
                      </div>
                      <div className="photoTpBtm">
                            <p>TeamSnap makes it easy to share video links, photos and files with the entire team via a simple interface for uploading, organizing, and wirting custom titles and captions. Use the buttons in the upper right to switch between <span className="redTxt">Photos</span>, <span className="redTxt">Videos</span>, and <span className="redTxt">Files</span>. </p>
                            {/* <a href="#" className="addFirstvideo">Add Your First Photo</a> */}
                            {/* <input type="file" id="myFile" name="add your First Photo"/> */}
                            {/* <InterestPhoto getImages={getImages} /> */}
                      </div>
                      <div className="ppHead">
                                    <span></span>
                                    <button className="start-stream-btn actvUpld"   onClick={() =>
                                              imageModalOpen()
                                            } >Create New Folder</button>
                                    {/* <select>
                                        <option>Perform Action</option>
                                        <option>Perform Action2</option>
                                    </select> */}
                                   
                                </div>
                                {imageModal ? (
                  <Modal show={imageModal} size='md'>
                    <Modal.Body>
                      <div className='prefarance-form playerinfo-form'>
                        <h1 className='m-title'>Upload New Galary</h1>
                        <div className='row'>
                        <div className='col-md-12'>
                            <div className='prefarance-form-list'>
                              <label className='input-label'>
                                {" "}
                                Galary Name
                              </label>

                              <input
                                type='text'
                                className='input-select'
                              
                                placeholder='enter galary name '
                                onChange={(e) => setGalaryname(e.target.value)}
                                // defaultValue={newplayerdata[id].firstName}
                              />
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div
                              className='prefarance-form-list'
                              style={{ width: "100%" }}
                            >  
                            <label className='input-label'> Choose Image</label>

                             
                              <input
                                type='file'
                               
                              //  multiple onChange={(event) => handleFileChange(event)}
                               multiple onChange={(event) => handleFileChange(event)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='text-center mt-3'>
                          <button
                            className='add-links'
                            onClick={() => setImageModal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className='add-links ml-2'
                            style={{
                              backgroundColor: "#1d1b1b",
                            }}
                            onClick={updateNewGalary}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : (
                  ""
                )}
                <div className="team-shop-list-main">
                                    {folder?.map((item,index) => {
                                          return (
                                            // <div className="team-shop-list-main">
                                                <div className="team-shop-product-box tspb-folder-box">
                                                    <div className="team-shop-product-img">
                                                        {/* folder icon */}
                                                        <svg className='folder-icon' width="225" height="225" viewBox="0 0 225 225" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0 15C0 6.71573 6.71573 0 15 0H67.9915C73.5356 0 78.6277 3.05815 81.2327 7.95226L89.2673 23.0477C91.8723 27.9419 96.9644 31 102.509 31H210C218.284 31 225 37.7157 225 46V198.603C225 205.798 219.891 211.98 212.825 213.335L195.302 216.696C142.557 226.811 88.3946 227.072 35.5548 217.465L12.3167 213.239C5.18436 211.943 0 205.731 0 198.481V15Z" fill="#7A7A7A"/>
                                                        </svg>

                                                        <div className='team-folder-img'
                                                        // to={{ pathname: "/AddShopData/productId="+data._id, state: "GAME" }}
                                                        onClick={()=>history.push({ pathname: "/Photoview", state: {
                                                            myphotos:item.images,
                                                            galaryid:item._id,
                                                            // img_index:index
                                                        }})}
                                                        >
                                                            {item.images.length == 0 ? (
                                                                <img src={Insta} alt="" className='dummy-img' />
                                                            ) : (
                                                                // <img src={`${pic}${data.image}`} alt="" />}
                                                                <img src={item.images[0]} alt="" />
                                                            )}


                                                        </div>


                                                    </div>
                                                    <div className="team-shop-product-text">
                                                        <h2 className="product-title">{item.folder_name}</h2>
                                                        <p className="product-description">
                                                           <span>Total Image - {item.images.length}</span> 
                                                        </p>
                                                        {/* <div className="product-price">${data.price}</div> */}
                                                        {/* <div className="product-size">{data.size}</div> */}
                                                    </div>
                                                </div>
                                            // </div>
                                        )
                                    })
                                    }
                                </div>

                    
                    </div>
                    
                               
                                

                </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Photos;
