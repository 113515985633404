import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import TeamList from "../../../images/team-list.png";
import SideMenuComponents from "../../../Components/SideMenu";
import flag from "../../../images/flag.png";
import { propTypes } from "react-bootstrap/esm/Image";

import Accordion from 'react-bootstrap/Accordion';

const PlayerSideNav = ({name}) => {
  const history = useHistory();
  const [listValue, setList] = useState({
    email: false,
    alert: false,
    post: false,
    chat:false,
  });


  function playerListValsetter(vals){
    //localStorage.setItem('player_list_value', JSON.stringify(vals));
    setList(vals);
  }

/*
  useEffect(()=>{
    
    const userLocal = JSON.parse(localStorage.getItem('user'));
    if(userLocal.user_type == 'player'){
      if (localStorage.getItem('player_list_value') !== null) {
        setList(JSON.parse(localStorage.getItem('player_list_value')));
      }
      //console.log("test", userLocal, listValue);
    }else{
      localStorage.removeItem('player_list_value');
    }
    console.warn("test5555555555555", localStorage.getItem('player_list_value'));
    
  }, [])
*/

  return (
    <div
      style={{
        width: "25%",
        padding: "10px",
        borderRight: "1px solid #605d5d",
      }}
    >
    
      <div className="accorDianbg">
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-head" id="headingOne">
              <h2
                className={"mb-0"}
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded={"true"}
                aria-controls="collapseOne"
                onClick={() => {
                  history.push("/PlayerTeamMassage");
                  playerListValsetter({email: true,
                    alert: false,
                    post: false,
                    chat: false,
                  });
                  
                
                }}
              >
                Email
              </h2>
            </div>

            <div
              id="collapseOne"
              // className={"collapse show"}
              className={name === "Emails"  ? "collapse show" : "collapse"}
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <p
                  onClick={() => {
                    history.push("/PlayerTeamMassage");
                    playerListValsetter({
                      email: true,
                      alert: false,
                      post: false,
                    });
                  }}
                >
                  Compose Mail
                </p>
                <p
                  onClick={() => {
                    history.push("/PlayerInbox");
                    playerListValsetter({
                      email: true,
                      alert: false,
                      post: false,
                    });
                  }}
                  
                >
                  Inbox
                </p>
                <p
                  onClick={() => {
                    history.push("/PlayerSent");
                    playerListValsetter({
                      email: true,
                      alert: false,
                      post: false,
                    });
                  }}
                >
                  Sent
                </p>
                <p
                  onClick={() => {
                    history.push("/PlayerStarred");
                    playerListValsetter({
                      email: true,
                      alert: false,
                      post: false,
                    });
                  }}
                >
                  Starred Mail
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-head" id="headingTwo">
              <h2
                className="mb-0 collapsed"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                // aria-controls="collapseTwo"
                onClick={() => {
                  history.push("/PlayerChat");
                  playerListValsetter({
                    email: false,
                    alert: false,
                    post: false,
                  });
                }}
              >
                Chat
              </h2>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body"></div>
            </div>
          </div>
          <div className="card">
            <div className="card-head" id="headingThree">
              <h2
                className={"mb-0"}
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded={"false"}
                aria-controls="collapseThree"
                onClick={() => {
                  // history.push("/PlayerInbox");
                  history.push("/PlayerAlert");
                  playerListValsetter({
                    email: false,
                    alert: true,
                    post: false,
                  });
                }}
              >
                Alert
              </h2>
            </div>
            <div
              id="collapseThree"
              // className={"collapse"}
              className={name === "Alert" ? "collapse show" : "collapse"}
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <p
                  onClick={() => {
                    history.push("/PlayerAlert");
                    playerListValsetter({
                      email: false,
                      alert: true,
                      post: false,
                    });
                  }}
                >
                  New ALert
                </p>
                <p
                  onClick={() => {
                    history.push("/PlayerAlertInbox");
                    playerListValsetter({
                      email: false,
                      alert: true,
                      post: false,
                    });
                  }}
                >
                  Inbox
                </p>
                <p
                  onClick={() => {
                    history.push("/PlayerAlertSent");
                    playerListValsetter({
                      email: false,
                      alert: true,
                      post: false,
                    })
                  }}
                >
                  Sent
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-head" id="headingFour">
              <h2
                className="mb-0 collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseThree"
                onClick={() => {
                  history.push("/PlayerNewPost");
                }}
              >
                Posts
              </h2>
            </div>
            <div
              id="collapseFour"
              // className="collapse"
              className={name === "Post" ? "collapse show" : "collapse"}
              aria-labelledby="headingFour"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <p
                  onClick={() => {
                    history.push("/PlayerNewPost");
                  }}
                >
                  New Post
                </p>
                <p
                  onClick={() => {
                    history.push("/PlayerAllPosts");
                  }}
                >
                  All Posts
                </p>
                <p
                  onClick={() => {
                    history.push("/PlayerMyPosts");
                  }}
                >
                  My Posts
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerSideNav;
