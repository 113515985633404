import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import UserProfile from "../../../images/user-profile.png";
import flag from "../../../images/flag.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import BigUserProfile from "../../../images/big-user-profile.png";
import { EyeFill, ZoomIn } from "react-bootstrap-icons";
import axios from "axios";
import PlayerHeader from "../../../Components/Header/PlayerHeader";
import { usePlayerHeaderContext } from '../../Players/PlayerContext/PlayerHeaderContext';

const TeamAvailability = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [team, setTeam] = useState([]);
  const [divColor, setDivColor] = useState(false);
  const [profilePic, setProfilePic] = useState([]);
  const [teamDropdown, setTeamDropDown] = useState("");
  const [eventditailsmodel, seteventDitailsmodel] = useState(false);
  const [gameditailsmodel, setgameDitailsmodel] = useState(false);
  const [eventdetails, setEventdetails] = useState({});
  const [gamedetails, setGamedetails] = useState({});
  const [id1, setId1] = useState("");
  const [flagId, setFlagId] = useState("");
  const {  playerSelectedId, playerRosterId, playerTeamId, playerTeamName } =  usePlayerHeaderContext();

  const pic = "https://nodeserver.mydevfactory.com:1447/profilepic/";
  // const pic2 ="https://www.sports-cloud.io/sportsapi/user_profile_image/profile_image-1686564212825-D6DA274D-FF68-45E3-B8A5-18D8FA3AB18A.jpg"
  console.log(playerTeamId,"playerTeamId==>>")
  useEffect(() => {
    setUser(user);
    const newId = playerTeamId;
    const rosterid =playerRosterId
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    teamSelect();
    eventRosterDitails();
    gameRosterDitails();
    teamSchedule(newId,rosterid);
    updateProfile();
  }, [playerTeamId]);
  const handleLogout = () => {
    dispatch(logoutUser(null));
    localStorage.removeItem("user");
    setUserData(null);
    history.push("/");
  };

  const updateProfile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        "token": user.authtoken,
      };
      // console.log("user", user);

      Network("api/getUserDetailsById?user_id=" + user?._id, "GET", header).then(
        async (res) => {
          // console.log("new Profile Pic----", res);
          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          setProfilePic(res.response_data.userDetailsObj);
        }
      );
    }
  };

  // const teamSelect = () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   if (user) {
  //     let header = {
  //       authToken: user.authtoken,
  //     };
  //     console.log("user", user);

  //     Network(
  //       "api/player-joined-team-list?player_id=" + user._id,
  //       "GET",
  //       header
  //     ).then(async (res) => {
  //       console.log("res----", res);
  //       if (res.response_code == 4000) {
  //         dispatch(logoutUser(null));
  //         localStorage.removeItem("user");
  //         history.push("/");
  //         toast.error(res.response_message);
  //       }

  //       setTeam(res.response_data);
  //       // if(res.response_data.length!=0){
  //       teamSchedule(res.response_data[0]._id);
  //       // }
  //     });
  //   }
  // };

  const teamSelect = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      console.log("user", user);

      Network(
        // 'api/getAllAcceptedTeamListByPlayerId?playerId=' + "644a463b556e970345ff5be5",
        "api/getAllAcceptedTeamListByPlayerId?playerId=" + user._id,
        // "api/getAllAcceptedTeamListByPlayerId?playerId=" +"644a463b556e970345ff5be5",
        "GET",
        header
      ).then(async (res) => {
        console.log("res----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }

        setTeam(res.response_data);
        // teamSchedule(res?.response_data[0].accept_invite_team_id)
        // if(res.response_data.length!=0){
        // teamRoster(res?.response_data[0]._id);
        // }
      });
    }
  };

  // const teamSchedule = (id) => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   console.log("id<<<<<", id);
  //   if (user) {
  //     let header = {
  //       authToken: user.authtoken,
  //     };
  //     console.log("user", user);

  //     Network(
  //       "api/get-game-event-list-for-player?user_id=" +
  //         user._id +
  //         "&page=1&limit=10",
  //       "GET",
  //       header
  //     ).then(async (res) => {
  //       console.log("schedule----", res);

  //       if (res.response_code == 4000) {
  //         dispatch(logoutUser(null));
  //         localStorage.removeItem("user");
  //         history.push("/");
  //         toast.error(res.response_message);
  //       }
  //       setSchedule(res.response_data.docs);
  //     });
  //   }
  // };


  const teamSchedule = (id,playerarosteraid) => {
    const user = JSON.parse(localStorage.getItem("user"));
    // const playerarosteraid = localStorage.getItem("selectedplayerRosterId")
    console.log(playerarosteraid, "===>>>rosterid")
    console.log(id, "===>>>teamid")

    console.log("id<<<<<", `api/getAllEventAndGamesData?team_id=${id}&roster_id=${playerarosteraid}`,);
    if (user) {
      let header = {
        token: user.authtoken,
      };
      console.log("user", user);

      Network(
        // 'api/get-game-event-list-for-player?user_id='+user._id+'&page=1&limit=10',
        // "api/getAllEventAndGamesData?team_id="+"645cc97e6612dc1e4cd97597",
        "api/getAllEventAndGamesData?team_id=" + id + "&roster_id=" + playerarosteraid,
        // +'&page=1&limit=10',
        "GET",
        header
      ).then(async (res) => {
        console.log("schedule----", res);

        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/")
          toast.error(res.response_message);
        }
        setSchedule(res.response_data);
        console.log("res", res.response_data)
        // schedule?.map((item) => {
        //   setData(item.roster_availability_data[0]?._id)
        //   console.log(item.roster_availability_data[0]?._id,"09898989")
        // })


      });
    }
  };
  const change = (event) => {
    console.log("event", event.target.value);
    setTeamDropDown(event.target.value);

    const selectedTeamObject = team.find(
      (item) => item.accept_invite_team_id === event.target.value
    );

    localStorage.setItem("PlayerrTeamName", selectedTeamObject?.accept_invite_team_name);
    localStorage.setItem("PlayerTeamId", selectedTeamObject?.accept_invite_team_id);
    localStorage.setItem(
      "PlayerRosterId",
      selectedTeamObject?.accept_invite_id
    );
    // setTeamDropDown(event.target.value);


    teamSchedule(event.target.value);
  };



  const ditailsmodelvalue = (id, flag) => {
    setId1(id);
    flag == "Event" ? eventRosterDitails(id) : gameRosterDitails(id);
    if (flag == "Event") {
      seteventDitailsmodel(true);

    } else {
      setgameDitailsmodel(true);
    }
    console.log(flag, "======>>>>>>>>flag value");
  };

  const eventRosterDitails = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(id, "redgfdgfgfdgfcddsdfs");
    axios({
      method: "get",

      url:
        "https://www.sports-cloud.io/sportsapi/api/getRosterAvailableDetailsByEventId?events_id=" +
        id,
      //'https://www.sports-cloud.io/sportsapi/api/getRoasterDetailsById?rosterId=' + id,
      headers: {
        token: user.authtoken,
      },
    })
      .then(function (res) {
        console.log(res.data.response_data, "978767564554343456767475784789567856756");
        setEventdetails(res.data.response_data);

        if (res.response_code == 200) {
          seteventDitailsmodel(false);
          //   teamRoster(teamDropdown)
        }
      })
      .catch(function (res) {
        //  console.log(res)
      });
  };

  const gameRosterDitails = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(id, "redgfdgfgfdgfcddsdfs");
    axios({
      method: "get",

      url:
        "https://www.sports-cloud.io/sportsapi/api/getRosterAvailableDetailsByGameId?game_id=" +
        id,
      //'https://www.sports-cloud.io/sportsapi/api/getRoasterDetailsById?rosterId=' + id,
      headers: {
        token: user.authtoken,
      },
    })
      .then(function (res) {
        console.log(res, "game ditails");
        setGamedetails(res.data.response_data);

        if (res.response_code == 200) {
          setgameDitailsmodel(false);
          //   teamRoster(teamDropdown)
        }
      })
      .catch(function (res) {
        //  console.log(res)
      });
  };
  const selectFlag = (event) => {
    setFlagId(event.target.value);
  };


  return (
    <div>
      <div className="dashboard-container">
        <div className="dashboard-main">
          <SideMenuComponents />
          <div className="dashboard-main-content">
            {/* <div className="dashboard-head">
              <div className="teams-select">
                <select onClick={change}>
                  <option>Select Team</option>
                  {team?.map((team) => {
                    return (
                      // <option value={team.team_id._id}>
                      //   {team.team_id.team_name}
                      // </option>
                      <option value={team?.accept_invite_team_id}>
                        {team?.accept_invite_team_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="profile-head">
                {console.log(profilePic.lname, "3740000000000000000>>>>")}
                {profilePic?.fname ? (
                  <div className="profile-head-name">

                    {profilePic?.fname + " " + profilePic?.lname}
                  </div>
                ) : (
                  <div className="profile-head-name">{profilePic?.fname} {profilePic?.lname}</div>
                )}
                <div className="profile-head-img">
                  {console.log(profilePic, "profilepic===>>>")}
                  {profilePic?.profile_image == null ? (

                    <img src={BigUserProfile} alt="" />
                  ) : (
                    <img src={profilePic?.profile_image} alt="" />

                  )}
                </div>
              </div>
              <div className="login-account">
                <ul>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#myModallogin"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
              <PlayerHeader />
            <div>
              <h1
                style={{ color: "white", fontSize: "30px", fontWeight: "bold" }}
              >
                Availability for
              </h1>
            </div>
            {/* <div className="manager-player-section">
              <div className="teams-select1 ml-auto">
                <select className="ml-0">
                  <option>My Availability</option>
                  <option> Team Availability</option>
                </select>
              </div>
            </div> */}

            <div className="prefarance-box">
              <div className="team-payment team-assesment">

{/* 
                Table for show details */}
                <table>
                  <tr>
                    <th>Game/ Event</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Location</th>
                    <th>Attendence</th>
                    <th>show rosters</th>
                  </tr>
                  {schedule ? schedule?.map((item, id) => {
                    return (
                      <tr>
                        <td>
                          <div className="flag-prac">
                            {/* <img
                              src={schedule.display_icon.image}
                              alt=""
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "50%",
                              }}
                            /> */}
                            <button className="practice">
                              {item.isFlag == "Event"
                                ? item?.event_name
                                : item?.game_name}
                            </button>
                          </div>
                        </td>
                        <td>
                          <span>
                            {/* {new Date(schedule.date).getDate()}/
                            {new Date(schedule.date).getMonth()}/
                            {new Date(schedule.date).getFullYear()} */}
                            {new Date(item.date).getDate()}/
                            {new Date(item.date).getMonth()}/
                            {new Date(item.date).getFullYear()}
                          </span>
                        </td>
                        <td>
                          <span>
                            {/* {schedule.time.startTime}-{schedule.time.endTime} */}
                            {item?.start_time}-{item?.end_time}
                          </span>
                        </td>
                        <td>
                          <span>
                            {/* {schedule.location_details},{schedule.location} */}
                            {item.location.locationName}
                            {/* {item.location.address} */}

                          </span>
                        </td>
                        <td>
                          {
                            item.roster_availability_data[0]?.at_an_event === "going" ?

                              // <input
                              //   type="checkbox"
                              //   // style={{ width: "20px", height: "20px" ,color:"green"}}
                              //   style={{color:"green"}}
                              // />
                              <div className="checbox1"
                                style={{ width: "20px", height: "20px", color: "green", backgroundColor: "#1FD83C" }}
                              >

                              </div>
                              : item.roster_availability_data[0]?.at_an_event === "maybe" ?
                                <div className="checbox2"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px", backgroundColor: "#963687" }}>

                                </div> :
                                item.roster_availability_data[0]?.at_an_event === "no" ?
                                  <div className="checbox2"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px", backgroundColor: "#EC3525" }}>

                                  </div> : <div className="checbox2"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px", backgroundColor: "#353535" }}>

                                  </div>


                          }
                        </td>
                        <td>
                          <div className="last-row">
                            {/* <button data-toggle="modal" data-target="#assignmentdelect" onClick={() => deletePlayerData(player._id)} ><img src={Delect} /></button> */}
                            <button
                              onClick={() =>
                                ditailsmodelvalue(item._id, item.isFlag)
                              }
                            >
                              <EyeFill style={{ color: "white" }} />
                            </button>
                          </div>
                        </td>

                      </tr>
                    );
                  }) : (
                    <>
                      <tr>
                        <td colSpan={7} className="text-center text-red">
                          Please select team
                        </td>
                      </tr>
                    </>
                  )
                  }

                 
                </table>

                {/* {schedule?.map((item, id) => { */}
                {/* For events? */}
                {eventditailsmodel ? (
                  <Modal show={eventditailsmodel} size="md">
                    <Modal.Body>
                      <div className="prefarance-form playerinfo-form">
                        <h1 className="m-title">Event Roster Details</h1>
                        {eventdetails ? (
                          <>
                          
                            <div className="style={{ textAlign: 'center' }}">
                              <table style={{ color: 'white', margin: '0 auto', padding: '10px' }}>
                                <tr>
                                  <th style={{ padding: '10px' }}>profile picture</th>
                                  <th style={{ padding: '10px' }}>roster name</th>
                                  <th style={{ padding: '10px' }}>roster Attendence</th>
                                </tr>
                                {eventdetails.map((item) => {
                                  return (
                                    <tr>
                                      {console.log(item, "item++++")}
                                      <td style={{ padding: '10px' }}> <img src={item.assignment_volunteer_roster_profile_pic} style={{ width: '50px', height: '50px' }} /></td>
                                      <td style={{ padding: '10px' }}>{item.assignment_volunteer_roster_name}</td>
                                      <td style={{ padding: '10px' }}>{item.at_an_event === "going" ?

                                        <div className="checbox1"
                                          style={{ width: "20px", height: "20px", color: "green", backgroundColor: "#1FD83C" }}
                                        >

                                        </div>
                                        : item.at_an_event === "maybe" ?
                                          <div className="checbox2"
                                            type="checkbox"
                                            style={{ width: "20px", height: "20px", backgroundColor: "#963687" }}>

                                          </div> :
                                          item.at_an_event === "no" ?
                                            <div className="checbox2"
                                              type="checkbox"
                                              style={{ width: "20px", height: "20px", backgroundColor: "#EC3525" }}>

                                            </div> : <div className="checbox2"
                                              type="checkbox"
                                              style={{ width: "20px", height: "20px", backgroundColor: "#353535" }}>

                                            </div>
                                      }</td>
                                    </tr>)
                                })}


                              </table>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <div className="text-center">
                          <button
                            className="add-links"
                            style={{ margin: "10px" }}
                            onClick={() => seteventDitailsmodel(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : (
                  ""
                )}


{/* details modal */}
                {gameditailsmodel ? (
                  <Modal show={gameditailsmodel} size="md">
                    <Modal.Body>
                      <div className="prefarance-form playerinfo-form">
                        <h1 className="m-title">Roster Availability Details</h1>
                        {gamedetails ? (
                          <>
                           
                           <div className="style={{ textAlign: 'center' }}">
                              <table style={{ color: 'white', margin: '0 auto', padding: '10px' }}>
                                <tr>
                                  <th style={{ padding: '10px' }}>Profile Picture</th>
                                  <th style={{ padding: '10px' }}>Roster name</th>
                                  <th style={{ padding: '10px' }}>roster Attendence</th>
                                </tr>
                                {gamedetails.map((item) => {
                                  return (
                                    <tr>
                                      {console.log(item, "item++++")}
                                      <td style={{ padding: '10px' }}> <img src={item.assignment_volunteer_roster_profile_pic} style={{ width: '50px', height: '50px' }} /></td>
                                      <td style={{ padding: '10px' }}>{item.assignment_volunteer_roster_name}</td>
                                      <td style={{ padding: '10px',marginLeft:"35px" , display:'flex'}}>{item.at_an_event === "going" ?

                                        <div className="checbox1"
                                          style={{ width: "20px", height: "20px", color: "green", backgroundColor: "#1FD83C" }}
                                        >

                                        </div>
                                        : item.at_an_event === "maybe" ?
                                          <div className="checbox2"
                                            type="checkbox"
                                            style={{ width: "20px", height: "20px", backgroundColor: "#963687" }}>

                                          </div> :
                                          item.at_an_event === "no" ?
                                            <div className="checbox2"
                                              type="checkbox"
                                              style={{ width: "20px", height: "20px", backgroundColor: "#EC3525" }}>

                                            </div> : <div className="checbox2"
                                              type="checkbox"
                                              style={{ width: "20px", height: "20px", backgroundColor: "#353535" }}>

                                            </div>
                                      }</td>
                                    </tr>)
                                })}


                              </table>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="text-center mt-3">
                          <button
                            className="add-links"
                            onClick={() => setgameDitailsmodel(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div >
      </div >
    </div >
  );
};
export default TeamAvailability;
