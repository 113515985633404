import React, { createContext, useContext, useState, useEffect } from "react";

const PlayerHeaderContext = createContext();

export const usePlayerHeaderContext = () => useContext(PlayerHeaderContext);

 export const PlayerHeaderProvider = ({ children }) => {
  const [playerSelectedId, setPlayerSelectedId] = useState(null);
  const [playerRosterId, setPlayerRosterId] = useState(
    localStorage.getItem("selectedplayerRosterId") || null
  );
  const [playerTeamId, setPlayerTeamId] = useState(
    localStorage.getItem("playerselectedTeamId") || null
  );
  const [playerTeamName, setPlayerTeamName] = useState(
    localStorage.getItem("playerselectedTeamName") || null
  );
  // const [eventId, setEventId] = useState({ eventId: "", flag: "" });

  const updateplayerSelectedId = (id) => {
    setPlayerSelectedId(id);
  };

  const updateplayerRosterId = (id) => {
    console.log(id,"rosterid==>>>")
    setPlayerRosterId(id);
    localStorage.setItem("selectedplayerRosterId", id);
  };

  const updateplayerTeamId = (id) => {
    console.log(id,"teamid==>>>")
    setPlayerTeamId(id);
    localStorage.setItem("playerselectedTeamId", id);
  };

  // const updateEventId = (id, flag) => {
  //   setEventId({ eventId: id, isFlag: flag });
  //   localStorage.setItem(
  //     "selectedUpdatedGameId",
  //     JSON.stringify({ eventId: id, isFlag: flag })
  //   );
  // };

  const updateplayerTeamName = (name) => {
    setPlayerTeamName(name);
    localStorage.setItem("playerselectedTeamName", name);
  };

  useEffect(() => {
    const savedSelectedTeamId = localStorage.getItem("playerselectedTeamId");
    if (savedSelectedTeamId) {
      setPlayerTeamId(savedSelectedTeamId);
    }

    const savedRosterId = localStorage.getItem("selectedplayerRosterId");
    if (savedRosterId) {
      setPlayerRosterId(savedRosterId);
    }

    const savedTeamName = localStorage.getItem("playerselectedTeamName");
    if (savedTeamName) {
      setPlayerTeamName(savedTeamName);
    }

    // const savedEventId = localStorage.getItem("selectedUpdatedEventId");
    // console.log(savedEventId, "savedEventId");
    // const savedParsedEventId = JSON.parse(savedEventId);
    // console.log(savedParsedEventId, "savedEventId");
    // if (savedParsedEventId) {
    //   setEventId(savedParsedEventId);
    // }
  }, []);

  return (
    <PlayerHeaderContext.Provider
      value={{
        playerSelectedId,
        playerRosterId,
        playerTeamId,
        playerTeamName,
        updateplayerSelectedId,
        updateplayerRosterId,
        updateplayerTeamId,
        updateplayerTeamName,
        // updateEventId,
        // eventId,
      }}
    >
      {children}
    </PlayerHeaderContext.Provider>
  );
};

// export default playerHeaderContext;
