import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import UserProfile from "../../../images/user-profile.png";
import tableProfile from "../../../images/table-profile.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../Redux/Actions/auth";
import { ToastContainer, toast } from "react-toastify";
import BigUserProfile from "../../../images/big-user-profile.png";
import ManagerHeader from "../../../Components/Header/ManagerHeader";
import { useHeaderContext } from "../ManagerContext/HeaderContext";

function RecorderStatistic(props) {
  const history = useHistory();

  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const dispatch = useDispatch();
  const [team, setTeam] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [profilePic, setProfilePic] = useState([]);
  const [teamid, setTeamId] = useState("");
  const [statisticlist, setStatisticlist] = useState([]);
  const {
    updateSelectedId,
    updateManagerRosterId,
    updateTeamId,
    updateGameId,
    updateTeamName,
    teamName,
    teamId,
    managerRosterId,
  } = useHeaderContext();

  useEffect(() => {
    // let user = userdata && userdata._id ? true : false;
    // console.log("userMe===>", user);
    setUser(user);
    // console.log("USerData", userdata);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    teamSelect();
    updateProfile();
    recordedStisticList();
  }, []);

  const handleLogout = () => {
    console.log("pruyuuuuuu", props);
    // dispatch(logoutUser(null));
    localStorage.removeItem("user");
    setUserData(null);
    props.history.push("/");
  };
  const pic = "https://nodeserver.mydevfactory.com:1447/";
  const teamSelect = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        authToken: user.authtoken,
      };
      console.log("user", user);

      Network(
        "api/my-team-list?team_manager_id=" + user._id,
        "GET",
        header
      ).then(async (res) => {
        console.log("teanSelect----", res);
        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setTeam(res.response_data);
        teamSchedule(res.response_data[0]._id);
      });
    }
  };

  const teamSchedule = (id) => {
    console.log("id", id);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        authToken: user.authtoken,
      };

      let url = "";
      if (id != undefined) {
        url =
          "api/get-game-event-list?manager_id=" +
          user._id +
          "&team_id=" +
          id +
          "&page=1&limit=10";
      } else {
        url =
          "api/get-game-event-list?manager_id=" +
          user._id +
          "&team_id=" +
          teamId +
          "&page=1&limit=10";
      }
      //console.log('user',user)
      Network(
        "api/get-game-event-list?manager_id=" +
          user._id +
          "&team_id=" +
          id +
          "&page=1&limit=10",
        "GET",
        header
      ).then(async (res) => {
        console.log("schedule----", res);
        // if (res.response_code == 4000) {
        //     dispatch(logoutUser(null))
        //     localStorage.removeItem("user");
        //     history.push("/")
        //     toast.error(res.response_message)
        // }
        //console.log("doc data----->",res.response_data.docs)
        setSchedule(res.response_data.docs);
      });
    }
  };

  const updateProfile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        authToken: user.authtoken,
      };
      console.log("user", user);

      Network("api/get-user-details?user_id=" + user._id, "GET", header).then(
        async (res) => {
          console.log("new Profile Pic----", res);
          setProfilePic(res.response_data);
        }
      );
    }
  };

  const change = (event) => {
    console.log("event", event);
    setTeamId(event);
    teamSchedule(event);
  };

  const recordedStisticList = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      console.log("user", user);

      Network("api/get-all-statistics-data-by-manager-id", "GET", header).then(
        async (res) => {
          console.log("teanSelect----", res);
          if (res.response_code == 4000) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          setStatisticlist(res.response_data);
          console.log(res.response_data, "statisticlist==>>");
        }
      );
    }
  };
  const [selectedItems, setSelectedItems] = useState([]);
  console.log(selectedItems,"selecteditems----->>>>")

  // Function to toggle selection of an item
  const toggleSelection = (item) => {
    // If the item is already selected, remove it from the selectedItems array
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      // If the item is not selected, add it to the selectedItems array
      setSelectedItems([...selectedItems, item]);
    }
  };

  return (
    <div>
      <div className="dashboard-container">
        <div className="dashboard-main">
          <SideMenuComponents manger="manger" />
          <div className="dashboard-main-content">
            <ManagerHeader change={change} />
            {/* <div className="dashboard-head">
                            <div className="teams-select">
                                <button className="create-new-team" onClick={() => {
                                    history.push("/CreateTeam")
                                }}>Create New Teams</button>
                                <select onChange={change} >

                                    {team == null ? <option> Team1</option> :
                                        team.map((team) => {
                                            return (
                                                <option key={team.id}>{team.team_name}</option>
                                            )
                                        })}
                                </select>
                                <div className="dropBtn">
                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ backgroundColor: "#2C2C2C", border: "none" }}>
                                        ACCOUNT
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ backgroundColor: "#484848", listStyle: "none", margin: "14px" }}>
                                        <li><a className="dropdown-item" href="#">Jayanta Karmakar</a></li>
                                        <Link to={{ pathname: "/MyAccount" }} >
                                            <li><a className="dropdown-item" href="#">My Account</a></li>
                                        </Link>
                                        <Link to={{ pathname: "/Credit" }} >
                                            <li><a className="dropdown-item" href="#">Credits</a></li>
                                        </Link>
                                        <Link to={{ pathname: "/Household" }} >
                                            <li><a className="dropdown-item" href="#">My HouseHold</a></li>
                                        </Link>
                                        <Link to={{ pathname: "/ManageTeam" }} >
                                            <li><a className="dropdown-item" href="#">Manage My Team</a></li>
                                        </Link>
                                        <Link to={{ pathname: "/Biling" }} >
                                            <li><a className="dropdown-item" href="#">Biling & Plans</a></li>
                                        </Link>
                                        <Link to={{ pathname: "/CreateTeam" }} >
                                            <li><a className="dropdown-item" href="#">Create New Team</a></li>
                                        </Link>
                                        <Link to={{ pathname: "/SignOut" }} >
                                            <li><a className="dropdown-item active" href="#">Sign Out</a></li>
                                        </Link>

                                    </ul>
                                </div>
                            </div>
                            <div className="profile-head">
                                <div className="profile-head-name">{profilePic?.fname + " " + profilePic?.lname}</div>
                                <div className="profile-head-img">
                                    {profilePic?.profile_image == null ?
                                        <img src={BigUserProfile} alt="" /> :
                                        <img src={`${pic}${profilePic?.profile_image}`} alt="" />
                                    }

                                </div>
                            </div>
                            <div className="login-account"><ul><li><a href="#" data-toggle="modal" data-target="#myModallogin" onClick={handleLogout}>Logout</a></li></ul></div>

                        </div> */}
            <div className="player-info-head">
              <h2 className="page-title">Recorder Statistics</h2>
            </div>

            <div className="prefarance-box recordStatisticBg">
              <div className="playStat_Head">
                <span>
                  To re-order, just click and drag the box . Changes are saved
                  automatically.
                </span>
                <a href="#" className="doneBtn">
                  Done
                </a>
              </div>

              <div className="recordForm">
                {/* <span>Minutes Played</span>
                    <span>2 Point Shots Made</span>
                    <span>2 Point Shots Attempted</span>
                    <span>2 Point Shot Percentage</span>
                    <span>3 Point Shots Made</span>
                    <span>3 Point Shots Attempted</span>
                    <span>3 Point Shot Percentage</span>
                    <span>Free Throws Made</span>
                    <span>Free Throws Attempted</span>
                    <span>Free Throw Percentage</span>
                    <span>Total Points</span>
                    <span>Offensive Rebounds</span>
                    <span>Defensive Rebounds</span>
                    <span>Total Rebounds</span>
                    <span>Assists</span>
                    <span>Steals</span>
                    <span>Blocked Shots</span>
                    <span>Turnovers</span>
                    <span>Personal Fouls</span>
                    <span>Earned Run Average (Kickers)</span> */}
                {statisticlist?.map((item) => {
                  return (
                    <span
                      key={item.id}
                      style={{
                        color: selectedItems.includes(item) ? 'white' : 'white',
                        backgroundColor: selectedItems.includes(item) ? '#ec3525' : 'transparent',
                        // display: 'inline-block', // Ensure each span takes up only as much space as its content
                        // padding: '5px', // Add padding for better appearance
                        // margin: '5px', // Add margin for spacing between spans
                      }}
            
                      onClick={() => toggleSelection(item)}
                    >
                      {item.name_of_statistic}
                    </span>
                  );
                })}
              </div>

              <div className="recordstBtm">
                <a href="#" className="doneBtn">
                  Done
                </a>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecorderStatistic;
