import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
  useLocation,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import UserProfile from "../../../images/user-profile.png";
import tableProfile from "../../../images/table-profile.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import Insta from "../../../images/insta.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";
import "../Home/Player_style.css";
import Table from "react-bootstrap/Table";
import PlayerHeader from "../../../Components/Header/PlayerHeader";
import { usePlayerHeaderContext } from "../../Players/PlayerContext/PlayerHeaderContext";
import { ToastContainer, toast } from "react-toastify";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../Redux/Actions/auth";
// import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import axios from "axios";

function PlayerPhotoView(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.state;
  console.log(data, "images===<><><>><<><><");
  // const photo = data.myphotos

  // const [data, setData] = useState({});
  const [file,setFile]=useState([])
  const[image,setImages]=useState([])

  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [folder, setFolder] = useState([]);
  const { playerSelectedId, playerrRosterId, playerTeamId, playerTeamName } =
    usePlayerHeaderContext();

  // useEffect(() => {
  //   // setData(location.state);
  //   setImages(data.myphotos)
  //   console.log(location.state,"location.state==>>")
  //   console.log(data,"data++++++++++")
  // }, [data]);

  console.log(data,"data<><><><><")
  

  useEffect(() => {
    setImages(data?.myphotos)
    // const teamId = localStorage.getItem("playerselectedTeamId")
    // axios.get("api/getAllGalleryImagesByTeamId?teamId=" + teamId)
   
    // let user = userdata && userdata._id ? true : false;
    // console.log("userMe===>", user);
    setUser(user);
    // console.log("USerData", userdata);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    let userD = userLocal && userLocal._id ? true : false;
    const newid = playerTeamId;
    // photoUpload(newid);
    setUser(userD);
    setUserData(userLocal);
  }, [playerTeamId]);

 

  // const photoUpload = (id) => {
  //   const user = JSON.parse(localStorage.getItem("user"));

  //   if (user) {
  //     let header = {
  //       token: user.authtoken,
  //     };
  //     console.log("user", user);

  //     Network(
  //       // 'api/get-game-event-list-for-player?user_id='+user._id+'&page=1&limit=10',
  //       // "api/getAllEventAndGamesData?team_id="+"645cc97e6612dc1e4cd97597",
  //       "api/getAllGalleryImagesByTeamId?teamId=" + id,
  //       // +'&page=1&limit=10',
  //       "GET",
  //       header
  //     ).then(async (res) => {
  //       if (res.response_code == 4000) {
  //         dispatch(logoutUser(null));
  //         localStorage.removeItem("user");
  //         history.push("/");
  //         toast.error(res.response_message);
  //       }
  //       setFolder(res.response_data);
  //       console.log(res.response_data, "res data=====>>>>>>>>");
  //       // console.log("res",res.response_data)
  //       // schedule?.map((item) => {
  //       //   setData(item.roster_availability_data[0]?._id)
  //       //   console.log(item.roster_availability_data[0]?._id,"09898989")
  //       // })
  //     });
  //   }
  // };

  const deleteData = (data1, indexToRemove) => {
    // console.log("==========>",data)
    // let tempArr=data
    // let temp=data?.myphotos.filter(item=>item!=data1)

    // console.log(temp)
    // tempArr.myphotos=temp
    // setData({...data,myphotos:temp})
    //setData({...tempArr})
    const a = window.confirm("Are you sure you wish to delete this Data?");

    const photo = data1;
    const galaryid = data?.galaryid;
    console.log(data1, "photourl===>>>>");
    console.log(galaryid, "galaryid==>>>>");
    // const indexToRemove = data?.myphotos.indexOf(photo);

    // // Use splice to remove the photo at the found index
    // data?.myphotos.splice(indexToRemove, 1);

    // setData({ ...data })
    // console.log("[][][][][][][][][]",data)

    const user = JSON.parse(localStorage.getItem("user"));
    if (a == true) {
      axios({
        method: "post",

        url: "https://www.sports-cloud.io/sportsapi/api/deleteSingleImageByGalleryId",
        headers: {
          token: user.authtoken,
        },
        data: {
          galleryId: galaryid,
          image_url: photo,
        },
      })
        .then(function (res) {
          console.log(
            res.data.response_message,
            "978767564554343456767475784789567856756"
          );
          console.log(data,"data ====>>>>")

          if (res.data.success == true) {
            console.log(res)
            toast.success(res.data.response_message);
           
            // data?.myphotos.splice(indexToRemove, 1);
            image.splice(indexToRemove, 1);
            setImages(res.data.response_data.images)
          
            // setData({ ...data })
          
            //  photoUpload()
            // let tempArr = data;
            // let temp = data?.myphotos.filter((item) => item != data1);

            // console.log(temp);
            // tempArr.myphotos = temp;
            // setData({ ...data, myphotos: temp });
            // setData({ ...tempArr });
          }
          if(res.data.success == false){
            toast.error(res.data.response_message)
          }
        })
        .catch(function (res) {
          //  console.log(res)
        });
      console.log(
        JSON.stringify({
          galleryId: galaryid,
          image_url: photo,
        }),
        "data==>>>"
      );
    }
  };

  // const [selectedFiles, setSelectedFiles] = useState([]);
  // const [filePreviews, setFilePreviews] = useState([]);

  // Function to handle file input change
  const handleFileChange = (e) => {
    const files = e.target.files[0];
    setFile(files)
    // setSelectedFiles([...selectedFiles, ...files]);
    // const previews = Array.from(files).map((file) => URL.createObjectURL(file));
    // setData([...data, ...previews]);
  };

  // const handleFileUpload = () => {
  //   if (selectedFiles.length > 0) {
  //     // Implement code to upload selectedFile to your server or perform other actions
  //     console.log("Uploading:", selectedFiles);
  //   } else {
  //     alert("No file selected.");
  //   }
  // };

  // const updatephotos = () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   const formData = new FormData();
  //   console.log(data, "===>>>>");
  //   data.myphotos.map((url) => {
  //     const urlParts = url.split("/");
  //     const fileName = urlParts[urlParts.length - 1];
  //     const name = fileName.split(".")[0];
  //     const type = fileName.split(".")[1];

  //     const item = {
  //       uri: url,
  //       name: name,
  //       type: type,
  //     };
  //     console.log("===item====>", item);
  //     formData.append("gallery_image", item);
  //   });
  //   //   console.log(imageobj,"-->>");

  //   //formData.append("gallery_image", data.myphotos);
  //   formData.append("galleryId", data.galaryid);
  //   console.log(formData.entries(), "++++++++");
  //   for (var [key, value] of formData.entries()) {
  //     console.log("formData.entries===>", key, value);
  //   }
  //   axios("https://www.sports-cloud.io/sportsapi/api/editGalleryPicById", {
  //     method: "POST",
  //     headers: {
  //       token: user.authtoken,
  //       "Content-Type": "multipart/form-data",
  //     },
  //     data: formData,
  //   }).then((res) => {
  //     console.log("edit player Image", res.status);
  //     if (res.status == 200) {
  //       toast.success("update Succecfull");
  //       console.log("edit player Image", res.data);
  //       // photoUpload();
  //     }

  //     if (res.response_code == 400) {
  //       dispatch(logoutUser(null));
  //       localStorage.removeItem("user");
  //       history.push("/");
  //       toast.error(res.response_message);
  //     }
  //   });
  // };

  const addPhoto=()=>{
    const user = JSON.parse(localStorage.getItem("user"));
    const formData = new FormData();
    const galaryid = data?.galaryid;
    console.log(file, 'file')
    formData.append("gallery_image",file)
    formData.append("galleryId",galaryid)
    axios("https://www.sports-cloud.io/sportsapi/api/addSingleGalleryImageByGalleryId", {
      method: "POST",
      headers: {
        token: user.authtoken,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then((res) => {
      console.log("edit player Image", res);
      if (res.data.success == true) {
        
        toast.success(res.data.response_message);
        console.log("edit player Image", res.data);
        setImages(res.data.response_data.images)
        // photoUpload();
        history.pushState({'myphotos':res.data.response_data.images,
          galaryid:data.galaryid,})
      }
      if(res.data.success == false){
        toast.error(res.data.response_message)
      }

      if (res.response_code == 400) {
        dispatch(logoutUser(null));
        localStorage.removeItem("user");
        history.push("/");
        toast.error(res.response_message);
      }
    });


  }
  const  deleteFolder= ()=>{
    const a = window.confirm("Are you sure to delete all the photos?");

   
    const galaryid = data?.galaryid;
   
    console.log(galaryid, "galaryid==>>>>");
    // const indexToRemove = data?.myphotos.indexOf(photo);

    // // Use splice to remove the photo at the found index
    // data?.myphotos.splice(indexToRemove, 1);

    // setData({ ...data })
    // console.log("[][][][][][][][][]",data)

    const user = JSON.parse(localStorage.getItem("user"));
    if (a == true) {
      axios({
        method: "post",

        url: "https://www.sports-cloud.io/sportsapi/api/deleteGalleryById",
        headers: {
          token: user.authtoken,
        },
        data: {
          galleryId: galaryid
         
        },
      })
        .then(function (res) {
          console.log(
            res.data.response_message,
            "978767564554343456767475784789567856756"
          );
          console.log(data,"data ====>>>>")

          if (res.data.response_code == 200) {
            console.log(res)
            toast.success(res.data.response_message);
           
            // data?.myphotos.splice(indexToRemove, 1);
            // image.splice(0,image?.length);
            // setImages(res.data.response_data.images)
            setImages([])
            history.push("./PlayerPhotos")
          
           
          }
        })
        .catch(function (res) {
          //  console.log(res)
        });
      // console.log(
      //   JSON.stringify({
      //     galleryId: galaryid,
      //     image_url: photo,
      //   }),
      //   "data==>>>"
      // );
    }
  }

  return (
    <div>
      <div className="dashboard-container">
        <div className="dashboard-main">
          <SideMenuComponents />
          <div className="dashboard-main-content">
            <PlayerHeader />
            {/* <div className="dashboard-head">
                            <div className="teams-select">
                                <select>
                                    <option>My Teams</option>
                                    <option>My Teams 2</option>
                                    <option>My Teams 3</option>
                                </select>
                            </div>

                            <div className="profile-head">
                                <div className="profile-head-name">{user?.fname} {user?.lname}</div>
                                <div className="profile-head-img">
                                    {
                                        user ?
                                            <img src={user?.profile_image} alt="" /> :
                                            <img src={UserProfile} alt="" />
                                    }

                                </div>
                            </div>
                            <div className="login-account">
                                <ul>
                                    <li><a href="#" data-toggle="modal" data-target="#myModallogin" onClick={handleLogout}>Logout</a></li>
                                    <li><a href="#" data-toggle="modal" data-target="#myModalregister" onClick={handleLogout}>Logout</a></li>
                                </ul>
                            </div>
                        </div> */}

            <div className="prefarance-page">
              {/* <div className="player-info-head">
                                <h2 className="page-title">Photo Albums</h2>
                                <div className="player-info-head-right">
                                    <div className="streming-head-right">
                                        <div className="stream-tab">
                                            <ul>
                                                <li onClick={() => history.push("./PlayerMedia")}><a href="#">Stream</a></li>
                                                <li><a className="active" href="#">Photos</a></li>
                                                <li onClick={() => history.push("./PlayerVideos")}><a href="#">Videos</a></li>
                                                <li onClick={() => history.push("./PlayerFiles")}><a href="#">Files</a></li>
                                            </ul>
                                        </div>
                                        <button className="start-stream-btn actvUpld">Upload New Photo</button>
                                        <button className="start-stream-btn">Make Album Private</button>
                                    </div>
                                </div>
                            </div> */}

              {/* <div className="prefarance-form-list">
                                <label className="options-check-family">Select All
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                </label>
                            </div> */}

              <div className="prefarance-box playerPhotopage">
                {/* <div className="ppHead">
                                    <span>&nbsp;</span>
                                    <select>
                                        <option>Perform Action</option>
                                        <option>Perform Action2</option>
                                    </select>
                                </div> */}
                {/* <div className="ppTablebg">
                            <Table responsive="md">
                                    <thead>
                                        <tr>
                                            <th>Photo</th>
                                            <th>Title</th>
                                            <th>Added by</th>
                                            <th>Date Added</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>                                            
                                            <td><span className="ppImg"><img src={user.profile_image} alt="" /></span></td>
                                            <td>1551512214254545.jpg</td>
                                            <td>Lisa Menon</td>
                                            <td>Oct 20, 2021</td>
                                        </tr>
                                        <tr>                                            
                                            <td><span className="ppImg"><img src={user.profile_image} alt="" /></span></td>
                                            <td>1551512214254545.jpg</td>
                                            <td>Lisa Menon</td>
                                            <td>Oct 20, 2021</td>
                                        </tr>
                                        <tr>                                            
                                            <td><span className="ppImg"><img src={user.profile_image} alt="" /></span></td>
                                            <td>1551512214254545.jpg</td>
                                            <td>Lisa Menon</td>
                                            <td>Oct 20, 2021</td>
                                        </tr>
                                        <tr>                                            
                                            <td><span className="ppImg"><img src={user.profile_image} alt="" /></span></td>
                                            <td>1551512214254545.jpg</td>
                                            <td>Lisa Menon</td>
                                            <td>Oct 20, 2021</td>
                                        </tr>
                                    </tbody>                                    
                                </Table> 
                        </div>  
                          
                                         */}
                    {data?.galaryid ? <div>
                                       
                  <div className="d-flex justify-content-end">
                  
                  <button className="add-links" onClick={deleteFolder}>
                    All Delete
                  </button>
                  </div>
                <div className="team-shop-list-main">
               
                  {console.log(image,"image===>>>>>>>>+++++")}
                 
                  {image?.map((item, index) => {
                    // let text = "";
                    // for (let x of item) {
                    //     text += x;
                    //     console.log(text, "===>>>>>12334")
                    // }
                    return (
                     

                      <div key={index} className="team-shop-product-box">
                        <div className="team-shop-product-img">
                          <img src={item} alt="" />
                        </div>
                        {/* <div className="team-shop-product-text">
                                                        <h2 className="product-title">{item.folder_name}</h2>
                                                        <p className="product-description">
                                                            folder description
                                                        </p> */}
                        {/* <div className="product-price">${data.price}</div> */}
                        {/* <div className="product-size">{data.size}</div> */}
                        {/* </div> */}

                        <div className="product-action floated-top-right">
                          {/*<img
                            src={pencil}
                            alt=""
                            className="edit-icon"
                            // onClick={(e) =>
                            //     updateModalValue(id, data._id)
                            // }
                                                      />*/}
                          <img
                            src={Delect}
                            onClick={() => deleteData(item, index)}
                            alt="#"
                          />
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="team-shop-product-box">
                                        <div className="team-shop-product-img"> */}
                  {/* <input type="file" accept="image/*" multiple onChange={handleFileChange} />
                                            {data?.myphotos?.length > 0 &&
                                                data.myphotos.map((preview, index) => (
                                                    <div key={index}>
                                                        <img src={preview} alt={`Preview ${index}`} />
                                                    </div>
                                                ))}
                                            <button onClick={handleFileUpload}>Upload Photos</button> */}
                  {/* <button> <input type="file" accept="image/*" />+</button> */}
                  {/* <input type="file" accept="image/*" multiple onChange={handleFileChange} />
                                            {selectedFiles.length > 0 && (
                                                <div>
                                                    <p>Selected Files:</p>
                                                    <ul>
                                                        {selectedFiles.map((file, index) => (
                                                            <li key={index}>{file.name}</li>
                                                        ))}
                                                    </ul>
                                                    <button onClick={handleAddToPhotoArray}>Add to Photo Array</button>
                                                </div>
                                            )}
                                            {data?.myphotos?.length > 0 && (
                                                <div>
                                                    <p>Photos in Array:</p>
                                                    {data?.myphotos?.map((photo, index) => (
                                                        <img key={index} src={URL.createObjectURL(photo)} alt={`Photo ${index}`} />
                                                    ))}
                                                </div>
                                            )} */}

                  {/* 
                                        </div>
                                    </div> */}
                   
                </div>
                <div class="d-flex flex-wraps justify-content-between align-items-center">
                                      <input type="file" onChange={handleFileChange} className="my-3"/>
                  <button className="add-links my-3" onClick={addPhoto} >
                    Update
                  </button>
                  </div>
                  </div>:
                  <div>
                  <h1 style={{color:"white"}}>plese enter a folder</h1>
                  </div>
                  }

              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerPhotoView;
