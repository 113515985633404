// import React, { useState, useEffect, useRef } from "react";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   useHistory,
//   HashRouter,
// } from "react-router-dom";
// import "../../../Utils/css/style.css";
// import "../../../Utils/css/responsive.css";
// import "../../../Utils/css/bootstrap.min.css";
// import "../../../Utils/css/bootstrap-datepicker.css";
// import Alert from "react-bootstrap/Alert";
// import TeamList from "../../../images/team-list.png";
// import SideMenuComponents from "../../../Components/SideMenu";
// import Modal from "react-bootstrap/Modal";
// import flag from "../../../images/flag.png";
// import SideNav from "./sideNav";
// import send from "../../../images/send.png";

// import { useDispatch } from "react-redux";
// import axios from "axios";
// import { Network } from "../../../Services/Api";
// import { ToastContainer, toast } from "react-toastify";
// import { logoutUser } from "../../../Redux/Actions/auth";
// import { Button } from "react-bootstrap";
// import PlayerHeader from "../../../Components/Header/PlayerHeader";
// import { usePlayerHeaderContext } from "../../Players/PlayerContext/PlayerHeaderContext";
// import { SendFill } from "react-bootstrap-icons";
// import { CardImage } from "react-bootstrap-icons";
// import { Sector } from "recharts";


import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import Alert from "react-bootstrap/Alert";
import TeamList from "../../../images/team-list.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Modal from "react-bootstrap/Modal";
import flag from "../../../images/flag.png";
import SideNav from "./sideNav";
import ManagerHeader from "../../../Components/Header/ManagerHeader";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Network } from "../../../Services/Api";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import { Button } from "react-bootstrap";
import { useHeaderContext } from "../ManagerContext/HeaderContext";
import { SendFill } from "react-bootstrap-icons";
// import { database, auth } from "firebase";
import { getDatabase, ref, push, set, onChildAdded } from "firebase/database";

// import { Firestore } from "firebase/firestore";
// import {storage} from "firebase"
// import firebase from "firebase";
import { getStorage, uploadBytes, getDownloadURL } from "firebase/storage";;

const ManagerChat = () => {
  const dispatch = useDispatch();
  const bottomRef = useRef(null);
  const storage = getStorage();

  const { selectedId, managerRosterId, teamId, teamName } = useHeaderContext();
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(managerRosterId,"managerRosterId===>>>")
  console.log(user, "===>>user");
  //   console.log(playerTeamId, "playerTeamId==>>");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [chat, setchat] = useState([]);
  const [msg, setMsg] = useState("");
  const [roster, setRoster] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  console.log(selectedContact, '56556565656566666666666')
  const [receiverId, setReceiverId] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [chatid, setChatid] = useState("");
  const [photo, setPhoto] = useState(null);
  const[receiverImage, setReceiverImage] = useState("")

  const db = getDatabase();
  // const chatListRef = ref(db, `chat/chatId`);
  // let concatenatedIds

  // if (user._id > receiverId) {
  //   setChatid(user._id + receiverId);
  //  } else {
  //   setChatid(receiverId + user._id);
  //  }

  // const chatId =chatid

  const chatListRef = ref(db, `messages/${chatid}/`);

  // const chatId = `${user._id}_${receiverId}`;
  useEffect(() => {
    // const handleLocalStorageUpdate = () => {
    // const newId = localStorage.getItem("ManagerTeamId");
    const newId = teamId;
    showroster(newId);
    setName(user.fname);
    setEmail(user.email);

    // setchat([{name:"roster",message:"hello"},{name:"Arnab",message:"hi"}])
    // const chatListRef = ref(db, `chat/${user._id}_${receiverId}`);
    // // const chatListRef = ref(db, `chat/chatId`);

    onChildAdded(chatListRef, (data) => {
      console.log(data.val(), "datas.....>>>");
      const messageData = data.val();
      //setchat(state=>{})
      // if (messageData) {
      //   const messageList = Object.values(messageData);
      //   setchat(messageList);
      // } else {
      //   setchat([]);
      // }

      //  const c =[...chat]
      //  c.push(data.val())
      setchat((chat) => [...chat, data.val()]);
      setTimeout(() => {
        updateHight();
      }, 500);

      // addCommentElement(postElement, data.key, data.val().text, data.val().author);
    });
    //  console.log(user.fname,"==>>fname")
    // Fetch data using the new id and update the state
    // getAllMailList(newId);
    // };

    // window.addEventListener('localStorageUpdate', handleLocalStorageUpdate);

    // // Clean up the event listener when the component unmounts
    // return () => {
    //   window.removeEventListener('localStorageUpdate', handleLocalStorageUpdate);
    // };

    showroster(newId);
  }, [teamId, chatid]);

  const chatFunc = async () => {
    const chatListRef = ref(db, `chat/${user._id}_${receiverId}`);
    // const chatListRef = ref(db, `chat/chatId`);

    onChildAdded(chatListRef, (data) => {
      console.log(data.val(), "datas.....>>>");
      const messageData = data.val();
      //setchat(state=>{})
      // if (messageData) {
      //   const messageList = Object.values(messageData);
      //   setchat(messageList);
      // } else {
      //   setchat([]);
      // }

      //  const c =[...chat]
      //  c.push(data.val())
      setchat((chat) => [...chat, data.val()]);
      setTimeout(() => {
        updateHight();
      }, 500);
    });
  };

  // useEffect(() => {
  //   // 👇️ scroll to bottom every time messages change
  //   bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  // }, [chat]);

  const history = useHistory();

  const showroster = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("id------>", id);
    if (user) {
      let header = {
        token: user.authtoken,
      };
      console.log("user", user);

      // Network('api/getRosterListByTeamId?teamid=' +"6480285555cf8a5024960668", 'GET', header)
      Network(
        "api/getRoasterUserDetailsByTeamId?teamId=" + id,
        "GET",
        header
      ).then(async (res) => {
        console.log("teamRoster----", res);

        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        let mydata = res.response_data.filter((c) => c.user_id !== user._id);
        // setRoster(res.response_data);
        setRoster(mydata);
        // console.log(res)
        console.log("team player", res.response_data);

        // setNewPlayerData(res.response_data.player)

        // console.log( newplayerdata,"6767676769898988")
      });
    }
  };

  const handleContactClick = (contactName, receiverId,receverimage) => {
    // Logic to filter and display chat for the selected contact
    console.log(receiverId, "senderid==>>.");
    const selectedContactChat = chat.filter((c) => c.name === contactName);
    setSelectedContact(contactName);
    setReceiverId(receiverId);
    setReceiverImage(receverimage)
    setchat(selectedContactChat);
    if (managerRosterId > receiverId) {
      setChatid(managerRosterId + receiverId);
    } else {
      setChatid(receiverId + managerRosterId);
    }

    //chatFunc()

    // const chatId = `${user._id}_${receiverId}`;
    // const chatRef = database.ref(`chat/${chatId}`);
    // chatRef.on("value", (snapshot) => {
    //   const messageData = snapshot.val();
    //   if (messageData) {
    //     const messageList = Object.values(messageData);
    //     setMessages(messageList);
    //   } else {
    //     setMessages([]);
    //   }
    // });

    // return () => {
    //   // Cleanup the listener when the component is unmounted
    //   chatRef.off("value");
    // };

    updateHight();
  };

  // const sendMessage = async () => {
  //   const message = {
  //     sender: user._id,
  //     receiver: receiverId,
  //     text: newMessage,
  //     timestamp: Date.now(),
  //   };

  //   await database.ref(`messages/${user._id}_${receiverId}}`).push(message);
  //   setNewMessage('');
  // };

  const updateHight = () => {
    const el = document.getElementById("chat");
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  };

  const handlePhotoChange = async (e) => {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0])
    }
    // const file =e.target.files[0]
    // if (file) {
    //   const storageRef = ref(storage, `images/${file.name}`);
    //   await uploadBytes(storageRef, file);

    //   // Retrieve download URL
    //   const downloadURL = await getDownloadURL(storageRef);
    //   setPhoto(downloadURL);
    // }

  };

 

  const sendChat = async () => {

    // const photoRef = storage.ref(`chat_photos/${photo.name}`);
    // // const a = Firestore.ref
    // const uploadTask = photoRef.put(photo);

    // await uploadTask;
    // const photoUrl = await photoRef.getDownloadURL();
    if (photo) {
      // Handle photo upload
      // const formData = new FormData();
      // formData.append("photo", photo);
      // const photoRef = storage.ref(`chat/${chatid}/`);
      // // const a = Firestore.ref
      // const uploadTask = photoRef.put(photo);

      // await uploadTask;
      // const photoUrl = await photoRef.getDownloadURL();
      const chatRef = push(chatListRef);
      console.log(photo, "-->>.")
      set(chatRef, {
        name,
        email,
        sender: user._id,
        receiver: receiverId,
        message: photo,
        timestamp: new Date().toString(),
        profile_image: user.profile_image,
      });

      setPhoto(null);
    }
    if (msg) {
      const chatRef = push(chatListRef);
      const timestamp = Math.floor(Date.now() / 1000);
      set(chatRef, {
        // name,
        // email,
        // sender: user._id,
        // receiver: receiverId,
        // message: msg,
        // timestamp: Date.now(),
        // profile_image: user.profile_image,
        email,
        _id:  timestamp,
      avatar: receiverImage,
      user: {
        _id:user._id,
        avatar: user.profile_image,
        name: name,
      },
      // text: text,
      text: msg,
      createdAt:new Date().toString(),
      // createdAt: JSON.stringify(message[0].createdAt),
     

      });
    }

    // const c =[...chat]
    // c.push({name,message:msg})
    // setchat(c)
    setMsg("");
  };

  const change = () => {
    // console.log("eventssss=============");
    // setTeamDropDown(value);
    // setPlayer([])
    // teamRoster(event.target.value);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-main">
        <SideMenuComponents manger="manger" />
        <div className="dashboard-main-content">
          <ManagerHeader change={change} />
          <div className="prefarance-box player-info mail_body mt-0">
            <SideNav name="Chat" />
            <div className="tab-content">
              <div style={{ display: "flex", flexDirection: " row" }}>
                {/* <div className="contact" style={{height:"400px",backgroundColor:"#808080",borderRadius:"15px",overflow:'scroll'}} >
               <span><button>Arpita</button></span>
                <span><button>Arpita</button></span>
                <span><button>Arnav</button></span>


            </div> */}

                <div className="chat-user-list">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      height: "400px",
                      width: "250px",
                      backgroundColor: "#4c4b57",
                      borderRadius: "15px",
                      overflow: "scroll",
                    }}
                  >
                    {roster?.map((player) => {
                      return (
                        <>
                          <span>
                            <button
                              onClick={() =>
                                handleContactClick(
                                  player.fullName,
                                  player.rosterId,
                                  player.userImage
                                )
                              }
                            >
                              {player.fullName}
                            </button>
                          </span>
                          {/* <span>Arnab</span>
                <span>Srabani</span> */}
                        </>
                      );
                    })}
                  </div>
                </div>
                <div
                  id="chat"

                >
                  {selectedContact && <h3>{selectedContact}</h3>}

                  {/* { name ? */}
                  <div className="chat-wrapper" style={{
                    height: "400px",
                    width: "100%",

                    overflow: "scroll",
                  }}>
                    <div
                    //  className="contact-container"
                    >
                      {/* <h3>User: {name}</h3> */}
                      {/* <div className="contact">
                <span><button>Arpita</button></span>
                <span><button>Arpita</button></span>
                <span><button>Arnav</button></span>

              </div> */}

                      <div className="chat-ccontainer">
                        {selectedContact ? (
                          chat.map((c, i) => (
                            <div
                              key={i}
                              className={`ccontainer ${c?.user?._id === user._id ? "me" : "other"}`}
                            >
                              {/* Profile image for both users */}
                              {console.log(chat,"chat===>>")}
                              <img
                                src={c.user?.avatar?c.user?.avatar:c.avatar}
                                alt="Profile"
                              />
                              {/* Message content */}
                              <p className="chatbox">
                                <strong>{c?.name}</strong>
                                {/* <span>{c?.message}</span> */}
                                <strong>{c?.text}</strong>
                              </p>
                            </div>
                          ))
                        ) : (
                          "Please select a contact"
                        )}
                      </div>

                    </div>
                  </div>
                  {/* // :"null"} */}
                </div>
              </div>
              <div className="btm chat-bot-ctr">
                <input
                  type="text"
                  placeholder="enter your chat"
                  onInput={(e) => setMsg(e.target.value)}
                  value={msg}
                ></input>

                {/* <div> */}
                {/* <img src={photo} alt="selected-photo" style={{ height: '100px', width: '100px', borderRadius: '50%' }} onClick={handlePhotoChange} /> */}

                {/* <input
                    // id="fileInput"
                    type="file"
                 
                    onChange={handlePhotoChange}
                    // style={{ display: "none" }}
                  />
                  
                  <label className='input-label'> Choose Image</label> */}
                {/* <button>
                  <inpit type="file" onChange={handlePhotoChange}/>

                  
                    <CardImage />
                  </button> */}
                {/* </div> */}

                {/* <button className="send-btn" ><img src={send}/></button> */}
                {/* {msg? */}
                <button onClick={(e) => sendChat()}>
                  <SendFill size={30} />
                </button>
                {/* :"null"
                } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerChat;
