import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import TeamList from "../../../images/team-list.png";
import SideMenuComponents from "../../../Components/SideMenu";
import flag from "../../../images/flag.png";
import SideNav from "./sideNav";

import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import ManagerHeader from "../../../Components/Header/ManagerHeader";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Network } from "../../../Services/Api";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import { Button } from "react-bootstrap";
import { useHeaderContext } from "../ManagerContext/HeaderContext";

const AlertInbox = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [listValue, setList] = useState({
    email: false,
    alert: false,
    post: false,
  });
  const { selectedId, managerRosterId, teamId, teamName } = useHeaderContext();
  const [alertList, setAlertList] = useState([]);
  const [index, setIndex] = useState(null);
  const [singleAlert, setSingleAlert] = useState({});
  const [singleToAlert, setSingleToAlert] = useState([]);
  const [data, setData] = useState({})
  const [modalValue, setModalValue] = useState(false);
  const [teamDropdown, setTeamDropDown] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    const newId = teamId;
    getAllAlertList(newId);
  }, [teamId]);

  const modalOpen = (item) => {
    setModalValue(true);
    setSingleAlert(item);
    setSingleToAlert(item.to_user);
    setData(item.to_user[0]);
  };
  const modalClose = () => {
    setModalValue(false);
  };

  const getAllAlertList = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user._id;
    // const teamId = localStorage.getItem("ManagerTeamId");
    const token = user.authtoken;

    let header = {
      token: token,
    };
    Network(
      `api/getAllAlertList?teamId=${id}`,
      "GET",
      header
    ).then(async (res) => {
      if (res.response_code == 400) {
        dispatch(logoutUser(null));
        localStorage.removeItem("user");
        history.push("/");
        toast.error(res.response_message);
      }
      setAlertList(res.response_data);
      console.log("Mails", res.response_data);
    });
  };
  console.log("dropdoe", teamDropdown);

  const onDelete = (i) => {
    console.log("Deleted!!!");
    setShowAlert(true);
    setIndex(i);
  };

  return (
    <div
      className="prefarance-box player-info"
      style={{ height: "100%", marginTop: "0px", borderRadius: "0px" }}
    >
      <SideMenuComponents manger="manger" />

      <div className="dashboard-main-content">
        <ManagerHeader />
        <div className="prefarance-box player-info mail_body mt-0">
          <SideNav name="Alert" />

          <div className="tab-content">
            {alertList?.length === 0 || alertList?.length == undefined ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "gold",
                  marginTop: "20vh",
                }}
              >
                You haven't sent any emails
              </div>
            ) : (
              alertList?.map((item, i) => (
                <>
                  <div className="mail-body">
                    <div className="mail-item" onClick={() => modalOpen(item)}>
                      <img
                        src={item.to_user[0].alert_from_user_profile_pic}
                        className="mail-image"
                        alt="#"
                      />

                      <div className="item-name">
                        {item.to_user[0].alert_from_user_name}
                      </div>
                      <br />
                      <div className="item-subj text-eclipse-1">
                        {item.subject}{" "}
                      </div>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                    
                    <div onClick={() => onDelete(i)}>
                      <img
                        className="action-icon"
                        src={require("../../../images/delect-red.png")}
                        alt="#"
                        style={{ marginLeft: "20px" }}
                      />
                    </div>
                    </div>
                  </div>
                  {i == index && (
                    <Alert
                      show={showAlert}
                      variant="secondary"
                      style={{
                        borderColor: "black",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      }}
                      className="mt-3"
                    >
                      <p style={{ color: "#ddd", fontSize: "0.9rem" }}>
                        Are you sure you want to delete this mail?
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outline-light"
                          style={{
                            fontSize: "0.85rem",
                            marginRight: "5px",
                            border: "0",
                          }}
                          onClick={handleCloseAlert}
                          className="mr-2"
                        >
                          OK
                        </Button>
                        <Button
                          variant="outline-danger"
                          style={{
                            fontSize: "0.85rem",
                            marginRight: "5px",
                            border: "0",
                          }}
                          onClick={handleCloseAlert}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Alert>
                  )}
                </>
              ))
            )}

            <Modal
              className="prefarance-form alert-modal-w"
              size="lg"
              centered
              show={modalValue}
              onHide={modalClose}
            >
              <Modal.Header className="border-0 px-4">
                <Modal.Title
                  className="m-title m-0"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {/* 
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#ccc",
                      marginRight: "5px",
                    }}
                  >
                    Subject -
                  </p>*/}
                  <small>{singleAlert.subject}</small>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="viewMail-body pt-0 alert-modal">
                <div className="mail_sender-data">
                  <img
                    className="senderImage"
                    src={data.alert_from_user_profile_pic}
                    alt="#"
                  />
                  <div className="sndr-content">
                    <div className="sndr-name">{data.alert_from_user_name}</div>
                    <p className="sndr-email">{data.alert_from_user_mail}</p>
                    
                  </div>
                </div>
                <div className="mail-body">
                  <div>
                  <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <div>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "#aaa",
                          paddingRight: "5px",
                        }}
                      >
                        Send To:
                      </p>
                      <div className="to-email-w">
                      {singleToAlert.map((item) => {
                        return (
                          <div className="to-email" key={item._id}>
                            {item.alert_to_user_mail}
                          </div>
                        );
                      })}
                      </div>
                      </div>
                    </div>
                  </div>
                  <div>
                <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "#aaa",
                          paddingRight: "5px",
                        }}
                      >
                        Message:
                      </p>
                  <div className="massage-content">{singleAlert.message}</div>
                  </div>
                  </div>
                <div className="btn-act">
                  {/* <Button variant="outline-secondary" className="mr-3">
                      Reply
                    </Button> */}
                  <Button variant="outline-danger" onClick={modalClose}>
                    &times;
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertInbox;
