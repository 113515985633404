import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import Logo from "../../../images/logo.png";
import UserProfile from "../../../images/user-profile.png";
import TeamList from "../../../images/team-list.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";

import flag from "../../../images/flag.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import { useHeaderContext } from "../ManagerContext/HeaderContext";
import ManagerHeader from "../../../Components/Header/ManagerHeader";
import { data } from "jquery";

const Gamelist = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    updateSelectedId,
    updateManagerRosterId,
    updateTeamId,
    updateTeamName,
    teamName,
    teamId,
    managerRosterId
  } = useHeaderContext();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [dropdown, setDropdown] = useState([]);
  const [locationValue, setLocationValue] = useState([]);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    setUser(user);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    dropdownMenu();
    LocationData();
    // const team_id = localStorage.getItem("selectedTeamId");
    const newid=teamId
   const rosterid = managerRosterId
    
    teamSchedule(newid,rosterid);
  }, [teamId,managerRosterId]);
  const handleLogout = () => {
    // dispatch(logoutUser(null));
    localStorage.removeItem("user");
    setUserData(null);
    history.push("/");
  };

  const dropdownMenu = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      //console.log('user',user)

      Network(
        "api/my-team-list?team_manager_id=" + user._id,
        "GET",
        header
      ).then(async (res) => {
        console.log("dropdown----", res);
        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setDropdown(res.response_data);
      });
    }
  };
  const LocationData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      //console.log('user',user)

      Network("api/get-location-list", "GET", header).then(async (res) => {
        console.log("location----", res);
        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setLocationValue(res.response_data);
      });
    }
  };

  const teamSchedule = (id,ManagerRosterId) => {
    console.log("id", id);
    const user = JSON.parse(localStorage.getItem("user"));
    // const ManagerRosterId = localStorage.getItem("selectedManagerRosterId");
    console.log("authToken", user.authtoken);
   
    // console.log("ManagerRosterId", ManagerRosterId);
    if (user) {
      let header = {
        token: user.authtoken,
      };

      let url = "";
      //   if (id != undefined) {
      //     url =
      //       "api/get-game-event-list?manager_id=" +
      //       user._id +
      //       "&team_id=" +
      //       id +
      //       "&page=1&limit=10";
      //   } else {
      //     url =
      //       "api/get-game-event-list?manager_id=" +
      //       user._id +
      //       "&team_id=" +
      //       teamDropdown +
      //       "&page=1&limit=10";
      //   }
      //console.log('user',user)
      // var teamid = id ? id :   "6480285555cf8a5024960668";
      Network(
        "api/getAllEventAndGamesData?team_id=" +
          id +
          "&roster_id=" +
          ManagerRosterId,
        "GET",
        header
      ).then(async (res) => {
        console.log("schedule----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        if(res.success==true){
        console.log("doc data----->", res.response_data);
        
        let mydata = res.response_data.filter((c) => c.isFlag == "Game");
        // let Data = res.response_data?.map((item) => ({
        //   game_name:item.game_name,
        //   _id:item._id
        // }));
        setSchedule(mydata);
        console.log(mydata,"Data=====>>>>")
        }
 
      });
    }
  };

  // console.log(dropdown,"dropdown");

  const change = (event) => {
    console.log("event", event);
    updateTeamId(event);
  };

  return (
    <div>
      <div className="dashboard-container">
        <div className="dashboard-main">
          <SideMenuComponents manger="manger" />
          <div className="dashboard-main-content">
            <ManagerHeader change={change}  />
            {/* <div className="dashboard-head">
              <div className="teams-select">
                <select onChange={change} value={teamId}>
                  <option>Select A Team</option>
                  {dropdown?.map((dropdown) => {
                    return (
                      <option value={dropdown._id}>{dropdown.team_name}</option>
                    );
                  })}
                </select>
              </div>

              <div className="profile-head">
                <div className="profile-head-name">
                  {user ? user.fname : null}
                </div>
                <div className="profile-head-img">
                  {user ? (
                    <img src={user.profile_image} alt="" />
                  ) : (
                    <img src={UserProfile} alt="" />
                  )}
                </div>
              </div>
              <div className="login-account">
                <ul>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#myModallogin"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}

            {/* <div className="manager-player-section">
              <h3>Match :</h3>
              <div className="teams-select3" style={{ marginRight: "37%" }}>
                <select>
                  <option>Location</option>
                  {locationValue?.map((data) => {
                    return (
                      <option>
                        {data.locationName},{data.address}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="teams-select3 teamSlct">
                <select>
                  <option>All </option>
                  <option> Team Availability</option>
                </select>
              </div>
            </div> */}

            <div className="prefarance-box">
              <div className="team-payment team-assesment custom-team-schedule-tbl">
                <table>
                  <tr>
                    <th></th>
                    <th>Game/ Event</th>
                  </tr>

                  {schedule?.map((schedule, id) => {
                    return (
                      <tr>
                        {/* <td>
                          
                          {schedule.isFlag == "Game" ? (
                            <img
                              src={Cricket}
                              alt="cricket-icon"
                              className="img-fluid"
                              style={{ "max-width": 50 }}
                            />
                          ) : (
                            <img src={flagIcon} alt="flag-icon" className="" />
                          )}
                        </td> */}
                        <td>
                          <div className="flag-prac">
                            <Link
                              to={{
                                pathname: "/ScoreKeeper",
                                state: {
                                  // Include the data you want to pass in the state object
                                  teamid :teamId,
                                  gameID: schedule._id,
                                    gameName:schedule.game_name
                                },
                              }}
                            >
                              <button className="practice">
                                {/* {schedule.isFlag == "Game"
                                  ? schedule.game_name
                                  : ""} */}
                                  {schedule.game_name}
                              </button>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>

            {/* <div className="prefarance-box">
              <div className="team-payment team-assesment">
                <table style={{ textAlign: "center" }}>
                  <tr>
                    <th style={{ fontSize: "30px" }}>Non-Players</th>
                    <th style={{ fontSize: "30px" }}>2 Pointers</th>
                    <th style={{ fontSize: "30px" }}>3 Pointers</th>
                    <th style={{ fontSize: "30px" }}>Free Throws</th>
                  </tr>

                  <tr>
                    <td>
                      <img src={UserProfile} alt="" />
                      <span>Jayanta Karmakor</span>
                    </td>
                    <td>2</td>
                    <td>
                      <span>1</span>
                    </td>
                    <td>2</td>
                    <td>
                      <div className="last-row">
                        <button
                          data-toggle="modal"
                          data-target="#assignmentdelect"
                        >
                          <img src={Delect} />
                        </button>{" "}
                        <button>
                          <img src={pencil} />
                        </button>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div> */}

            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Gamelist;
