import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";

// Fix for marker icon issues
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = ({ latitude, longitude }) => {
  const [locationName, setLocationName] = useState("Loading location...");

  // Reverse geocode to get location name
  useEffect(() => {
    const fetchLocationName = async () => {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
        );
        const location = response.data.display_name;
        setLocationName(location);
      } catch (error) {
        console.error("Error fetching location name: ", error);
        setLocationName("Location name not available");
      }
    };

    fetchLocationName();
  }, [latitude, longitude]);

  const position = [latitude, longitude]; // Use passed latitude and longitude

  return (
    <MapContainer center={position} zoom={13} style={{ height: "400px", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {/* Single marker using the passed latitude and longitude */}
      <Marker position={position}>
        <Popup>
          {locationName} <br />
          Latitude: {latitude}, Longitude: {longitude}
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
