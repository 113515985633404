import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import UserProfile from "../../../images/user-profile.png";
import flag from "../../../images/flag.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import { useHeaderContext } from "../ManagerContext/HeaderContext";
import ManagerHeader from "../../../Components/Header/ManagerHeader";

const ManagerTeamAvailability = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    updateSelectedId,
    updateManagerRosterId,
    updateTeamId,
    updateGameId,
    updateTeamName,
    teamName,
    teamId,
  } = useHeaderContext();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [dropdown, setDropdown] = useState([]);
  const [teamDropdown, setTeamDropDown] = useState("");
  const [schedule, setSchedule] = useState([]);
  const [gameEvent, setGameEvent] = useState("60be4fb4fc11cd4f8ca2cf8c");
  const [gameEventAllData, setGameEventAllData] = useState([]);
  const [colorValue, setColorValue] = useState("");
  const [divColor, setDivColor] = useState("");
  const [listValue, setListValue] = useState();
  const [listId, setListUserId] = useState();
  const [colorSelect, setColorSeleted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [allUserId, setAllUserId] = useState([]);
  const [indexVal, setIndexVal] = useState();
  const [dataIdVal, setDataId] = useState([
    {
      dataId: "",
      indexVal: "",
    },
  ]);
  const divColor1 = divColor;
  console.log("dataId console", dataIdVal);
  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "Active", value: "1" },
    { name: "Radio", value: "2" },
    { name: "Radio", value: "3" },
  ];

  const colorArray = ["green", "gray", "red"];
  // console.log("color index", JSON.stringify(colorArray[dataIdVal[1].indexVal]))

  let AvailabilityArray = [
    {
      name: "GOING",
      value: "green",
    },
    {
      name: "MAYBE",
      value: "gray",
    },
    {
      name: "NO",
      value: "red",
    },
  ];

  useEffect(() => {
    setUser(user);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    dropdownMenu();
    const team_id = localStorage.getItem("selectedTeamId");
    teamSchedule(team_id);
    // GameEventList();
    setColor();
  }, []);
  const handleLogout = () => {
    dispatch(logoutUser(null));
    localStorage.removeItem("user");
    setUserData(null);
    history.push("/");
  };

  const pic = "https://www.sports-cloud.io/sportsapi/";

  const dropdownMenu = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      //console.log('user',user)

      Network(
        "api/getAllTeamName?teamManagerId=" + user._id,
        "GET",
        header
      ).then(async (res) => {
        console.log("dropdown----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setDropdown(res.response_data);

        // teamSchedule(res.response_data.team_id);
        // console.log(res.response_data,"idddddddddd");
      });
    }
  };

  const teamSchedule = (id) => {
    console.log("id", id);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };

      let url = "";
      // if (id != undefined) {

      //     url = 'api/getAllEventAndGamesData?team_id=' + id
      // }
      // else {
      //     url = 'api/get-game-event-list?manager_id=' + user._id + '&team_id=' + teamDropdown + '&page=1&limit=10'
      // }
      //console.log('user',user)
      Network("api/getAllEventAndGamesData?team_id=" + id, "GET", header).then(
        async (res) => {
          console.log("schedule----", res);
          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          console.log("doc data----->", res.response_data);
          setSchedule(res.response_data);
        }
      );
    }
  };

  const teamEventData = (id, flag) => {
    updateGameId(id, flag);
    history.push("/ManagerTeamDetails");
  };

  const GameEventList = (array) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };

      let url = "";
      if (array[0] == "Game") {
        url = "api/getRosterAvailableDetailsByGameId?game_id=" + array[1];
      } else {
        url = "api/getRosterAvailableDetailsByEventId?events_id=" + array[1];
        // url = 'api/team-player-availability-list?game_event_id=' + "60be4fb4fc11cd4f8ca2cf8c"
      }

      Network(url, "GET", header).then(async (res) => {
        console.log("gameEvent list----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setGameEventAllData(res.response_data.docs);
      });
    }
  };
  const setColor = (id, availability) => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(" id and availability", id, availability);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify({
        _id: id,
        availability: availability,
      }),
    };
    fetch(
      "https://www.sports-cloud.io/sportsapi/api/change-player-availability",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("availability", res);

        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
      });
  };

  const change = (event) => {
    console.log("event", event);
    setTeamDropDown(event);
    updateTeamId(event);
    teamSchedule(event);
  };

  const gameEventId = (e) => {
    const myArray = e.target.value.split("-");
    setGameEvent(myArray[1]);
    GameEventList(myArray);
    console.log("game event", myArray);
  };

  const colorChange = (e, id, dataId1) => {
    const indexNo = e.target.value;
    console.log("id and value", id, e.target.value, dataId1);
    setDataId([{ dataId: dataId1, indexVal: indexNo }]);
    //  setAllUserId([...allUserId,dataId])

    setListUserId(id);
    setIndexVal(e.target.value);

    if (e) {
      if (e.target.value == 0) {
        setDivColor("green");
      } else if (e.target.value == 1) {
        setDivColor("gray");
      } else if (e.target.value == 2) {
        setDivColor("red");
      } else {
        // setDivColor("green")
        console.log("jhfkg");
      }
    }
  };
  const goingcolor = { backgroundColor: "blue" };
  const goingcolor1 = { backgroundColor: "green" };

  console.log("color", divColor);

  const changeColor = (i) => {
    const goingcolor1 = { backgroundColor: "green" };
  };

  return (
    <div>
      <div className='dashboard-container'>
        <div className='dashboard-main'>
          <SideMenuComponents manger='manger' />
          <div className='dashboard-main-content'>
            <ManagerHeader change={change} />
            {/* <div className="dashboard-head">
              <div className="teams-select">
                <button
                  className="create-new-team"
                  onClick={() => {
                    history.push("/CreateTeam");
                  }}
                >
                  Create New Teams
                </button>

                <select onChange={change} value={teamId}>
                  <option>Select A Team</option>
                  {dropdown.map((dropdown) => {
                    return (
                      <option value={dropdown.team_id}>
                        {dropdown.team_name}
                      </option>
                    );
                  })}
                </select>
                <select>
                  <option>Account</option>
                  <option>Account 2</option>
                  <option>Account 3</option>
                </select>
              </div>

              <div className="profile-head">
                <div className="profile-head-name">
                  {user ? user.fname : null}
                </div>
                <div className="profile-head-img">
                  {user ? (
                    <img src={user.profile_image} alt="" />
                  ) : (
                    <img src={UserProfile} alt="" />
                  )}
                </div>
              </div>
              <div className="login-account">
                <ul>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#myModallogin"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className='player-info-head availability'>
              <h2 className='page-title'>Availability</h2>
              <div className='teams-select'>
                <select onChange={gameEventId}>
                  <option>Select Game/Event</option>
                  {schedule?.map((schedule) => {
                    return (
                      <option value={`${schedule.isFlag}-${schedule._id}`}>
                        {schedule.isFlag == "Game"
                          ? schedule.game_name
                          : schedule.event_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className='player-info-head-right '>
                <div className='teams-select'>
                  <select className='w-auto mr-0'>
                    <option>Show All</option>
                    <option> Games Only</option>
                    <option> Events Only</option>
                  </select>

                  <button
                    className='add-new-family px-3'
                    style={{ width: "auto" }}
                    onClick={() => {
                      history.push("./preferance");
                    }}
                  >
                    Availability Preference
                  </button>
                  {/* <button className='edit-btn' style={{ marginLeft: "5px" }}>
                    Export
                  </button> */}
                </div>{" "}
              </div>
            </div>

            <div className='team-payment team-assesment'>
              {/* <table>
                 
                  <tr>
                    <th
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        paddingLeft: "10%",
                      }}
                    >
                      Game/ Event
                    </th>
                    <th
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        paddingLeft: "30%",
                      }}
                    >
                      {" "}
                      Date
                    </th>
                    <th
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        paddingLeft: "30%",
                      }}
                    >
                      {" "}
                      Location
                    </th>
                    <th
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        paddingLeft: "30%",
                      }}
                    >
                      {" "}
                      Details
                    </th>
                  </tr>

                  {schedule?.map((schedule, id) => {
                    return (
                      <tr>
                        <td style={{ paddingLeft: "10%" }}>
                          <div className='flag-prac'>
                            <button className='practice'>
                              {schedule.isFlag == "Game"
                                ? schedule.game_name
                                : schedule.event_name}
                            </button>
                          </div>
                        </td>

                        <td style={{ display: "flex", paddingLeft: "40%" }}>
                          <div className='last-row'>
                            <button
                              onClick={() =>
                                teamEventData(schedule._id, schedule.isFlag)
                              }
                            >
                              <img src={pencil} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table> */}
              <table>
                <tr>
                  <th>Game/ Event</th>
                  <th>Date Time</th>
                  <th>Location</th>
                  <th>Details</th>
                </tr>

                {schedule?.map((schedule, id) => {
                  return (
                    <tr>
                      <td>
                        <div className='flag-prac'>
                          <button className='practice'>
                            {schedule.isFlag == "Game"
                              ? schedule.game_name
                              : schedule.event_name}
                          </button>
                        </div>
                      </td>
                      <td>
                        <span>{schedule.date.split("T")[0]}</span>
                      </td>

                      <td>
                        <span>{schedule.location.locationName}</span>
                      </td>

                      <td>
                        <div className='last-row'>
                          <button
                            style={{ color: "#ec3525" }}
                            onClick={() =>
                              teamEventData(schedule._id, schedule.isFlag)
                            }
                          >
                            {/* <img src={pencil} /> */}
                            View
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManagerTeamAvailability;
