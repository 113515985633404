import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import TeamList from "../../../images/team-list.png";
import UserProfile from "../../../images/user-profile.png";
import SideMenuComponents from "../../../Components/SideMenu";
import flag from "../../../images/flag.png";
import SettingNav from "./settingNav";
import DatePicker from "react-datepicker";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import ManagerHeader from "../../../Components/Header/ManagerHeader";
import { useHeaderContext } from "../ManagerContext/HeaderContext";

const TeamSettingHome = () => {
  const history = useHistory();
  const [listValue, setList] = useState({
    email: false,
    alert: false,
    post: false,
  });

  const {
    updateSelectedId,
    updateManagerRosterId,
    updateTeamId,
    updateGameId,
    updateTeamName,
    teamName,
    teamId,
    managerRosterId,
  } = useHeaderContext();

  const [startDate, setStartDate] = useState(new Date());
  const dispatch = useDispatch();

  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [teamDropdown, setTeamDropDown] = useState("");

  const [valueDropDown, setValueDropDown] = useState("");
  const [eventType, setEventType] = useState();
  const [editing, setEditing] = useState(false);
  const [team, setTeam] = useState({});

  useEffect(() => {
    // let user = userdata && userdata._id ? true : false;
    // //console.log("userMe===>", user);
    // dropdownMenu();
    // setUser(user);
    // //console.log("USerData", userdata);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    //console.log("userData after login--->", userLocal)
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    const newid = teamId;
    teamData(newid);
    timezone();

    // teamSchedule();
  }, [teamId]);

  const handleLogout = () => {
    //console.log("pruyuuuuuu", props);
    // dispatch(logoutUser(null));
    localStorage.removeItem("user");
    setUserData(null);
    history.push("/");
  };

  // const dropdownMenu = () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   if (user) {
  //     let header = {
  //       authToken: user.authtoken,
  //     };
  //     //console.log('user',user)

  //     Network(
  //       "api/my-team-list?team_manager_id=" + user._id,
  //       "GET",
  //       header
  //     ).then(async (res) => {
  //       console.log("dropdown----", res);
  //       if (res.response_code == 4000) {
  //         dispatch(logoutUser(null));
  //         localStorage.removeItem("user");
  //         history.push("/");
  //         toast.error(res.response_message);
  //       }
  //       setDropdown(res.response_data);

  //       teamSchedule(res.response_data[0]._id);
  //     });
  //   }
  // };
  const change = (event) => {
    console.log("event", event);
    setTeamDropDown(event);
    teamSchedule(event);
    teamData(event);
  };

  const teamSchedule = (id) => {
    console.log("id", id);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        authToken: user.authtoken,
      };

      let url = "";
      if (id != undefined) {
        url =
          "api/get-game-event-list?manager_id=" +
          user._id +
          "&team_id=" +
          id +
          "&page=1&limit=10";
      } else {
        url =
          "api/get-game-event-list?manager_id=" +
          user._id +
          "&team_id=" +
          teamDropdown +
          "&page=1&limit=10";
      }
      //console.log('user',user)
      Network(
        "api/get-game-event-list?manager_id=" +
          user._id +
          "&team_id=" +
          id +
          "&page=1&limit=10",
        "GET",
        header
      ).then(async (res) => {
        console.log("schedule----", res);
        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        //console.log("doc data----->",res.response_data.docs)
        setSchedule(res.response_data.docs);
      });
    }
  };
  const flagList = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        authToken: user.authtoken,
      };
      //console.log('user',user)

      Network("api/all-flag-list", "GET", header).then(async (res) => {
        console.log("flagList----", res);
        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
      });
    }
  };

  const deleteScheduleData = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("id-------------->", id);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": user.authtoken,
      },
      body: JSON.stringify({
        _id: id,
      }),
    };
    fetch(
      "https://nodeserver.mydevfactory.com:1447/api/delete-assignment",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("delete assignment data", res);
        if (res.response_code == 4000) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        teamSchedule();
      });
  };
  const [sportsVal, setSportsVal] = useState(null);
  const sportsData = [
    { label: "Basketball", value: "1" },
    { label: "Baseball", value: "2" },
    { label: "Soccer", value: "3" },
    { label: "Cricket", value: "4" },
    { label: "Badminton", value: "5" },
  ];
  const [ageGroup, setAgeGroup] = useState(null);
  const ageData = [
    { label: "Select Age", value: "0" },
    { label: "19 & Under", value: "1" },
    { label: "20 - 30", value: "2" },
    { label: "30 - 40", value: "3" },
    { label: "Over 40", value: "4" },
  ];
  const [gender, setGender] = useState(null);
  const genderData = [
    { label: "Select Gender", value: "0" },
    { label: "Male", value: "1" },
    { label: "Female", value: "2" },
  ];

  const [loc, setLoc] = useState(null);
  const locData = [
    { label: "USA", value: "1" },
    { label: "Canada", value: "2" },
    { label: "Mexico", value: "3" },
    { label: "UK", value: "4" },
    { label: "India", value: "5" },
  ];
  const [sport, setSport] = useState("");

  const teamData = (id) => {
    console.log("id", id);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };

      Network("api/getTeamDetailsById?teamId=" + id, "GET", header).then(
        async (res) => {
          console.log("schedule----", res);
          if (res.response_code == 4000) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          if (res.success === true) {
            //console.log("doc data----->",res.response_data.docs)
            setTeam(res.response_data);
            setSport(res.response_data.sports);
            console.log(res.response_data, "teamdata==>>");
          }
        }
      );
    }
  };

  const [timezonelist, setTimeZoneList] = useState([]);

  const timezone = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      let header = {
        token: user.authtoken,
      };

      Network("api/getAllTimeZoneList", "GET", header).then(async (res) => {
        console.log("schedule----", res);
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setTimeZoneList(res.response_data);
        console.log(res.response_data, "timezonelist==>>>");
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeam((prevTeam) => ({
      ...prevTeam,
      [name]: value,
     
    }));
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    // setEditing(false);
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(team, "team==>>>>");
    if (user) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: user.authtoken,
        },
        body: JSON.stringify({team,teamId:teamId}),
      };

      // Assuming you want to send the entire team object for editing
      fetch(
        "https://www.sports-cloud.io/sportsapi/api/editTeamDetailsById",

        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          console.log("Schedule  data", res);
          if (res.success == true) {
            if (imageselect) {
              teampictureupdate();
            }
            toast.success(res.response_message);
            teamData(teamId);
          }
          if (res.success == false) {
            toast.error(res.response_message);
          }
        });
    }
  };
  const [imageselect, setImageselect] = useState(false);
  const [file, setFile] = useState([]);
  console.log(file, "----------------------------------------------");
  const teampictureupdate = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const formData = new FormData();
    formData.append("teamId", teamId);

    formData.append("profile_image", file);
    if (user) {
      const requestOptions = {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          token: user.authtoken,
        },
        body: formData,
      };
      fetch(
        "https://www.sports-cloud.io/sportsapi/api/update-team-profile-image",

        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          console.log("Schedule  data", res);
          if (res.response_code == 200) {
          }
          if (res.response_code == 400) {
            toast.error(res.response_message);
          }
        });
    }
  };
  const handleFileChange = (e) => {
    const files = e.target.files[0];
    setFile(e.target.files[0]);
    setImageselect(true);
    // setSelectedFiles([...selectedFiles, ...files]);
    // const previews = Array.from(files).map((file) => URL.createObjectURL(file));
    // setData([...data, ...previews]);
  };

  return (
    <div
      className="prefarance-box player-info"
      style={{ height: "100%", marginTop: "0px", borderRadius: "0px" }}
    >
      <SideMenuComponents manger="manger" />
      <div className="dashboard-main-content">
        <ManagerHeader change={change} />
        {/* <div className="dashboard-head">
                    <div className="teams-select">
                        <button className="create-new-team" onClick={() => history.push("./CreateTeam")}>Create New Teams</button>

                        <select onChange={change} value={teamDropdown == "" ? dropdown ?._id : teamDropdown} >
                            {dropdown?.map((dropdown) => {
                                return (
                                    <option value={dropdown._id}>{dropdown.team_name}</option>
                                )
                            })}
                        </select>
                        <select>
                            <option>Account</option>
                            <option>Account 2</option>
                            <option>Account 3</option>
                        </select>
                    </div>

                    <div className="profile-head">
                        <div className="profile-head-name">{user ? user.fname : null}</div>
                        <div className="profile-head-img">
                            {
                                user ?
                                    <img src={user.profile_image} alt="" /> :
                                    <img src={UserProfile} alt="" />
                            }

                        </div>
                    </div>
                    <div className="login-account">
                        <ul>
                            <li><a href="#" data-toggle="modal" data-target="#myModallogin" onClick={handleLogout}>Logout</a></li>
                        </ul>
                    </div>
                </div> */}
        <div className="prefarance-page">
          <div className="page-header">
            <h2 className="page-title">Manager</h2>
            {/* <div className="teams-select">
              <button
                className="create-new-team"
                onClick={() => history.push("./CreateTeam")}
                style={{ backgroundColor: "red" }}
              >
                Create New Teams
              </button>
            </div> */}
          </div>

          <div className="prefarance-box" style={{ overflow: "auto" }}>
            <SettingNav />

            <div
              className="team-payment team-assesment"
              style={{ marginTop: "10px" }}
            >
              <div className="prefarance-form playerinfo-form">
                <div className="row" style={{ padding: "20px" }}>
                  {/* {editing ? ( */}
                  <div>
                    <div className="col-md-12">
                      <div className="prefarance-form-list">
                        <label htmlFor="image">Image:</label>
                        {team.team_profile_image ? (
                          <div className="team-shop-product-box">
                            <div className="team-shop-product-img">
                              <img src={team.team_profile_image} />
                              <input
                                type="file"
                                id="image"
                                name="image"
                                accept="image/*"
                                onChange={handleFileChange}
                              />
                            </div>
                          </div>
                        ) : (
                          <input
                            type="file"
                            id="image"
                            name="image"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="prefarance-form-list">
                        {/* <label>Team</label>
                      <select className="input-select">
                        <option>Kolkata Night Rider </option>
                        <option> Chennai Super King</option>
                      </select> */}
                        <label htmlFor="team_name">Team</label>
                        <input
                          className="input-select"
                          type="text"
                          id="team_name"
                          name="team_name"
                          value={team.team_name}
                          onChange={handleChange}
                        />
                        {/* {team.team_name} */}
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                    <div className="prefarance-form-list"></div>
                  </div>
                  <div className="col-md-2">
                    <div className="prefarance-form-list">
                      <div className="EditPhoto">Edit</div>
                    </div>
                  </div> */}

                    <div className="col-md-12">
                      <div className="prefarance-form-list">
                        {/* <label> Your Team Logo</label>
                      <input
                        type="text"
                        className="input-select"
                        style={{ borderRadius: "30px", width: "33%" }}
                        placeholder=" Upload A Team Logo"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "15px",
                          padding: "5px",
                        }}
                      >
                        For best result, your team logo should be transparent
                        PNG.{" "}
                        <span style={{ color: "red" }}>
                          {" "}
                          Learn more info about team logos.
                        </span>
                      </p> */}
                        <label>Sports</label>
                        <select
                          className="input-select"
                          id="sports"
                          name="sports"
                          value={team.sports}
                          onChange={handleChange}
                          defaultValue={
                            team.sports ? team.sports : "select team sports"
                          }
                          // defaultValue={sport?sport:"set sport"}
                        >
                          {/* <span> {sport?sport:"set sport"}
                        {console.log(sport,"sport-->>>")}
                       { console.log(team.sports,"team.sports==>>")} */}
                          {/* </span> */}
                          {sportsData?.map((sport) => (
                            <option key={sport.value} value={sport.label}>
                              {sport.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="prefarance-form-list">
                        {/* <label>Your Team Photo</label>
                      <input
                        type="text"
                        className="input-select"
                        style={{ borderRadius: "30px", width: "33%" }}
                        placeholder=" Upload A Team Photo"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "15px",
                          padding: "5px",
                        }}
                      >
                        This photo shows on the home page.
                      </p> */}
                        <label htmlFor="postal_code"> postalcode</label>
                        <input
                          className="input-select"
                          type="text"
                          id="postal_code"
                          name="postal_code"
                          value={team.postal_code}
                          onChange={handleChange}
                          defaultValue={
                            team.postal_code
                              ? team.postal_code
                              : "enter postel code"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="prefarance-form-list">
                        {/* <label>Sponcer Information</label>
                      <input
                        type="text"
                        className="input-select"
                        style={{ borderRadius: "30px", width: "33%" }}
                        placeholder=" Add New Sponcer"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "15px",
                          padding: "5px",
                        }}
                      >
                        Add multiple sponsors to your team home page.
                        <span style={{ color: "red" }}>
                          {" "}
                          Find out More
                        </span>{" "}
                      </p> */}
                        <label htmlFor="name"> country</label>

                        <select
                          className="input-select"
                          id="country"
                          name="country"
                          value={team.country}
                          onChange={handleChange}
                        >
                          {locData?.map((country) => (
                            <option key={country.value} value={country.label}>
                              {country.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="prefarance-form-list">
                        <label htmlFor="name"> time_zone</label>
                        {console.log(team?.time_zone, "time_zone==>>")}
                        <select
                          className="input-select"
                          id="time_zone"
                          name="time_zone"
                          value={team.time_zone}
                          onChange={handleChange}
                          defaultValue={
                            team?.time_zone === ""
                              ? "select time zone"
                              : team?.time_zone
                          }
                        >
                          {team?.time_zone ? (
                            <option value={team.time_zone} disabled>
                              {team.time_zone}
                            </option>
                          ) : (
                            <option value="">select time zone</option>
                          )}
                          {timezonelist?.map((timezone, index) => (
                            <option key={index} value={timezone.timezoneName}>
                              {timezone.timezoneName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="prefarance-form-list">
                        <label htmlFor="name"> alternate_sport_name</label>
                        <input
                          className="input-select"
                          type="text"
                          id="alternate_sport_name"
                          name="alternate_sport_name"
                          value={team.alternate_sport_name}
                          onChange={handleChange}
                          defaultValue={
                            team.alternate_sport_name === ""
                              ? "enter alternate sports name "
                              : team.alternate_sport_name
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="prefarance-form-list">
                        <label htmlFor="name"> age_group</label>

                        <select
                          className="input-select"
                          id="age_group"
                          name="age_group"
                          value={team.age_group}
                          onChange={handleChange}
                          //  defaultValue={team.age_group === "" ? "select age group":team.age_group}
                        >
                          <span>
                            {team.age_group === ""
                              ? "select age group"
                              : team.age_group}
                          </span>
                          {ageData?.map((age) => (
                            <option key={age.value} value={age.label}>
                              {age.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="prefarance-form-list">
                        <label htmlFor="name"> team_gender</label>
                        <select
                          className="input-select"
                          id="team_gender"
                          name="team_gender"
                          value={team.team_gender}
                          onChange={handleChange}
                        >
                          {genderData?.map((gender) => (
                            <option key={gender.value} value={gender.label}>
                              {gender.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="prefarance-form-list"></div>
                    </div>
                    <div className="col-md-2">
                      <div className="prefarance-form-list">
                        <button  className="add-links my-3" onClick={handleSaveClick} style={{backgroundColor:'red'}}>Save</button>
                      </div>
                    </div>
                  </div>
                  {/* )
                :
                (
                  <div>
                    <h2>{team.team_name}</h2>
                    <p>Sports: {team.sports}</p>
                   
                    
                    <button onClick={handleEditClick}>Edit</button>
                  </div>
                )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSettingHome;
