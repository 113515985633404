import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import UserProfile from "../../../images/user-profile.png";
import flag from "../../../images/flag.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import { useHeaderContext } from "../ManagerContext/HeaderContext";
import ManagerHeader from "../../../Components/Header/ManagerHeader";

const ManagerTeamDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateTeamId, gameId } = useHeaderContext();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [dropdown, setDropdown] = useState([]);
  const [teamDropdown, setTeamDropDown] = useState("");
  const [schedule, setSchedule] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [key, setKey] = useState("availability");

  useEffect(() => {
    setUser(user);
    const userLocal = JSON.parse(localStorage.getItem("user"));
    console.log("userData after login--->", userLocal);
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    // dropdownMenu();
    const team_id = localStorage.getItem("selectedTeamId");
    teamSchedule(team_id);
    // GameEventList();
    // setColor();
    rosterAvailDetailsData();
  }, []);
  const handleLogout = () => {
    dispatch(logoutUser(null));
    localStorage.removeItem("user");
    setUserData(null);
    history.push("/");
  };

  const teamSchedule = (id) => {
    console.log("id", id);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };

      let url = "";
      // if (id != undefined) {

      //     url = 'api/getAllEventAndGamesData?team_id=' + id
      // }
      // else {
      //     url = 'api/get-game-event-list?manager_id=' + user._id + '&team_id=' + teamDropdown + '&page=1&limit=10'
      // }
      //console.log('user',user)
      Network("api/getAllEventAndGamesData?team_id=" + id, "GET", header).then(
        async (res) => {
          console.log("schedule----", res);
          if (res.response_code == 400) {
            dispatch(logoutUser(null));
            localStorage.removeItem("user");
            history.push("/");
            toast.error(res.response_message);
          }
          console.log("doc data----->", res.response_data);
          setSchedule(res.response_data);
        }
      );
    }
  };

  const rosterAvailDetailsData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (gameId.isFlag == "Game") {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/getRosterAvailableDetailsByGameId?game_id=" +
        gameId.gameId;
    } else {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/getRosterAvailableDetailsByEventId?events_id=" +
        gameId.gameId;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("detailsdata", res);
        if (res.response_code == 200) {
          console.log(" detailsdata==", res);
          setDetailsData(res.response_data);
        }
      });
  };

  const change = (event) => {
    console.log("event", event);
    setTeamDropDown(event);
    updateTeamId(event);
    teamSchedule(event);
  };

  return (
    <div>
      <div className='dashboard-container'>
        <div className='dashboard-main'>
          <SideMenuComponents manger='manger' />
          <div className='dashboard-main-content'>
            <ManagerHeader change={change} />

            <div className='player-info-head availability'>
              <h2 className='page-title'>Game/Event Details</h2>
            </div>
            <div className='main-wrapper-prefarance-box'>
              <Tabs
                id='controlled-tab-example'
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className='mb-3c'
              >
                <Tab eventKey='availability' title='Availability'>
                  <div className='prefarance-box mt-0'>
                    <div className='team-payment team-assesment'>
                      <table>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          {/* <th>Location</th> */}
                          <th>Availability</th>
                        </tr>

                        {detailsData?.map((details) => {
                          return (
                            <tr>
                              <td>
                                <img
                                  src={
                                    details.assignment_volunteer_roster_profile_pic
                                  }
                                  alt='cricket-icon'
                                  className='img-fluid'
                                  style={{ "max-width": 90 }}
                                />
                              </td>

                              <td>
                                <span>
                                  {details.assignment_volunteer_roster_name}
                                </span>
                              </td>

                              <td>
                                <div className='last-row'>
                                  <button
                                    style={{ color: "#ec3525" }}
                                    data-toggle='modal'
                                    data-target='#setAvailability'
                                  >
                                    {details.at_an_event}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManagerTeamDetails;
