import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import { EyeFill } from "react-bootstrap-icons";

import "./style.css";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import UserProfile from "../../../images/user-profile.png";
import flagIcon from "../../../images/flag.png";
import football from "../../../images/football.png";
import Cricket from "../../../images/Cricket.png";
import add from "../../../images/add.png";
import Delect from "../../../images/delect.png";
import pencil from "../../../images/pencil.png";
import view from "../../../images/menu10.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Footer from "../../../Components/Footer";
import { Network } from "../../../Services/Api";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import Subscribe from "./Subscribe";
import Modal from "react-bootstrap/Modal";
import BigUserProfile from "../../../images/big-user-profile.png";
import CsvDownload from "../../../Components/Comman/CsvDownload";
import ManagerHeader from "../../../Components/Header/ManagerHeader";
import { useHeaderContext } from "../ManagerContext/HeaderContext";

function LineupPage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { gameId, updateGameId, updateTeamId } = useHeaderContext();
  const [userMe, setUser] = useState(null);
  const [user, setUserData] = useState({});
  const [rosterData, setRosterData] = useState([]);
  const [teamDropdown, setTeamDropDown] = useState("");
  const [modeValue, setModeValue] = useState(false);
  const [position, setPosition] = useState("");
  const [lineupData, setLineupData] = useState([]);
  const [gameLineupData, setGameLineupData] = useState([]);
  const [teamPositionData, setTeamPositionData] = useState([]);
  const [customRosterData, setCustomRosterData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [positionTitleForEdit, setPositionTitleForEdit] = useState("");
  const [positionIdForEdit, setPositionIdForEdit] = useState("");
  const [addOrUpdateBtn, setAddOrUpdateBtn] = useState(false);
  const [addlineUpArray, setAddLineUpArray] = useState([]);

  useEffect(() => {
    const userLocal = JSON.parse(localStorage.getItem("user"));
    let userD = userLocal && userLocal._id ? true : false;
    setUser(userD);
    setUserData(userLocal);
    const team_id = localStorage.getItem("selectedTeamId");
    // teamSchedule(team_id);
    getRosterListByTeamId();
    setTeamDropDown(team_id);
    gameDetailsbyGameEventId();
    getPositionByTeamId(gameId.teamId);
  }, []);

  const change = (value) => {
    setTeamDropDown(value);
    updateTeamId(value);
    // teamSchedule(value);
  };

  const gameDetailsbyGameEventId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (gameId.isFlag == "Game") {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/getGameDetailsByGameId?game_id=" +
        gameId.gameId;
    } else {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/getDetailsByEventId?events_id=" +
        gameId.gameId;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.response_code == 200) {
          setGameLineupData(
            res?.response_data?.lineup?.map((item) => {
              return {
                game_team_position_id: item?.game_team_position_id?._id,
                assignment_lineup_roster_id:
                  item?.assignment_lineup_roster_id?._id,
              };
            })
          );
        }
      });
  };

  const getRosterListByTeamId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };

      Network(
        "api/getRoasterUserDetailsByTeamId?teamId=" + gameId.teamId,
        "GET",
        header
      ).then(async (res) => {
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setRosterData(res.response_data);
        setCustomRosterData(
          res.response_data.map((item) => {
            return {
              fullName: item.fullName,
              userImage: item.userImage,
              email: item?.email,
              rosterId: item.rosterId,
              positionId: null,
              positionTitle: null,
            };
          })
        );
      });
    }
  };


  const addPositionModal = (index) => {
    setModeValue(true);
    setSelectedIndex(index);
    setPositionTitleForEdit("");
    setAddOrUpdateBtn(false);
  };

  const handleCheckboxChange = (obj) => {
    setCustomRosterData(
      customRosterData.map((item, index) => {
        if (selectedIndex === index) {
          return {
            email: item.email,
            fullName: item.fullName,
            positionId: obj.id,
            positionTitle: obj.name,
            rosterId: item.rosterId,
            userImage: item.userImage,
          };
        } else {
          return item;
        }
      })
    );
    setModeValue(false);
  };

  const addPositionTitle = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    var url =
      "https://www.sports-cloud.io/sportsapi/api/addPositionTitleByTeam";
    var data = {
      team_id: gameId.teamId,
      position_title_name: positionTitleForEdit,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.response_code == 200) {
          setModeValue(false);
          getPositionByTeamId(gameId.teamId);
          toast.success(res.response_message);
        }
        if (res.response_code == 400) {
          setModeValue(false);
          toast.error(res.response_message);
        }
      });
  };

  const getPositionByTeamId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let header = {
        token: user.authtoken,
      };
      Network(
        "api/getPositionsByTeamId?team_id=" + gameId.teamId,
        "GET",
        header
      ).then(async (res) => {
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setTeamPositionData(res.response_data);
      });
    }
  };

  const handleEditPosition = (id, title) => {
    setPositionTitleForEdit(title);
    setPositionIdForEdit(id);
    setAddOrUpdateBtn(true);
  };

  const updatePositionTitle = (id, title) => {
    const user = JSON.parse(localStorage.getItem("user"));
    var url = "https://www.sports-cloud.io/sportsapi/api/updatePositionById";
    var data = {
      position_id: id,
      position_title: title,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.response_code == 200) {
          setModeValue(false);
          getPositionByTeamId(gameId.teamId);
          toast.success(res.response_message);
        }
        if (res.response_code == 400) {
          setModeValue(false);
          toast.error(res.response_message);
        }
      });
  };

  const deletePositionTitle = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const a = window.confirm("Are you sure you wish to delete this Data?");
    if (a == true) {
      var url =
        "https://www.sports-cloud.io/sportsapi/api/deletePositionDetailsById";

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: user.authtoken,
        },
        body: JSON.stringify({
          position_id: id,
        }),
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.response_code == 200) {
            setModeValue(false);
            toast.success(res.response_message);
            getPositionByTeamId(gameId.teamId);
          }
          if (res.response_code == 400) {
            toast.error(res.response_message);
            setModeValue(false);
          }

          //   teamSchedule(teamDropdown);
        });
    }
  };

  const handleLineupCheckbox = (e, positionId, rosterId) => {
    if (e.target.checked === true) {
      setAddLineUpArray([
        ...addlineUpArray,
        {
          game_team_position_id: positionId,
          assignment_lineup_roster_id: rosterId,
        },
      ]);
    } else {
      const newArray = addlineUpArray.filter((item) => {
        return item.assignment_lineup_roster_id !== rosterId;
      });
      setAddLineUpArray(newArray);
    }
  };

  const isButtonDisabled = addlineUpArray.length === 0;

  const addLineupsByGameId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    var url = "https://www.sports-cloud.io/sportsapi/api/addLineupsByGameid";
    var data = {
      game_id: gameId.gameId,
      lineup: [...gameLineupData, ...addlineUpArray],
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: user.authtoken,
      },
      body: JSON.stringify(data),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.response_code == 200) {
          toast.success("Lineup Added Successfully");
          history.push("/ScheduleAvailabilityDetails");
        }
        if (res.response_code == 400) {
          toast.error(res.response_message);
        }
      });
  };

  return (
    <div>
      <div className='dashboard-container'>
        <div className='dashboard-main'>
          <SideMenuComponents manger='manger' />
          <div className='dashboard-main-content'>
            <ManagerHeader change={change} />

            <div className='prefarance-page'>
              <div className='page-header'>
                <h2 className='page-title'>Add Lineup</h2>
              </div>

              <div className='prefarance-box'>
                <div className='team-payment team-assesment team-lineup-lists'>
                  <table>
                    <tr class="tbl-header sticky">
                      <th></th>
                      <th>Name</th>
                      <th>Email</th>
                      {/* <th>Position</th> */}
                    </tr>

                    {customRosterData?.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <div className="avatar-check-group">
                            {gameLineupData[index]
                              ?.assignment_lineup_roster_id !==
                              item?.rosterId && (
                              <input
                                type='checkbox'
                                onChange={(e) => {
                                  handleLineupCheckbox(
                                    e,
                                    item.positionId,
                                    item.rosterId
                                  );
                                }}
                              />
                            )}

                            <img
                              src={item.userImage}
                              alt='cricket-icon'
                              className='img-fluid small-avatar-icon'
                              style={{ "max-width": 50 }}
                            />
                            </div>
                          </td>
                          <td>
                            <span>{item.fullName}</span>
                          </td>
                          <td>
                            <span>{item.email}</span>
                          </td>
                          {/* <td>
                            <div className='last-row'>
                              {item.positionTitle !== null ? (
                                <button
                                  style={{ color: "#ec3525" }}
                                  onClick={() => {
                                    addPositionModal(index);
                                  }}
                                >
                                  {item?.positionTitle}
                                </button>
                              ) : (
                                <span>
                                  <button
                                    onClick={() => {
                                      addPositionModal(index);
                                    }}
                                  >
                                    <img src={add} />
                                  </button>
                                </span>
                              )}
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </table>
                  <div className='text-center tbl-action'>
                    <button
                      disabled={isButtonDisabled}
                      className={
                        isButtonDisabled ? "add-links-disable" : "add-links"
                      }
                      onClick={addLineupsByGameId}
                    >
                      Add Lineup
                    </button>
                  </div>
                </div>
                {modeValue ? (
                  <Modal show={modeValue} size='md'>
                    <Modal.Body>
                      <div className='prefarance-form playerinfo-form'>
                        <h1 className='m-title'>Add Position</h1>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='prefarance-form-list'>
                              <div className='last-row'>
                                <span>
                                  <ul>
                                    {teamPositionData.map((item) => (
                                      <>
                                        <li
                                          style={{
                                            color: "#9e9e9e",
                                            fontSize: "16px",
                                            listStyle: "none",
                                            display: "flex",
                                            gap: "5px",
                                          }}
                                          key={item.position_id}
                                        >
                                          <input
                                            type='checkbox'
                                            onChange={(e) => {
                                              handleCheckboxChange({
                                                id: item.position_id,
                                                name: item.position_title,
                                              });
                                            }}
                                          />
                                          <span>{item.position_title}</span>
                                          <button
                                            onClick={() => {
                                              handleEditPosition(
                                                item.position_id,
                                                item.position_title
                                              );
                                            }}
                                          >
                                            <img src={pencil} />
                                          </button>
                                          <button
                                            onClick={() => {
                                              deletePositionTitle(
                                                item.position_id
                                              );
                                            }}
                                          >
                                            <img src={Delect} />
                                          </button>{" "}
                                        </li>
                                      </>
                                    ))}
                                  </ul>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='prefarance-form-list'>
                              <input
                                type='text'
                                className='input-select'
                                value={positionTitleForEdit}
                                onChange={(e) =>
                                  setPositionTitleForEdit(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='text-center mt-3'>
                        <button
                          className='add-links'
                          onClick={() => setModeValue(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className='add-links ml-2'
                          style={{
                            backgroundColor: "#1d1b1b",
                          }}
                          onClick={() => {
                            addOrUpdateBtn
                              ? updatePositionTitle(
                                  positionIdForEdit,
                                  positionTitleForEdit
                                )
                              : addPositionTitle();
                          }}
                        >
                          {addOrUpdateBtn ? "Update Position" : "Add Position"}
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LineupPage;
