import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  HashRouter,
} from "react-router-dom";
import "../../../Utils/css/style.css";
import "../../../Utils/css/responsive.css";
import "../../../Utils/css/bootstrap.min.css";
import "../../../Utils/css/bootstrap-datepicker.css";
import Alert from "react-bootstrap/Alert";
import TeamList from "../../../images/team-list.png";
import SideMenuComponents from "../../../Components/SideMenu";
import Modal from "react-bootstrap/Modal";
import flag from "../../../images/flag.png";
import SideNav from "./sideNav";
import ManagerHeader from "../../../Components/Header/ManagerHeader";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Network } from "../../../Services/Api";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "../../../Redux/Actions/auth";
import { Button } from "react-bootstrap";
import { useHeaderContext } from "../ManagerContext/HeaderContext";
const Inbox = () => {
  const dispatch = useDispatch();
  const { selectedId, managerRosterId, teamId, teamName } = useHeaderContext();
  const [mailList, setMailList] = useState([]);
  const [index, setIndex] = useState(null);
  const [singleMail, setSingleMail] = useState({});
  const [singleToMail, setSingleToMail] = useState([])
  // useEffect(() => {
  //   getAllMailList();
  // }, [teamDropdown]);

  const [showAlert, setShowAlert] = useState(false);
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    // const handleLocalStorageUpdate = () => {
    // const newId = localStorage.getItem("ManagerTeamId");
    const newId =  teamId
    // Fetch data using the new id and update the state
    getAllMailList(newId);
    // };

    // window.addEventListener('localStorageUpdate', handleLocalStorageUpdate);

    // // Clean up the event listener when the component unmounts
    // return () => {
    //   window.removeEventListener('localStorageUpdate', handleLocalStorageUpdate);
    // };
  }, [teamId]);

  const history = useHistory();

  const [teamDropdown, setTeamDropDown] = useState("");
  const [modalValue, setModalValue] = useState(false);

  const change = () => {
    // console.log("eventssss=============");
    // setTeamDropDown(value);
    // setPlayer([])
    // teamRoster(event.target.value);
  };
  const modalOpen = (item) => {
    setModalValue(true);
    setSingleMail(item);
    setSingleToMail(item.to_mail_send)
  };
  const modalClose = () => {
    setModalValue(false);
  };

  const getAllMailList = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    // const userMail = user.email;
    // const teamId = localStorage.getItem("ManagerTeamId");
    const token = user.authtoken;

    let header = {
      token: token,
    };
    Network("api/getAllInboxMailList?teamId=" + id, "GET", header).then(
      async (res) => {
        if (res.response_code == 400) {
          dispatch(logoutUser(null));
          localStorage.removeItem("user");
          history.push("/");
          toast.error(res.response_message);
        }
        setMailList(res.response_data);
        console.log("Mails", res.response_data);
      }
    );
  };
  console.log("dropdoe", teamDropdown);

  const onDelete = (i) => {
    console.log("Deleted!!!");
    setShowAlert(true);
    setIndex(i);
  };

  const setStarredMail = async(id, val) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.authtoken;
    let data = {
      _id : id,
      starred_mail: val
    }
    let header = {
      token: token,
    };
    var config = {
      method: "post",
      url: "https://www.sports-cloud.io/sportsapi/api/createStarredEmail",
      headers: header,
      data: data,
    };
    console.log("================================", data)
    await axios(config)
    .then((res) => {
      console.log("starred Email----", res.data);
      if (res.data.success === true) {
        getAllMailList(teamId)
        // toast.success("Successfully Send Email", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      } else {
        toast.error("Something went wrong. Please try after some time", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    })
    .catch((error) => {
      // setIsCategoryLoading(false)
      console.log("error", error.response.data.message);
      console.log("error", error.response.status);
      // handleError(error.response.status);
    });
  }
  return (
    <div className="dashboard-container">
      <div className="dashboard-main">
        <SideMenuComponents manger="manger" />
        <div className="dashboard-main-content">
          <ManagerHeader change={change} />
          <div className="prefarance-box player-info mail_body mt-0">
            <SideNav name="Emails" />
            <div className="tab-content">
            {mailList?.length === 0 || mailList?.length == undefined ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "gold",
                    marginTop: "20vh",
                  }}
                >
                  Your team haven't recive any emails yet.
                </div>
              ) : mailList?.map((item, i) => (
                <>
                  <div className="mail-body">
                    <div className="mail-item" onClick={() => modalOpen(item)}>
                      <img
                        src={item.from_user_profile_pic}
                        className="mail-image"
                        alt="#"
                      />

                      <div className="item-name">{item.from_user_name}</div>
                      <br />
                      <div className="item-subj text-eclipse-1">
                        {item.subject}{" "}
                      </div>
                    
                    </div>
                    <div style={{marginLeft: "auto"}}>
                    {item.starred_mail ?

                    
                    <div onClick={()=>setStarredMail(item._id, "false")}>
                    <img
                      className="action-icon"
                      src={require("../../../images/star.png")}
                      alt="#"
                      />
                    </div>
                    :
                    <p onClick={()=>setStarredMail(item._id, "true")}>

                    <img
                    className="action-icon"
                    src={require("../../../images/starempty.png")}
                    alt="#"
                    />
                    </p>
                  }
                  </div>
                    <div onClick={() => onDelete(i)}>
                      <img
                        className="action-icon"
                        src={require("../../../images/delect-red.png")}
                        alt="#"
                        style={{ marginLeft: "20px" }}
                      />
                    </div>
                  </div>
                  {i == index && (
                    <Alert
                      show={showAlert}
                      variant="secondary"
                      style={{
                        borderColor: "black",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      }}
                      className="mt-3"
                    >
                      <p style={{ color: "#ddd", fontSize: "0.9rem" }}>
                        Are you sure you want to delete this mail?
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outline-light"
                          style={{
                            fontSize: "0.85rem",
                            marginRight: "5px",
                            border: "0",
                          }}
                          onClick={handleCloseAlert}
                          className="mr-2"
                        >
                          OK
                        </Button>
                        <Button
                          variant="outline-danger"
                          style={{
                            fontSize: "0.85rem",
                            marginRight: "5px",
                            border: "0",
                          }}
                          onClick={handleCloseAlert}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Alert>
                  )}
                </>
              ))}

              {/* 
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <div className="prefarance-tab-content">

                        <div className="prefarance-form playerinfo-form">

                            <div className="row">
                                <div className="col-md-9">
                                    <div className="prefarance-form-list">

                                        <input type="checkbox" style={{ height: "15px", width: "17px" }} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="prefarance-form-list">
                                        <button className="add-links" style={{ margin: "10px" }}>Delete</button>
                                    </div>
                                </div>
                            </div>

                            
                            <div className="team-payment team-assesment">

                                <table  >
                                    <thead >
                                        <tr>
                                            <th> </th>
                                            <th style={{fontSize:"Larger"}}> Subject</th>
                                            <th style={{fontSize:"Larger"}}> Sent From</th>
                                            <th style={{fontSize:"Larger"}}>Send Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="firstColumn">
                                            <td>   <input type="checkbox" style={{ height: "15px", width: "17px" }} /></td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr style={{borderBottom:"0px"}}>
                                            <td>   <input type="checkbox" style={{ height: "15px", width: "17px" }} /></td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                        </tr>
                                        <tr>
                                            <td>   <input type="checkbox" style={{ height: "15px", width: "17px" }} /></td>
                                            <td >Larry the Bird</td>
                                            <td>Thornton</td>
                                            <td>@twitter</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>










                        </div>
                    </div>
                </div>
            */}

              <Modal
                className="prefarance-form"
                size="lg"
                centered
                show={modalValue}
                onHide={modalClose}
              >
                <Modal.Header className="border-0 px-4">
                  <Modal.Title className="m-title m-0" style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    <p style={{fontSize:"16px", color:"#ccc", marginRight:"5px"}}>
                      Subject -
                      </p>
                       <small>{singleMail.subject}</small>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="viewMail-body pt-0">
                  <div className="mail_sender-data">
                    <img
                      className="senderImage"
                      src={singleMail.from_user_profile_pic}
                      alt="#"
                    />
                    <div className="sndr-content">
                      <div className="sndr-name">
                        {singleMail.from_user_name}
                      </div>
                      <p className="sndr-email">{singleMail.from_user_mail}</p>
                      <div style={{display:"flex", alignItems:"center", flexWrap:"wrap"}}>
                     <p style={{fontSize:"13px", fontWeight:"bold", color:"#aaa", paddingRight:"5px"}}>
                      Send To: 
                      </p> 
                    {singleToMail.map(item => {return(
                      <div className="to-email" key={item._id}>
                        {item.gmail_to_user_mail}
                      </div>
                    )})}
                    </div>
                    </div>
                  </div>
                  <div className="mail-body">{singleMail.message}</div>
                  <div className="btn-act">
                    {/* <Button variant="outline-secondary" className="mr-3">
                      Reply
                    </Button> */}
                    <Button variant="outline-danger" onClick={modalClose}>
                      Close
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inbox;
